import {
  ADD_PHOTOSHOOT_FAILURE,
  ADD_PHOTOSHOOT_REQUEST,
  ADD_PHOTOSHOOT_SUCCESS,
  APPROVE_STYLE_SET_FAILURE,
  APPROVE_STYLE_SET_REQUEST,
  APPROVE_STYLE_SET_SUCCESS,
  CANCEL_SHOOT_REQUIREMENT_FAILURE,
  CANCEL_SHOOT_REQUIREMENT_REQUEST,
  CANCEL_SHOOT_REQUIREMENT_SUCCESS,
  CLEAR_ERRORS,
  CONTENT_FORMAT_DETAILS_UPDATE_FAILURE,
  CONTENT_FORMAT_DETAILS_UPDATE_REQUEST,
  CONTENT_FORMAT_DETAILS_UPDATE_SUCCESS,
  DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_FAILURE,
  DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_REQUEST,
  DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_SUCCESS,
  DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_FAILURE,
  DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_REQUEST,
  DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_SUCCESS,
  DASHBOARD_METRICS_FAILURE,
  DASHBOARD_METRICS_REQUEST,
  DASHBOARD_METRICS_SUCCESS,
  GET_ALL_CATEGORY_LIST_FAILURE,
  GET_ALL_CATEGORY_LIST_REQUEST,
  GET_ALL_CATEGORY_LIST_SUCCESS,
  GET_ALL_PHOTOSHOOT_DATA_FAILURE,
  GET_ALL_PHOTOSHOOT_DATA_REQUEST,
  GET_ALL_PHOTOSHOOT_DATA_SUCCESS,
  GET_ALL_PHOTOSHOOT_NAME_LIST_FAILURE,
  GET_ALL_PHOTOSHOOT_NAME_LIST_REQUEST,
  GET_ALL_PHOTOSHOOT_NAME_LIST_SUCCESS,
  GET_ARTICLE_WISE_BAR_CHART_FAILURE,
  GET_ARTICLE_WISE_BAR_CHART_REQUEST,
  GET_ARTICLE_WISE_BAR_CHART_SUCCESS,
  GET_CONTENT_FORMAT_DATA_BY_ID_FAILURE,
  GET_CONTENT_FORMAT_DATA_BY_ID_REQUEST,
  GET_CONTENT_FORMAT_DATA_BY_ID_SUCCESS,
  GET_CONTENT_FORMAT_DATA_FAILURE,
  GET_CONTENT_FORMAT_DATA_REQUEST,
  GET_CONTENT_FORMAT_DATA_SUCCESS,
  GET_LIST_OF_STYLE_FAILURE,
  GET_LIST_OF_STYLE_REQUEST,
  GET_LIST_OF_STYLE_SUCCESS,
  GET_PHOTOSHOOT_DATA_BY_ID_FAILURE,
  GET_PHOTOSHOOT_DATA_BY_ID_REQUEST,
  GET_PHOTOSHOOT_DATA_BY_ID_SUCCESS,
  GET_PHOTOSHOOT_DATA_FAILURE,
  GET_PHOTOSHOOT_DATA_REQUEST,
  GET_PHOTOSHOOT_DATA_SUCCESS,
  GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_FAILURE,
  GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_REQUEST,
  GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_SUCCESS,
  GET_RESHOOT_DATA_FAILURE,
  GET_RESHOOT_DATA_REQUEST,
  GET_RESHOOT_DATA_SUCCESS,
  GET_RESHOOT_DETAILS_FAILURE,
  GET_RESHOOT_DETAILS_REQUEST,
  GET_RESHOOT_DETAILS_SUCCESS,
  GET_STYLE_SETS_TABLE_DATA_FAILURE,
  GET_STYLE_SETS_TABLE_DATA_REQUEST,
  GET_STYLE_SETS_TABLE_DATA_SUCCESS,
  GET_STYLING_ELEMENTS_DATA_FAILURE,
  GET_STYLING_ELEMENTS_DATA_REQUEST,
  GET_STYLING_ELEMENTS_DATA_SUCCESS,
  GET_STYLING_ELEMENT_OPTION_FAILURE,
  GET_STYLING_ELEMENT_OPTION_REQUEST,
  GET_STYLING_ELEMENT_OPTION_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  PHOTOSHOOT_REQUEST_DETAILS_DELETE_FAILURE,
  PHOTOSHOOT_REQUEST_DETAILS_DELETE_REQUEST,
  PHOTOSHOOT_REQUEST_DETAILS_DELETE_SUCCESS,
  PHOTOSHOOT_REQUEST_DETAILS_EDIT_FAILURE,
  PHOTOSHOOT_REQUEST_DETAILS_EDIT_REQUEST,
  PHOTOSHOOT_REQUEST_DETAILS_EDIT_SUCCESS,
  REJECT_STYLE_SET_FAILURE,
  REJECT_STYLE_SET_REQUEST,
  REJECT_STYLE_SET_SUCCESS,
  RESHOOT_REQUIREMENT_CANCEL_FAILURE,
  RESHOOT_REQUIREMENT_CANCEL_REQUEST,
  RESHOOT_REQUIREMENT_CANCEL_SUCCESS,
} from "../Constants/StyleConstants";

// get Login user Reducers
export const LoginUserReducers = (state = { login_user: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        login_user: action.payload,
        status: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Dashboard Metrics user Reducers
export const getDashboardMetricsReducers = (
  state = { dashboard_metrics: {} },
  action
) => {
  switch (action.type) {
    case DASHBOARD_METRICS_REQUEST:
      return {
        loading: true,
      };
    case DASHBOARD_METRICS_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard_metrics: action.payload,
        status: action.payload,
      };
    case DASHBOARD_METRICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Dashboard Content Wise Data Doughnut Reducers
export const getDashboardDoughnutContentWiseReducers = (
  state = { content_wise_data: {} },
  action
) => {
  switch (action.type) {
    case DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_REQUEST:
      return {
        loading: true,
      };
    case DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_SUCCESS:
      return {
        ...state,
        loading: false,
        content_wise_data: action.payload,
        status: action.payload,
      };
    case DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Dashboard Category Wise Data Doughnut Reducers
export const getDashboardDoughnutCategoryWiseReducers = (
  state = { category_wise_data: {} },
  action
) => {
  switch (action.type) {
    case DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_REQUEST:
      return {
        loading: true,
      };
    case DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_SUCCESS:
      return {
        ...state,
        loading: false,
        category_wise_data: action.payload,
        status: action.payload,
      };
    case DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Content Format Data Reducers
export const getContentFormatDataReducers = (
  state = { content_format_data: {} },
  action
) => {
  switch (action.type) {
    case GET_CONTENT_FORMAT_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_CONTENT_FORMAT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        content_format_data: action.payload,
        status: action.payload,
      };
    case GET_CONTENT_FORMAT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Content Format Data By Id Reducers
export const getContentFormatDataByIdReducers = (
  state = { content_format_data_by_id: {} },
  action
) => {
  switch (action.type) {
    case GET_CONTENT_FORMAT_DATA_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_CONTENT_FORMAT_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        content_format_data_by_id: action.payload,
        status: action.payload,
      };
    case GET_CONTENT_FORMAT_DATA_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Photoshoot Rquest Reducers
export const AddPhotoShootRequestReducers = (
  state = { add_Photoshoot: {} },
  action
) => {
  switch (action.type) {
    case ADD_PHOTOSHOOT_REQUEST:
      return {
        loading: true,
      };
    case ADD_PHOTOSHOOT_SUCCESS:
      return {
        ...state,
        loading: false,
        add_Photoshoot: action.payload,
        status: action.payload,
      };
    case ADD_PHOTOSHOOT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Add Photoshoot Rquest Reducers
export const getPhotoShootRequestDataReducers = (
  state = { Photoshoot_request_data: {} },
  action
) => {
  switch (action.type) {
    case GET_PHOTOSHOOT_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_PHOTOSHOOT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        Photoshoot_request_data: action.payload,
        status: action.payload,
      };
    case GET_PHOTOSHOOT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Photoshoot Rquest data By Id Reducers
export const getPhotoShootRequestDataByIdReducers = (
  state = { Photoshoot_request_data_by_id: {} },
  action
) => {
  switch (action.type) {
    case GET_PHOTOSHOOT_DATA_BY_ID_REQUEST:
      return {
        loading: true,
      };
    case GET_PHOTOSHOOT_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        Photoshoot_request_data_by_id: action.payload,
        status: action.payload,
      };
    case GET_PHOTOSHOOT_DATA_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Photoshoot Rquest data By Id Reducers
export const getListofStyleReducers = (
  state = { list_of_styles: [] },
  action
) => {
  switch (action.type) {
    case GET_LIST_OF_STYLE_REQUEST:
      return {
        loading: true,
      };
    case GET_LIST_OF_STYLE_SUCCESS:
      return {
        ...state,
        loading: false,
        list_of_styles: action.payload,
        status: action.payload,
      };
    case GET_LIST_OF_STYLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get All Category List Reducers
export const getListofCategoryReducers = (
  state = { list_of_Category: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_CATEGORY_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list_of_Category: action.payload,
        status: action.payload,
      };
    case GET_ALL_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get All Photoshoot Name List Reducers
export const getListofPhotoshootNameReducers = (
  state = { photoshoot_name_list: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_PHOTOSHOOT_NAME_LIST_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_PHOTOSHOOT_NAME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        photoshoot_name_list: action.payload,
        status: action.payload,
      };
    case GET_ALL_PHOTOSHOOT_NAME_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Photoshoot Rquest data By Id Reducers
export const getPhotoShootRequestDetailsReducers = (
  state = { Photoshoot_request_details_data: [] },
  action
) => {
  switch (action.type) {
    case GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        Photoshoot_request_details_data: action.payload,
        status: action.payload,
      };
    case GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Photoshoot Rquest data By Id Reducers
export const getStylingElementDataReducers = (
  state = { styling_elements_data: [] },
  action
) => {
  switch (action.type) {
    case GET_STYLING_ELEMENTS_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_STYLING_ELEMENTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        styling_elements_data: action.payload,
        status: action.payload,
      };
    case GET_STYLING_ELEMENTS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Styling Element Option Reducers
export const getStylingElementOptionDataReducers = (
  state = { styling_elements_option_data: [] },
  action
) => {
  switch (action.type) {
    case GET_STYLING_ELEMENT_OPTION_REQUEST:
      return {
        loading: true,
      };
    case GET_STYLING_ELEMENT_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        styling_elements_option_data: action.payload,
        status: action.payload,
      };
    case GET_STYLING_ELEMENT_OPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Style Sets table Details Reducers
export const getStyleSetsTable_data_Reducers = (
  state = { style_sets_table_data: [] },
  action
) => {
  switch (action.type) {
    case GET_STYLE_SETS_TABLE_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_STYLE_SETS_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        style_sets_table_data: action.payload,
        status: action.payload,
      };
    case GET_STYLE_SETS_TABLE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get All PhotoShoot Data Reducers
export const getAllPhotoshoot_data_Reducers = (
  state = { photoshoot_alldata: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_PHOTOSHOOT_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_PHOTOSHOOT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        photoshoot_alldata: action.payload,
        status: action.payload,
      };
    case GET_ALL_PHOTOSHOOT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get All Reshoot Data Reducers
export const getAllReshoot_data_Reducers = (
  state = { reshoot_alldata: [] },
  action
) => {
  switch (action.type) {
    case GET_RESHOOT_DATA_REQUEST:
      return {
        loading: true,
      };
    case GET_RESHOOT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        reshoot_alldata: action.payload,
        status: action.payload,
      };
    case GET_RESHOOT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Reshoot Details Reducers
export const getReshoot_details_reducers = (
  state = { reshoot_requirement_details: {} },
  action
) => {
  switch (action.type) {
    case GET_RESHOOT_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_RESHOOT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        reshoot_requirement_details: action.payload,
        status: action.payload,
      };
    case GET_RESHOOT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Get Article wise Bar Chart Reducers
export const getArticle_wise_barChart_reducers = (
  state = { article_wise_data: [] },
  action
) => {
  switch (action.type) {
    case GET_ARTICLE_WISE_BAR_CHART_REQUEST:
      return {
        loading: true,
      };
    case GET_ARTICLE_WISE_BAR_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        article_wise_data: action.payload,
        status: action.payload,
      };
    case GET_ARTICLE_WISE_BAR_CHART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Cancel Shoot Requirement Reducers
export const cancel_shootRequirement_reducers = (
  state = { cancel_sR: {} },
  action
) => {
  switch (action.type) {
    case CANCEL_SHOOT_REQUIREMENT_REQUEST:
      return {
        loading: true,
      };
    case CANCEL_SHOOT_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        cancel_sR: action.payload,
        status: action.payload,
      };
    case CANCEL_SHOOT_REQUIREMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Approve Style set Reducers
export const approve_styleSet_reducers = (
  state = { approve_set: {} },
  action
) => {
  switch (action.type) {
    case APPROVE_STYLE_SET_REQUEST:
      return {
        loading: true,
      };
    case APPROVE_STYLE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        approve_set: action.payload,
        status: action.payload,
      };
    case APPROVE_STYLE_SET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Reject Style set Reducers
export const reject_styleSet_reducers = (
  state = { reject_set: {} },
  action
) => {
  switch (action.type) {
    case REJECT_STYLE_SET_REQUEST:
      return {
        loading: true,
      };
    case REJECT_STYLE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        reject_set: action.payload,
        status: action.payload,
      };
    case REJECT_STYLE_SET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Photoshoot Request details edit Reducers
export const PhotoshootRequest_detailsEdit_reducers = (
  state = { PRD_Edit: {} },
  action
) => {
  switch (action.type) {
    case PHOTOSHOOT_REQUEST_DETAILS_EDIT_REQUEST:
      return {
        loading: true,
      };
    case PHOTOSHOOT_REQUEST_DETAILS_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        PRD_Edit: action.payload,
        status: action.payload,
      };
    case PHOTOSHOOT_REQUEST_DETAILS_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Photoshoot Request details Delete Reducers
export const PhotoshootRequest_detailsDelete_reducers = (
  state = { PRD_Delete: {} },
  action
) => {
  switch (action.type) {
    case PHOTOSHOOT_REQUEST_DETAILS_DELETE_REQUEST:
      return {
        loading: true,
      };
    case PHOTOSHOOT_REQUEST_DETAILS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        PRD_Delete: action.payload,
        status: action.payload,
      };
    case PHOTOSHOOT_REQUEST_DETAILS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Reshoot Requirement cancel Reducers
export const reshoot_requirement_cancel_reducers = (
  state = { reshoot_cancel: {} },
  action
) => {
  switch (action.type) {
    case RESHOOT_REQUIREMENT_CANCEL_REQUEST:
      return {
        loading: true,
      };
    case RESHOOT_REQUIREMENT_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        reshoot_cancel: action.payload,
        status: action.payload,
      };
    case RESHOOT_REQUIREMENT_CANCEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


// content format details update Reducers
export const content_format_details_update_reducers = (
  state = { content_format_update_details: {} },
  action
) => {
  switch (action.type) {
    case CONTENT_FORMAT_DETAILS_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case CONTENT_FORMAT_DETAILS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        content_format_update_details: action.payload,
        status: action.payload,
      };
    case CONTENT_FORMAT_DETAILS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
