// Login constants
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// Dashboard Metrics constants
export const DASHBOARD_METRICS_REQUEST = "DASHBOARD_METRICS_REQUEST";
export const DASHBOARD_METRICS_SUCCESS = "DASHBOARD_METRICS_SUCCESS";
export const DASHBOARD_METRICS_FAILURE = "DASHBOARD_METRICS_FAILURE";

// Dashboard DoughnutChart Content_wise constants
export const DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_REQUEST =
  "DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_REQUEST";
export const DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_SUCCESS =
  "DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_SUCCESS";
export const DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_FAILURE =
  "DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_FAILURE";

// Dashboard DoughnutChart Category_wise constants
export const DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_REQUEST =
  "DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_REQUEST";
export const DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_SUCCESS =
  "DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_SUCCESS";
export const DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_FAILURE =
  "DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_FAILURE";

// Get Content Format Data Request constants
export const GET_CONTENT_FORMAT_DATA_REQUEST =
  "GET_CONTENT_FORMAT_DATA_REQUEST";
export const GET_CONTENT_FORMAT_DATA_SUCCESS =
  "GET_CONTENT_FORMAT_DATA_SUCCESS";
export const GET_CONTENT_FORMAT_DATA_FAILURE =
  "GET_CONTENT_FORMAT_DATA_FAILURE";

// Get Content Format Individual By Id constants
export const GET_CONTENT_FORMAT_DATA_BY_ID_REQUEST =
  "GET_CONTENT_FORMAT_DATA_BY_ID_REQUEST";
export const GET_CONTENT_FORMAT_DATA_BY_ID_SUCCESS =
  "GET_CONTENT_FORMAT_DATA_BY_ID_SUCCESS";
export const GET_CONTENT_FORMAT_DATA_BY_ID_FAILURE =
  "GET_CONTENT_FORMAT_DATA_BY_ID_FAILURE";

// Add Photoshoot Request constants
export const ADD_PHOTOSHOOT_REQUEST = "ADD_PHOTOSHOOT_REQUEST";
export const ADD_PHOTOSHOOT_SUCCESS = "ADD_PHOTOSHOOT_SUCCESS";
export const ADD_PHOTOSHOOT_FAILURE = "ADD_PHOTOSHOOT_FAILURE";

// Get Photoshoot Request Data constants
export const GET_PHOTOSHOOT_DATA_REQUEST = "GET_PHOTOSHOOT_DATA_REQUEST";
export const GET_PHOTOSHOOT_DATA_SUCCESS = "GET_PHOTOSHOOT_DATA_SUCCESS";
export const GET_PHOTOSHOOT_DATA_FAILURE = "GET_PHOTOSHOOT_DATA_FAILURE";

// Get Photoshoot Request Data By Id constants
export const GET_PHOTOSHOOT_DATA_BY_ID_REQUEST =
  "GET_PHOTOSHOOT_DATA_BY_ID_REQUEST";
export const GET_PHOTOSHOOT_DATA_BY_ID_SUCCESS =
  "GET_PHOTOSHOOT_DATA_BY_ID_SUCCESS";
export const GET_PHOTOSHOOT_DATA_BY_ID_FAILURE =
  "GET_PHOTOSHOOT_DATA_BY_ID_FAILURE";

// Get List of style constants
export const GET_LIST_OF_STYLE_REQUEST = "GET_LIST_OF_STYLE_REQUEST";
export const GET_LIST_OF_STYLE_SUCCESS = "GET_LIST_OF_STYLE_SUCCESS";
export const GET_LIST_OF_STYLE_FAILURE = "GET_LIST_OF_STYLE_FAILURE";

// Get PhotoShoot Request Details constants
export const GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_REQUEST =
  "GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_REQUEST";
export const GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_SUCCESS =
  "GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_SUCCESS";
export const GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_FAILURE =
  "GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_FAILURE";

// Get PhotoShoot Request Details constants
export const GET_STYLING_ELEMENTS_DATA_REQUEST =
  "GET_STYLING_ELEMENTS_DATA_REQUEST";
export const GET_STYLING_ELEMENTS_DATA_SUCCESS =
  "GET_STYLING_ELEMENTS_DATA_SUCCESS";
export const GET_STYLING_ELEMENTS_DATA_FAILURE =
  "GET_STYLING_ELEMENTS_DATA_FAILURE";

// Get PhotoShoot Request Details constants
export const GET_STYLING_ELEMENT_OPTION_REQUEST =
  "GET_STYLING_ELEMENT_OPTION_REQUEST";
export const GET_STYLING_ELEMENT_OPTION_SUCCESS =
  "GET_STYLING_ELEMENT_OPTION_SUCCESS";
export const GET_STYLING_ELEMENT_OPTION_FAILURE =
  "GET_STYLING_ELEMENT_OPTION_FAILURE";

// Get Style Sets table Details constants
export const GET_STYLE_SETS_TABLE_DATA_REQUEST =
  "GET_STYLE_SETS_TABLE_DATA_REQUEST";
export const GET_STYLE_SETS_TABLE_DATA_SUCCESS =
  "GET_STYLE_SETS_TABLE_DATA_SUCCESS";
export const GET_STYLE_SETS_TABLE_DATA_FAILURE =
  "GET_STYLE_SETS_TABLE_DATA_FAILURE";

// Get All Category List constants
export const GET_ALL_CATEGORY_LIST_REQUEST = "GET_ALL_CATEGORY_LIST_REQUEST";
export const GET_ALL_CATEGORY_LIST_SUCCESS = "GET_ALL_CATEGORY_LIST_SUCCESS";
export const GET_ALL_CATEGORY_LIST_FAILURE = "GET_ALL_CATEGORY_LIST_FAILURE";

// Get All Category List constants
export const GET_ALL_PHOTOSHOOT_NAME_LIST_REQUEST =
  "GET_ALL_PHOTOSHOOT_NAME_LIST_REQUEST";
export const GET_ALL_PHOTOSHOOT_NAME_LIST_SUCCESS =
  "GET_ALL_PHOTOSHOOT_NAME_LIST_SUCCESS";
export const GET_ALL_PHOTOSHOOT_NAME_LIST_FAILURE =
  "GET_ALL_PHOTOSHOOT_NAME_LIST_FAILURE";

// Get All Photoshoot Data constants
export const GET_ALL_PHOTOSHOOT_DATA_REQUEST =
  "GET_ALL_PHOTOSHOOT_DATA_REQUEST";
export const GET_ALL_PHOTOSHOOT_DATA_SUCCESS =
  "GET_ALL_PHOTOSHOOT_DATA_SUCCESS";
export const GET_ALL_PHOTOSHOOT_DATA_FAILURE =
  "GET_ALL_PHOTOSHOOT_DATA_FAILURE";

// Get All Photoshoot Data constants
export const GET_RESHOOT_DATA_REQUEST = "GET_RESHOOT_DATA_REQUEST";
export const GET_RESHOOT_DATA_SUCCESS = "GET_RESHOOT_DATA_SUCCESS";
export const GET_RESHOOT_DATA_FAILURE = "GET_RESHOOT_DATA_FAILURE";

// Get Reshoot Details constants
export const GET_RESHOOT_DETAILS_REQUEST = "GET_RESHOOT_DETAILS_REQUEST";
export const GET_RESHOOT_DETAILS_SUCCESS = "GET_RESHOOT_DETAILS_SUCCESS";
export const GET_RESHOOT_DETAILS_FAILURE = "GET_RESHOOT_DETAILS_FAILURE";

// Get Bar Chart constants
export const GET_ARTICLE_WISE_BAR_CHART_REQUEST =
  "GET_ARTICLE_WISE_BAR_CHART_REQUEST";
export const GET_ARTICLE_WISE_BAR_CHART_SUCCESS =
  "GET_ARTICLE_WISE_BAR_CHART_SUCCESS";
export const GET_ARTICLE_WISE_BAR_CHART_FAILURE =
  "GET_ARTICLE_WISE_BAR_CHART_FAILURE";

// Get Bar Chart constants
export const CANCEL_SHOOT_REQUIREMENT_REQUEST =
  "CANCEL_SHOOT_REQUIREMENT_REQUEST";
export const CANCEL_SHOOT_REQUIREMENT_SUCCESS =
  "CANCEL_SHOOT_REQUIREMENT_SUCCESS";
export const CANCEL_SHOOT_REQUIREMENT_FAILURE =
  "CANCEL_SHOOT_REQUIREMENT_FAILURE";

// Approve Style Set constants
export const APPROVE_STYLE_SET_REQUEST = "APPROVE_STYLE_SET_REQUEST";
export const APPROVE_STYLE_SET_SUCCESS = "APPROVE_STYLE_SET_SUCCESS";
export const APPROVE_STYLE_SET_FAILURE = "APPROVE_STYLE_SET_FAILURE";

// Reject Style Set constants
export const REJECT_STYLE_SET_REQUEST = "REJECT_STYLE_SET_REQUEST";
export const REJECT_STYLE_SET_SUCCESS = "REJECT_STYLE_SET_SUCCESS";
export const REJECT_STYLE_SET_FAILURE = "REJECT_STYLE_SET_FAILURE";

// Photoshoot request details edit constants
export const PHOTOSHOOT_REQUEST_DETAILS_EDIT_REQUEST =
  "PHOTOSHOOT_REQUEST_DETAILS_EDIT_REQUEST";
export const PHOTOSHOOT_REQUEST_DETAILS_EDIT_SUCCESS =
  "PHOTOSHOOT_REQUEST_DETAILS_EDIT_SUCCESS";
export const PHOTOSHOOT_REQUEST_DETAILS_EDIT_FAILURE =
  "PHOTOSHOOT_REQUEST_DETAILS_EDIT_FAILURE";

// Photoshoot request details Delete constants
export const PHOTOSHOOT_REQUEST_DETAILS_DELETE_REQUEST =
  "PHOTOSHOOT_REQUEST_DETAILS_DELETE_REQUEST";
export const PHOTOSHOOT_REQUEST_DETAILS_DELETE_SUCCESS =
  "PHOTOSHOOT_REQUEST_DETAILS_DELETE_SUCCESS";
export const PHOTOSHOOT_REQUEST_DETAILS_DELETE_FAILURE =
  "PHOTOSHOOT_REQUEST_DETAILS_DELETE_FAILURE";

// Photoshoot request details Delete constants
export const RESHOOT_REQUIREMENT_CANCEL_REQUEST =
  "RESHOOT_REQUIREMENT_CANCEL_REQUEST";
export const RESHOOT_REQUIREMENT_CANCEL_SUCCESS =
  "RESHOOT_REQUIREMENT_CANCEL_SUCCESS";
export const RESHOOT_REQUIREMENT_CANCEL_FAILURE =
  "RESHOOT_REQUIREMENT_CANCEL_FAILURE";

// Content format update constants
export const CONTENT_FORMAT_DETAILS_UPDATE_REQUEST =
  "CONTENT_FORMAT_DETAILS_UPDATE_REQUEST";
export const CONTENT_FORMAT_DETAILS_UPDATE_SUCCESS =
  "CONTENT_FORMAT_DETAILS_UPDATE_SUCCESS";
export const CONTENT_FORMAT_DETAILS_UPDATE_FAILURE =
  "CONTENT_FORMAT_DETAILS_UPDATE_FAILURE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
