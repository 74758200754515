import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Screens/Dashboard/Dashboard";
import Login from "./Screens/Login/Login";
import ShootRequirement from "./Screens/Shoot-Requirement/ShootRequirement";
import StylingElements from "./Screens/Sytling-elements/StylingElements";
import StyleSets from "./Screens/Style-Sets/StyleSets";
import ReShootRequirements from "./Screens/ReShoot-Requirements/ReShootRequirements";
import ContentFormat from "./Screens/Content-Format/ContentFormat";
import ProtectedRoutes from "./Components/ProtectedRoutes/ProtectedRoutes";
import AddPhotoShootRequestDetails from "./Screens/Add-PhotoShoot-Request-Details/AddPhotoShootRequestDetails";
import PhotoShootRequestDetails from "./Screens/PhotoShoot-Request-Details/PhotoShootRequestDetails";
import StylingElementOptions from "./Screens/Styling-Element-options/StylingElementOptions";
import CreateStylingOption from "./Screens/Create-Styling-Option/CreateStylingOption";
import UploadPhotoVideo from "./Screens/UploadPhoto&Video/UploadPhotoVideo";
import RejectedImageUploadNew from "./Screens/RejectedImageUploadNew/RejectedImageUploadNew";
import IndividualStyleSets from "./Screens/Individual-Style-Sets/IndividualStyleSets";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/shoot-requirement" element={<ShootRequirement />} />
          <Route
            path="/reshoot-requirement"
            element={<ReShootRequirements />}
          />
          <Route path="/styling-elements" element={<StylingElements />} />
          <Route path="/style-sets/:id?" element={<StyleSets />} />
          <Route path="/content-format" element={<ContentFormat />} />
          <Route
            path="/add-photoshoot-request-details/:id"
            element={<AddPhotoShootRequestDetails />}
          />
          <Route
            path="/photoshoot-request-details/:id"
            element={<PhotoShootRequestDetails />}
          />
          <Route
            path="/styling-element-options/:id/:name"
            element={<StylingElementOptions />}
          />
          <Route
            path="/create-styling-option/:id"
            element={<CreateStylingOption />}
          />
          <Route
            path="/upload-Photo-Video/:id"
            element={<UploadPhotoVideo />}
          />
          <Route
            path="/rejected-images-new-images/:id"
            element={<RejectedImageUploadNew />}
          />
          <Route
            path="/indivual-style-sets"
            element={<IndividualStyleSets />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
