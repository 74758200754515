import React from 'react'
import MetaTitle from '../../Components/Helmet/MetaTitle'
import SideBar from '../../Components/Sidebar/Sidebar'
import Header from '../../Components/Navbar/Navbar'

const IndividualStyleSets = () => {
  return (
    <>
    <MetaTitle title={"Styling Elements"} />
    <div className="grid-sidebar-row">
      <div>
        <SideBar />
      </div>
      <div>
        <Header />

      </div>
    </div>
  </>
  )
}

export default IndividualStyleSets
