import React from "react";
import "./GroupedBar.css";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(LinearScale, CategoryScale, BarElement, Legend, Title, Tooltip);

const BarChart = ({ order_chart }) => {
  // const order_chart = [
  //   {
  //     label: "October",
  //     total_article_count: 10,
  //     total_article_content_count: 20,
  //     reshoot_article: 30,
  //     reshoot_article_content_count: 80,
  //   },
  //   {
  //     label: "September",
  //     total_article_count: 10,
  //     total_article_content_count: 40,
  //     reshoot_article: 20,
  //     reshoot_article_content_count: 80,
  //   },
  //   {
  //     label: "August",
  //     total_article_count: 10,
  //     total_article_content_count: 20,
  //     reshoot_article: 60,
  //     reshoot_article_content_count: 50,
  //   },
  //   {
  //     label: "July",
  //     total_article_count: 10,
  //     total_article_content_count: 40,
  //     reshoot_article: 60,
  //     reshoot_article_content_count: 80,
  //   },
  //   {
  //     label: "June",
  //     total_article_count: 10,
  //     total_article_content_count: 10,
  //     reshoot_article: 30,
  //     reshoot_article_content_count: 80,
  //   },
  // ];

  const labels = order_chart && order_chart.map((item) => item.label);
  const keys_dynamic = Object.keys(order_chart && order_chart[0]).filter(
    (key) => key !== "label"
  );

  console.log(order_chart);

  console.log("Keys: ", keys_dynamic);

  const datasets = keys_dynamic.map((key) => {
    const formattedKey = key.replace(/_/g, " ");
    return {
      label: formattedKey,
      data: order_chart.map((item) => item[key]),
      backgroundColor: `rgba(${Math.random() * 255},${Math.random() * 255},${
        Math.random() * 255
      },0.6)`,
      barPercentage: 0.6,
      categoryPercentage: 0.3,
      borderRadius: 100,
    };
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    scales: {
      y: {
        border: { dash: [7, 7] }, // for the grid lines
        grid: {
          color: "#E5E5EF", // for the grid lines
          tickColor: "#E5E5EF", // for the tick mark
          tickBorderDash: [8, 8], // also for the tick, if long enough
          tickLength: 10, // just to see the dotted line
          tickWidth: 1,
          offset: true,
          drawTicks: true, // true is default
          drawOnChartArea: true, // true is default
        },
        ticks: {
          font: {
            family: "Poppins_SemiBold", // Your font family
            size: 12,
          },
        },
        beginAtZero: true,
      },
      x: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          lineWidth: 5,
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins_SemiBold", // Your font family
            size: 12,
          },
        },
      },
    },
    plugins: {
      labels: {
        color: "rgb(255, 99, 132)",
        font: {
          family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
        },
      },
      tooltip: {
        bodyFont: {
          family: "Poppins_SemiBold", // Add your font here to change the font of your tooltip body
        },
        titleFont: {
          family: "Poppins_SemiBold",
        },
      },
      responsive: true,
      legend: {
        position: "bottom",
        labels: {
          font: {
            family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
          },
          usePointStyle: true,
          boxWidth: 6,
          padding: 20,
        },
      },
    },
  };

  return (
    <div className="doubled-chart">
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
