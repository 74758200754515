import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Modal.css";
import { useState } from "react";
import AddPhotoShootRequest from "../ModalContent/Add-PhotoShootRequest/AddPhotoShootRequest";
import AddContentFormat from "../ModalContent/Add-ContentFormat/AddContentFormat";
import ImageSlider from "../ModalContent/ImageSlider/ImageSlider";
import AddStylingElements from "../ModalContent/AddStylingElements/AddStylingElements";
import CancelModal from "../ModalContent/CancelModal/CancelModal";
import UploadPhotosVideos from "../ModalContent/UploadPhotosVideos/UploadPhotosVideos";
import VideoModalContainer from "../ModalContent/VideoModal/VideoModalContainer";
import CloseIcon from "@mui/icons-material/Close";
import StylingSetView from "../ModalContent/StylingSetView/StylingSetView";

const ModalContainer = (props) => {
  const { index } = props;
  const [width, setWidth] = useState(356);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: 0,
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: index === 2 || index === 6 ? 0 : 2,
    borderRadius: index === 2 || index === 6 ? 0 : index === 7 ? 5.5 : 1,
    height:
      index === 2
        ? "413px"
        : index === 7
        ? "462px"
        : index === 5
        ? "600px"
        : "auto",
    border: "none",
    overflowY: index === 5 ? "auto" : "",
    outline: "none",
  };

  const backdropStyle = {
    // Add backdrop filter for blur
    backdropFilter: "blur(3px)",
  };

  const componentMap = {
    0: <AddPhotoShootRequest close={props.closeModal} />,
    1: (
      <AddContentFormat
        id={props.id}
        name={props.name}
        description={props.description}
        close={props.closeModal}
      />
    ),
    2: <ImageSlider close={props.closeModal} imageList={props.imageList} />,
    3: (
      <AddStylingElements
        close={props.closeModal}
        isFill={props.isFill}
        decodeName={props.decodeName}
        styling_item_type_id={props.styling_item_type_id}
      />
    ),
    4: (
      <CancelModal
        SId={props.SId}
        close={props.closeModal}
        isReshoot={props.isReshoot}
        id={props.id}
        PRD_Id={props.PRD_Id}
      />
    ),
    5: (
      <UploadPhotosVideos
        close={props.closeModal}
        conten_format_type={props.conten_format_type}
        photoshoot_request_detail_id={props.photoshoot_request_detail_id}
        reshoot={props.reshoot}
        id={props.id}
        reshoot_again={props.reshoot_again}
        reshoot_request_id={props.reshoot_request_id}
        requested_media_ids={props.requested_media_ids}
        new_reshoot={props.new_reshoot}
      />
    ),
    6: (
      <VideoModalContainer
        close={props.closeModal}
        video_url={props.video_url}
      />
    ),
    7: (
      <StylingSetView
        close={props.closeModal}
        img_uri={props.img_uri}
        id={props.id}
      />
    ),
  };

  useEffect(() => {
    if (index === 0) {
      setWidth(356);
    } else if (index === 2) {
      setWidth(412);
    }
  }, [index]);

  return (
    <Modal
      open={props.Isopen}
      onClose={props.closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: backdropStyle,
      }}
      className="modal-container"
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{ overflow: "scroll" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{componentMap[index]}</Box>
    </Modal>
  );
};

export default ModalContainer;

export const HeadingTitle = (props) => {
  return (
    <>
      <div className="header-cross-title">
        <Typography
          style={{ color: "#1C1C1C" }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          {props.title}
        </Typography>
        <div className="bg-cross" onClick={props.closeModal}>
          {/* <img src={CrossIcon} alt="" /> */}
          <CloseIcon className="cross-icon-col" />
        </div>
      </div>
      {props.desc && (
        <p
          style={{ marginTop: props.descTop ? "5px" : "" }}
          className="description-text"
        >
          {props.desc}
        </p>
      )}
    </>
  );
};
