import React from "react";
import "./Navbar.css";
// import searchIcon from "../../Assets/Header-Icons/search.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const isAuthenticated = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  // const [keyword, setKeyword] = useState();

  const logout = async () => {
    await localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <div className="header-wrapper">
      {/* grid 2 col starts here */}
      <div className="header-grid-row">
        <div>
          <div className="search-area">
            {/* <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search by Email, Mobile Number and Order Number...."
            />
            <Button className="search-btn">
              <img src={searchIcon} alt="searchIcon" />
            </Button> */}
          </div>
        </div>
        <div>
          <div className="avatar-flex-row">
            <div>
              {isAuthenticated && isAuthenticated?.firstname?.slice(0, 1)}
              {isAuthenticated && isAuthenticated?.lastname?.slice(0, 1)}
            </div>
            <div>
              <p>{isAuthenticated && isAuthenticated?.username}</p>
              <Button onClick={logout}>Logout</Button>
            </div>
          </div>
        </div>
      </div>
      {/* grid 2 col ends here */}
    </div>
  );
};

export default Header;
