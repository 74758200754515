import React from "react";
import Loading from "../../Assets/Loader/loading.gif";

const LoadingContent = (props) => {
  return (
    <div
      className="loading-free-icon"
    >
      <img src={Loading} alt="" />
    </div>
  );
};

export default LoadingContent;
