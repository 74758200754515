import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import "./DataTable.css";
import { useNavigate } from "react-router-dom";
import { DEFAULT_IMAGE } from "../../Middleware/DefaultImage";
import DEFAUL_VIDEO_ICON from "../../Assets/StylingAssets/clapperboard-high-efficiency-video-coding-video-player-video-icon-9c8cece0e3ba15290b12a2f15626acdd.png";
import CustomLoader from "../Loading/CustomLoader";

const DataTable = (props) => {
  const navigate = useNavigate();
  const {
    header,
    photoshoot_data_arr,
    Photoshoot_request_details_data,
    photoshoot_request_details,
    style_set_data,
    onCancel,
    handleUploadVideosPhotos,
    handleId,
    handleContentFormat,
    reshoot_alldata,
    loadVideo,
    handleImg,
    handleindex,
    loadImg,
    sendId,
    editIndex,
    editLoading,
    handleDeleteIndex,
  } = props;

  const handleLoadImage = (img) => {
    props.loadImage(img);
  };

  const handleModalCancel = (id) => {
    onCancel(id);
  };

  const [edit_Index, setEdit_index] = useState("");

  const handleEditRequest = (id, index) => {
    setEdit_index(index);
    editIndex(id);
  };

  const handleUpload = (id, data) => {
    handleUploadVideosPhotos(5);
    handleId(id);
    handleContentFormat(data);
  };

  const handlePlayVideo = (video, index) => {
    loadVideo(video);
    handleindex(index);
  };

  const loadApproveImg = (index, img, id) => {
    loadImg(img);
    handleImg(index);
    sendId(id);
  };

  const handleDelete = (id) => {
    handleDeleteIndex(id);
  };

  return (
    <TableContainer>
      <Table className="table" aria-label="dynamic table">
        <TableHead>
          <TableRow>
            {header &&
              header.map((i, index) => (
                <TableCell className="header" key={index}>
                  {i}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {photoshoot_data_arr && photoshoot_data_arr?.length > 0
            ? photoshoot_data_arr?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.total_article}</TableCell>
                  <TableCell>{row.created_by}</TableCell>
                  <TableCell>{row.total_article_completed}</TableCell>
                  <TableCell>{row.total_article_pending}</TableCell>
                  <TableCell>{row.created}</TableCell>
                  <TableCell>
                    <Button
                      className={`status-cta ${
                        row.status === "0"
                          ? "Pending"
                          : row.status === "1"
                          ? "Completed"
                          : row.status === "2"
                          ? "Cancelled"
                          : "status-cta"
                      }`}
                    >
                      {row.status === "0"
                        ? "pending"
                        : row.status === "1"
                        ? "completed"
                        : row.status === "2"
                        ? "cancelled"
                        : null}
                    </Button>
                  </TableCell>
                  <TableCell className="cta-actions">
                    <Button
                      disabled={
                        row.status === "2" || row.status === "1" ? true : false
                      }
                      className={`${
                        row.status === "2" || row.status === "1"
                          ? "edit-disabled-cta"
                          : "edit-cta"
                      }`}
                      onClick={() =>
                        navigate(`/add-photoshoot-request-details/${row.id}`)
                      }
                    >
                      Edit
                    </Button>{" "}
                    <br />
                    <Button
                      disabled={
                        row.status === "2" || row.status === "1" ? true : false
                      }
                      onClick={() => handleModalCancel(row.id)}
                      className={`${
                        row.status === "2" || row.status === "1"
                          ? "disabled-cancel-cta"
                          : "cancel-cta"
                      }`}
                    >
                      {row.status === "2" || row.status === "1"
                        ? "Cancelled"
                        : "Cancel"}
                    </Button>
                    <br />
                    <Button
                      onClick={() =>
                        navigate(`/photoshoot-request-details/${row.id}`)
                      }
                      className="view-cta"
                    >
                      View
                    </Button>{" "}
                    <br />
                  </TableCell>
                </TableRow>
              ))
            : null}

          {Photoshoot_request_details_data &&
          Photoshoot_request_details_data?.length > 0
            ? Photoshoot_request_details_data?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.style_color}</TableCell>
                  <TableCell>{row.product_category_name}</TableCell>
                  <TableCell>{row.content_format_name}</TableCell>
                  <TableCell>
                    <div className="grid-preview-reference add-photoshoot-requiest-grid-row">
                      {row.photoshoot_request_details_reference_medias
                        .slice(0, 4)
                        .map((i, index) => {
                          const fileExtension = i.media_url.split(".").pop();

                          // List of video file extensions (you can extend this list as needed)
                          const videoExtensions = [
                            "mp4",
                            "webm",
                            "avi",
                            "mov",
                            "wmv",
                            "flv",
                            "mkv",
                            "m4v",
                            "3gp",
                            "ogg",
                            "ogv",
                            "mpeg",
                            "mpg",
                            "m2v",
                            "mxf",
                            "asf",
                            "rm",
                            "rmvb",
                            "ts",
                            "vob",
                          ];

                          // Checking if the file extension is in the list of video extensions
                          const isVideo = videoExtensions.includes(
                            fileExtension.toLowerCase()
                          );
                          if (isVideo) {
                            return (
                              <img
                                onClick={() => handlePlayVideo(i.media_url, 6)}
                                key={index}
                                src={DEFAUL_VIDEO_ICON}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                                loading="lazy"
                              />
                            );
                          } else {
                            return (
                              <img
                                onClick={() =>
                                  handleLoadImage(
                                    row.photoshoot_request_details_reference_medias
                                  )
                                }
                                key={index}
                                src={i.media_url}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                                loading="lazy"
                              />
                            );
                          }
                        })}
                    </div>
                  </TableCell>
                  <TableCell className="cta-actions">
                    <Button
                      onClick={() => handleEditRequest(row.id, index)}
                      className="edit-cta"
                    >
                      {edit_Index === index && editLoading ? (
                        <CustomLoader small={true} />
                      ) : (
                        "Edit"
                      )}
                    </Button>{" "}
                    <br />
                    <Button
                      className="cancel-cta"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>{" "}
                    <br />
                  </TableCell>
                </TableRow>
              ))
            : null}

          {style_set_data && style_set_data?.length > 0
            ? style_set_data?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.photoshoot_request_name}</TableCell>
                  <TableCell>
                    <img loading="lazy" src={row.product_image} className="thumbnail" alt="" />
                  </TableCell>
                  <TableCell>{row.style_color}</TableCell>
                  <TableCell>{row.product_category_name}</TableCell>
                  <TableCell>
                    <div className="grid-preview-reference">
                      {row.photoshoot_request_details_reference_medias
                        .slice(0, 3)
                        .map((i) => {
                          const fileExtension = i.media_url.split(".").pop();

                          // List of video file extensions (you can extend this list as needed)
                          const videoExtensions = [
                            "mp4",
                            "webm",
                            "avi",
                            "mov",
                            "wmv",
                            "flv",
                            "mkv",
                            "m4v",
                            "3gp",
                            "ogg",
                            "ogv",
                            "mpeg",
                            "mpg",
                            "m2v",
                            "mxf",
                            "asf",
                            "rm",
                            "rmvb",
                            "ts",
                            "vob",
                          ];

                          // Checking if the file extension is in the list of video extensions
                          const isVideo = videoExtensions.includes(
                            fileExtension.toLowerCase()
                          );
                          if (isVideo) {
                            return (
                              <img
                                onClick={() => handlePlayVideo(i.media_url, 6)}
                                src={DEFAUL_VIDEO_ICON}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                                loading="lazy"
                              />
                            );
                          } else {
                            return (
                              <img
                                onClick={() =>
                                  handleLoadImage(
                                    row.photoshoot_request_details_reference_medias
                                  )
                                }
                                src={i.media_url}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                                loading="lazy"
                              />
                            );
                          }
                        })}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="grid-preview-reference">
                      {row?.styling_sets.slice(-3).map((i, index) => (
                        <div className="preview-style-sets" key={index}>
                          <img
                            onClick={() =>
                              loadApproveImg(7, row?.styling_sets, i.styling_set_id)
                            }
                            className="styling-options"
                            src={i.media_url}
                            alt=""
                            loading="lazy"
                          />
                          {index === 2 && (
                            <div
                              onClick={() =>
                                loadApproveImg(7, row?.styling_sets, i.styling_set_id)
                              }
                              class="overlay"
                            ></div>
                          )}
                          {index === 2 && (
                            <p
                              onClick={() =>
                                loadApproveImg(7, row?.styling_sets, i.styling_set_id)
                              }
                            >
                              {row?.styling_sets?.length}+
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </TableCell>
                </TableRow>
              ))
            : null}

          {photoshoot_request_details && photoshoot_request_details?.length > 0
            ? photoshoot_request_details?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    {" "}
                    <img
                      loading="lazy"
                      className="thumbnail"
                      src={row.product_image}
                      alt=""
                    />
                  </TableCell>
                  <TableCell>{row.style_color}</TableCell>
                  <TableCell>{row.product_category_name}</TableCell>
                  <TableCell>{row.content_format_name}</TableCell>
                  <TableCell>
                    <div className="grid-preview-reference">
                      {row.photoshoot_request_details_reference_medias
                        .slice(0, 3)
                        .map((i, index) => {
                          const fileExtension = i.media_url.split(".").pop();

                          // List of video file extensions (you can extend this list as needed)
                          const videoExtensions = [
                            "mp4",
                            "webm",
                            "avi",
                            "mov",
                            "wmv",
                            "flv",
                            "mkv",
                            "m4v",
                            "3gp",
                            "ogg",
                            "ogv",
                            "mpeg",
                            "mpg",
                            "m2v",
                            "mxf",
                            "asf",
                            "rm",
                            "rmvb",
                            "ts",
                            "vob",
                          ];

                          // Checking if the file extension is in the list of video extensions
                          const isVideo = videoExtensions.includes(
                            fileExtension.toLowerCase()
                          );
                          if (isVideo) {
                            return (
                              <img
                                loading="lazy"
                                onClick={() => handlePlayVideo(i.media_url, 6)}
                                key={index}
                                src={DEFAUL_VIDEO_ICON}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                              />
                            );
                          } else {
                            return (
                              <img
                                loading="lazy"
                                onClick={() =>
                                  handleLoadImage(
                                    row.photoshoot_request_details_reference_medias
                                  )
                                }
                                key={index}
                                src={i.media_url}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                              />
                            );
                          }
                        })}
                    </div>
                  </TableCell>
                  <TableCell className="cta-actions request-cta">
                    <Button
                      className="view-option-cta"
                      onClick={() => navigate(`/style-sets/${row.id}`)}
                    >
                      View Option
                    </Button>{" "}
                    <br />
                    <Button
                      onClick={() =>
                        navigate(`/create-styling-option/${row.id}`)
                      }
                      className="add-more-cta"
                    >
                      Add More
                    </Button>{" "}
                    <br />
                  </TableCell>
                  <TableCell className="cta-actions request-cta">
                    <Button
                      className="upload-cta"
                      onClick={() =>
                        handleUpload(row.id, row.content_format_name)
                      }
                    >
                      Upload
                    </Button>{" "}
                    <br />
                    <Button
                      onClick={() => navigate(`/upload-Photo-Video/${row.id}`)}
                      className="view-cta"
                    >
                      View
                    </Button>{" "}
                    <br />
                  </TableCell>
                </TableRow>
              ))
            : null}

          {reshoot_alldata && reshoot_alldata?.length > 0
            ? reshoot_alldata?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    <img loading="lazy" className="thumbnail" src={row.product_image} alt="" />
                  </TableCell>
                  <TableCell>{row.style_color}</TableCell>
                  <TableCell>{row.product_category_name}</TableCell>
                  <TableCell>{row.content_format_name}</TableCell>
                  <TableCell>
                    {/* <div
                      className="grid-preview-reference"
                      onClick={() =>
                        handleLoadImage(row.reshoot_request_reference_medias)
                      }
                    >
                      {row.reshoot_request_reference_medias
                        .slice(0, 3)
                        .map((i, index) => (
                          <img
                            key={index}
                            src={i.media_url}
                            onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                            alt=""
                          />
                        ))}
                    </div> */}

                    <div className="grid-preview-reference">
                      {row.reshoot_request_reference_medias
                        .slice(0, 3)
                        .map((i, index) => {
                          const fileExtension = i.media_url.split(".").pop();

                          // List of video file extensions (you can extend this list as needed)
                          const videoExtensions = [
                            "mp4",
                            "webm",
                            "avi",
                            "mov",
                            "wmv",
                            "flv",
                            "mkv",
                            "m4v",
                            "3gp",
                            "ogg",
                            "ogv",
                            "mpeg",
                            "mpg",
                            "m2v",
                            "mxf",
                            "asf",
                            "rm",
                            "rmvb",
                            "ts",
                            "vob",
                          ];

                          // Checking if the file extension is in the list of video extensions
                          const isVideo = videoExtensions.includes(
                            fileExtension.toLowerCase()
                          );
                          if (isVideo) {
                            return (
                              <img
                                onClick={() => handlePlayVideo(i.media_url, 6)}
                                key={index}
                                src={DEFAUL_VIDEO_ICON}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                                loading="lazy"
                              />
                            );
                          } else {
                            return (
                              <img
                                onClick={() =>
                                  handleLoadImage(
                                    row.reshoot_request_reference_medias
                                  )
                                }
                                key={index}
                                src={i.media_url}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                                loading="lazy"
                              />
                            );
                          }
                        })}
                    </div>
                  </TableCell>
                  <TableCell className="cta-actions request-cta">
                    <Button
                      className="view-option-cta"
                      onClick={() =>
                        navigate(
                          `/style-sets/${row.photoshoot_request_detail_id}`
                        )
                      }
                    >
                      View Option
                    </Button>{" "}
                    <br />
                    <Button
                      onClick={() =>
                        navigate(
                          `/create-styling-option/${row.photoshoot_request_detail_id}`
                        )
                      }
                      className="add-more-cta"
                    >
                      Add More
                    </Button>{" "}
                    <br />
                  </TableCell>
                  <TableCell>{row.created}</TableCell>
                  <TableCell>
                    <Button
                      className={`status-cta ${
                        row.status === "0"
                          ? "Pending"
                          : row.status === "1"
                          ? "Completed"
                          : row.status === "2"
                          ? "Cancelled"
                          : "status-cta"
                      }`}
                    >
                      {row.status === "0"
                        ? "pending"
                        : row.status === "1"
                        ? "completed"
                        : row.status === "2"
                        ? "cancelled"
                        : null}
                    </Button>
                  </TableCell>
                  <TableCell className="cta-actions request-cta">
                    <Button
                      disabled={
                        row.status === "2" || row.status === "1" ? true : false
                      }
                      className="upload-cta"
                      onClick={() =>
                        navigate(`/rejected-images-new-images/${row.id}`)
                      }
                    >
                      Upload
                    </Button>{" "}
                    <br />
                    <Button
                      onClick={() =>
                        navigate(
                          `/upload-Photo-Video/${row.id}?reshoot=${true}`
                        )
                      }
                      className="view-cta"
                    >
                      View
                    </Button>{" "}
                    <br />
                    <Button
                      disabled={
                        row.status === "2" || row.status === "1" ? true : false
                      }
                      onClick={() => handleModalCancel(row.id)}
                      className="cancel-cta"
                    >
                      {row.status === "2" || row.status === "1"
                        ? "Cancelled"
                        : "Cancel"}
                    </Button>
                    <br />
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
