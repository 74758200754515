import React, { useEffect, useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import { useNavigate, useParams } from "react-router-dom";
import "./PhotoshootRequestDetails.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getContentFormatDataActions,
  getListofCategoryActions,
  getListofStylesActions,
  getPhotoShootRequestDataByIdActions,
  getPhotoShootRequestDetailsDataActions,
} from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import { add_photoshoot_request_details_arr } from "../../Components/DataTable/DummyHeaderArray";
import DataTable from "../../Components/DataTable/DataTable";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { Pagination } from "../../Components/Pagination/Pagination";
import { useAlert } from "react-alert";
import api from "../../Middleware/AxiosConfig";
import { BASE_URL } from "../../Middleware/BaseURL";

export const status_arr = [
  {
    id: 0,
    name: "Pending",
  },
  {
    id: 1,
    name: "Completed",
  },
  {
    id: 2,
    name: "Cancelled",
  },
];

const PhotoShootRequestDetails = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  //   photoshoot request by id state
  const { Photoshoot_request_data_by_id, loading } = useSelector(
    (state) => state.getPhotoshootReqDataById
  );

  //   list of category state dropdown
  const { list_of_Category, loading: category_list_loading } = useSelector(
    (state) => state.listCategories
  );

  //   content format type read state
  const { content_format_data, loading: content_format_loading } = useSelector(
    (state) => state.showContentFormat
  );

  //   list of styles state dropdown
  const { list_of_styles, loading: style_list_loading } = useSelector(
    (state) => state.listofstyles
  );

  //   photoshoot request details state
  const {
    Photoshoot_request_details_data,
    loading: photoshoot_details_loading,
  } = useSelector((state) => state.photoshoot_details);

  const [page, setPage] = useState(1);

  const [photoshoot_request_details, setPhotoshoot_request_details] = useState(
    []
  );

  const [pagination_loading, setPagination_loading] = useState(false);

  // filters state
  const [style_color, setStyle_color] = useState("");

  const [category_display, setCategory_display] = useState("");
  const [category_id, setCategory_id] = useState("");

  const [content_format_id, setcontent_format_id] = useState("");
  const [content_format_display, setcontent_format_display] = useState("");

  const [status, setStatus] = useState("");
  const [status_id, setStatus_id] = useState("");

  const [isFilter_Active, setIsFilter_Active] = useState(false);

  const [totalPage_new, setTotal_page_new] = useState("");

  const fetchFilters = async (filters) => {
    try {
      // Remove keys with empty or null values
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(
          ([_, value]) => value !== null && value !== ""
        )
      );
      let response = await api.post(
        `${BASE_URL}/photoshoot_request_detail_read`,
        filteredFilters
      );

      if (response && response?.data?.status === 1) {
        setPhotoshoot_request_details(response && response?.data?.data);
      }
      setTotal_page_new(response && response?.data?.pagination?.last_page);

      console.log("RESPONSE: ", response.data);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const handleStyle_Color = (val) => {
    setIsFilter_Active(true);
    setStyle_color(val?.value);
    const filters = {
      photoshoot_request_id: id,
      style_color: val?.value,
      category_id: category_id,
      content_format_id: content_format_id,
      status: status_id,
    };
    fetchFilters(filters);
  };

  const handleCategory = (val) => {
    setIsFilter_Active(true);
    setCategory_display(val?.value);
    setCategory_id(val?.id);
    const filters = {
      photoshoot_request_id: id,
      style_color: style_color,
      category_id: val?.id,
      content_format_id: content_format_id,
      status: status_id,
    };
    fetchFilters(filters);
  };

  const handleContent_Format = (val) => {
    setIsFilter_Active(true);
    setcontent_format_id(val?.id);
    setcontent_format_display(val?.value);
    const filters = {
      photoshoot_request_id: id,
      style_color: style_color,
      category_id: category_id,
      content_format_id: val?.id,
      status: status_id,
    };
    fetchFilters(filters);
  };

  const handleStatus = (val) => {
    setIsFilter_Active(true);
    setStatus(val?.value);
    setStatus_id(val?.id);
    const filters = {
      photoshoot_request_id: id,
      style_color: style_color,
      category_id: category_id,
      content_format_id: content_format_id,
      status: val?.id,
    };
    fetchFilters(filters);
  };

  // filters state

  console.log("STYLE_COLOR: ", style_color);

  const fetchNewPhotoshootRequestDetails = async (
    page,
    style_color,
    category_id,
    content_format_id,
    status_id
  ) => {
    setPagination_loading(true);
    const filters = {
      photoshoot_request_id: id,
      page: page,
      style_color: style_color,
      category_id: category_id,
      content_format_id: content_format_id,
      status: status_id,
    };

    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([_, value]) => value !== null && value !== ""
      )
    );
    try {
      let response = await api.post(
        `${BASE_URL}/photoshoot_request_detail_read`,
        filteredFilters
      );

      if (response && response?.data?.status === 1) {
        setPhotoshoot_request_details(response && response?.data?.data);
        setPagination_loading(false);
      }
      setTotal_page_new(response && response?.data?.pagination?.last_page);
    } catch (error) {
      alert.error(error.message);
      setPagination_loading(false);
    }
  };

  const handlePage = (i) => {
    setPage(i);
    fetchNewPhotoshootRequestDetails(
      i,
      style_color,
      category_id,
      content_format_id,
      status_id
    );
  };

  const handleRedirect = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id && id !== null) {
      dispatch(getPhotoShootRequestDataByIdActions(id));
    }
  }, [id, dispatch]);

  // read
  useEffect(() => {
    if (id && id !== null && !isFilter_Active) {
      dispatch(getPhotoShootRequestDetailsDataActions(id));
    }
  }, [dispatch, id, isFilter_Active]);

  console.log("PhotoShoot Request Data: ", Photoshoot_request_details_data);

  const [imageList, setImageList] = useState([]);
  const [isIndex, setIsIndex] = useState(null);

  const [photoshoot_request_detail_id, setphotoshoot_request_detail_id] =
    useState("");
  const [conten_format_type, setContent_format_type] = useState("");

  const handleModal = (index) => {
    setIsIndex(index);
  };

  const handleUploadVideosPhotos = (index) => {
    handleModal(index);
  };

  const handleImgSlider = (imageList) => {
    console.log("Image List: ", imageList);
    setImageList(imageList);
    handleModal(2);
  };

  const handleId = (id) => {
    setphotoshoot_request_detail_id(id);
  };

  const handleContentFormat = (data) => {
    setContent_format_type(data);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const [uri, setUri] = useState("");

  const handleVideoUri = (video) => {
    setUri(video);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          imageList={imageList}
          photoshoot_request_detail_id={photoshoot_request_detail_id}
          conten_format_type={conten_format_type}
          video_url={uri}
        />
      );
    }
    return null;
  };

  console.log(isIndex);

  useEffect(() => {
    dispatch(getListofCategoryActions());
    dispatch(getContentFormatDataActions());
    dispatch(getListofStylesActions());
  }, [dispatch]);

  let content_format_dropdown =
    content_format_data && content_format_data?.data?.length > 0
      ? content_format_data?.data?.map(
          ({
            content_format_images,
            created,
            created_by,
            description,
            modified,
            modified_by,
            ...rest
          }) => rest
        )
      : [];

  useEffect(() => {
    Photoshoot_request_details_data &&
      setPhotoshoot_request_details(
        Photoshoot_request_details_data && Photoshoot_request_details_data?.data
      );
  }, [Photoshoot_request_details_data]);

  return (
    <>
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading || photoshoot_details_loading ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <div>
                <HeaderProps
                  title="Photoshoot Request Details"
                  hide={true}
                  redirect={true}
                  handleRedirect={handleRedirect}
                />
              </div>
              {/* header component ends here */}

              <div className="photoshoot-request-details-create">
                <div className="display-readonly-data">
                  <div>
                    <label htmlFor="" className="label">
                      Photoshoot Name
                    </label>
                    <input
                      className="input-readonly increase-text"
                      type="text"
                      defaultValue={
                        Photoshoot_request_data_by_id &&
                        Photoshoot_request_data_by_id?.data
                          ?.photoshoot_request_name
                      }
                      readOnly
                    />
                  </div>
                  <div>
                    <label htmlFor="" className="label">
                      Remarks
                    </label>
                    <input
                      type="text"
                      className="input-readonly increase-text"
                      defaultValue={
                        Photoshoot_request_data_by_id &&
                        Photoshoot_request_data_by_id?.data
                          ?.photoshoot_request_remark
                      }
                      readOnly
                    />
                  </div>
                </div>
              </div>

              {/* dropdown container */}
              <div
                className="dropdown-filter-container"
                style={{ marginBottom: "1.5rem" }}
              >
                <Dropdown
                  placeholder="Style Color"
                  bgColor={true}
                  dropdown={
                    list_of_styles && list_of_styles?.data?.length > 0
                      ? list_of_styles?.data
                      : []
                  }
                  loading={style_list_loading}
                  value={style_color}
                  onData={handleStyle_Color}
                />
                <Dropdown
                  placeholder="Category"
                  bgColor={true}
                  dropdown={
                    list_of_Category && list_of_Category?.data?.length > 0
                      ? list_of_Category?.data
                      : []
                  }
                  loading={category_list_loading}
                  value={category_display}
                  onData={handleCategory}
                />
                <Dropdown
                  placeholder="Content format type"
                  bgColor={true}
                  dropdown={
                    content_format_dropdown &&
                    content_format_dropdown?.length > 0
                      ? content_format_dropdown
                      : []
                  }
                  loading={content_format_loading}
                  value={content_format_display}
                  onData={handleContent_Format}
                />
                <Dropdown
                  placeholder="Status"
                  bgColor={true}
                  dropdown={
                    status_arr && status_arr?.length > 0 ? status_arr.slice(0,2) : []
                  }
                  // loading={category_list_loading}
                  value={status}
                  onData={handleStatus}
                />
              </div>
              {/* dropdown container */}

              <div className="table-wrapper">
                <div className="table-container">
                  <DataTable
                    header={add_photoshoot_request_details_arr}
                    loadImage={handleImgSlider}
                    photoshoot_request_details={
                      photoshoot_request_details &&
                      photoshoot_request_details?.length > 0
                        ? photoshoot_request_details
                        : []
                    }
                    handleUploadVideosPhotos={handleUploadVideosPhotos}
                    handleId={handleId}
                    handleContentFormat={handleContentFormat}
                    loadVideo={handleVideoUri}
                    handleindex={handleModal}
                  />
                </div>
              </div>

              {/* Pagination component starts here */}
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenA-${page}`}
                  page={page}
                  isLoading={pagination_loading && pagination_loading}
                  totalPage={
                    totalPage_new !== ""
                      ? totalPage_new
                      : Photoshoot_request_details_data &&
                        Photoshoot_request_details_data?.pagination?.last_page
                  }
                  onPageChange={handlePage}
                />
              </div>
              {/* Pagination component ends here */}
            </div>
          )}
          {/* content format data */}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default PhotoShootRequestDetails;
