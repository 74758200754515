import React, { useEffect, useState } from "react";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import "./StylingElements.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loading/Loader";
import { getStylingElementsDataActions } from "../../Actions/StyleActions";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import Vector from "../../Assets/StylingAssets/Frame.png";
import { DEFAULT_IMAGE } from "../../Middleware/DefaultImage";
import { useNavigate } from "react-router-dom";
import MetaTitle from "../../Components/Helmet/MetaTitle";
import { useAlert } from "react-alert";
import DisplayError from "../../Components/DisplayError/DisplayError";

const StylingElements = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { styling_elements_data, loading, error } = useSelector(
    (state) => state.styling_elements
  );

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (isIndex === null) {
      dispatch(getStylingElementsDataActions());
    }
  }, [dispatch, isIndex]);

  console.log("Styling Elements Data: ", styling_elements_data);

  useEffect(() => {
    if (error) {
      alert.error(error);
    }
  }, [alert, error]);

  return (
    <>
      <MetaTitle title={loading ? "Loading..." : "Styling Elements"} />
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : error ? (
            <DisplayError error={error} />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderProps title="Styling Elements" hide={true} />
              {/* header component ends here */}

              {/* images map code starts here */}
              <div className="image-code-container-grid-row">
                <div onClick={() => handleModal(3)} className="add-pStyling">
                  <img src={Vector} alt="" />
                </div>
                {styling_elements_data &&
                styling_elements_data?.data?.length > 0
                  ? styling_elements_data?.data?.map((i, index) => (
                      <div className="image-code-container-grid-col" key={i.id}>
                        <div>
                          <div
                            onClick={() =>
                              navigate(
                                `/styling-element-options/${i.id}/${i.name}`
                              )
                            }
                            className="img-fluid"
                          >
                            <img
                              className="img-fluid-elements"
                              src={i.media_url}
                              onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                              alt=""
                              loading="lazy"
                            />
                            <div className="text-container-overlap">
                              <p>{i.name}</p>
                              <p>{i.styling_item_count} Products</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
              {/* images map code ends here */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default StylingElements;
