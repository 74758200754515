export const routes = [
  {
    path: "/",
    title: "Dashboard",
    icon: require("../../Assets/Sidebar-Icons/home-white.png"),
    iconColor: require('../../Assets/Sidebar-Icons/home-color.png')
  },
  {
    path: "/shoot-requirement",
    title: "Shoot Requirement",
    icon: require("../../Assets/Sidebar-Icons/camera-white.png"),
    iconColor: require('../../Assets/Sidebar-Icons/Camera-color.png')
  },
  {
    path: "/styling-elements",
    title: "Styling Elements",
    icon: require("../../Assets/Sidebar-Icons/Fashion-white.png"),
    iconColor: require('../../Assets/Sidebar-Icons/Fashion-color.png')
  },
  {
    path: "/style-sets",
    title: "Style Sets",
    icon: require("../../Assets/Sidebar-Icons/Male-clothes-white.png"),
    iconColor: require('../../Assets/Sidebar-Icons/Male-clothes-color.png')
  },
  {
    path: "/reshoot-requirement",
    title: "Reshoot",
    icon: require("../../Assets/Sidebar-Icons/Media-white.png"),
    iconColor: require('../../Assets/Sidebar-Icons/Media-color.png')
  },
  {
    path: "/content-format",
    title: "Content Format",
    icon: require("../../Assets/Sidebar-Icons/Content-white.png"),
    iconColor: require('../../Assets/Sidebar-Icons/Content-color.png')
  },
];
