import React, { useCallback, useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import { Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import vectorUploadIcon from "../../../Assets/StylingAssets/Vector.png";
import { BASE_URL } from "../../../Middleware/BaseURL";
import api from "../../../Middleware/AxiosConfig";
import { useAlert } from "react-alert";
import CustomLoader from "../../../Components/Loading/CustomLoader";
import CancelIcon from "@mui/icons-material/Cancel";

const AddStylingElements = (props) => {
  const alert = useAlert();
  const { close, styling_item_type_id } = props;

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const [name, setName] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const [filePreviews, setFilePreviews] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Clear the files array and file previews before adding a new file
      setImage();
      setFilePreviews([]);

      const allowedExtensions = ["jpg", "jpeg", "png"];
      const filteredFiles = acceptedFiles.filter((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        return (
          file.type.startsWith("image/") &&
          allowedExtensions.includes(extension)
        );
      });

      if (filteredFiles.length !== acceptedFiles.length) {
        alert.error("Invalid file format. Only Jpg, Png & Jpeg files are allowed.");
        return;
      }

      // Add the accepted file to the arrays
      setImage(filteredFiles[0]);

      // Generate a file preview for the accepted file
      const file = filteredFiles[0];
      if (file.type.startsWith("image/")) {
        const previewUrl = URL.createObjectURL(file);
        setFilePreviews([previewUrl]);
      }
      // const preview = Object.assign(file, {
      //   preview: URL.createObjectURL(file),
      // });
    },
    [alert]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleAdd = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("name", name);

    // Append each image to the formData
    formData.append(`image`, image);

    setLoading(true);
    api
      .post(`${BASE_URL}/styling_item_type_create`, formData)
      .then((response) => {
        if (response && response?.data?.status === 1) {
          alert.success(response && response?.data?.message);
          closeModal();
          setLoading(false);
        } else if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        // Handle network errors and errors thrown in the 'then' block
        alert.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleOption = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("styling_item_type_id", styling_item_type_id);
    formData.set("name", name);

    // Append each image to the formData
    formData.append(`media`, image);

    setLoading(true);
    api
      .post(`${BASE_URL}/styling_item_create`, formData)
      .then((response) => {
        if (response && response?.data?.status === 1) {
          alert.success(response && response?.data?.message);
          closeModal();
          setLoading(false);
        } else if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        // Handle network errors and errors thrown in the 'then' block
        alert.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  console.log("File Previews: ", filePreviews);

  const handleDelete = (index) => {
    let filter_preview =
      filePreviews && filePreviews.filter((i, ix) => ix !== index);
    setImage();
    setFilePreviews(filter_preview);
  };

  return (
    <>
      <HeadingTitle
        title={`Add Styling`}
        // desc={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.....`}
        closeModal={close}
      />
      <form onSubmit={props.isFill ? handleOption : handleAdd}>
        <div className="add-photoshoot-request">
          <label className="label" htmlFor="stylingtypename">
            Styling Element Name
          </label>{" "}
          <br />
          <input
            className={`${props.isFill ? "input-readonly" : "input"}`}
            type="text"
            name="stylingtypename"
            id="stylingtypename"
            disabled={props.isFill ? true : false}
            value={props.isFill ? props.decodeName : name}
            onChange={(e) => setName(e.target.value)}
          />{" "}
          <br />
          {props.isFill && (
            <>
              <label className="label" htmlFor="stylingtypename">
                Styling Element Option Name
              </label>{" "}
              <br />
              <input
                className="input"
                type="text"
                name="stylingtypename"
                id="stylingtypename"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />{" "}
              <br />
            </>
          )}
          <div className="browse-files" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                <img src={vectorUploadIcon} alt="" />
                <p className="drag-tag">
                  Drag and Drop file here Or <span>Choose file</span>
                </p>
                <p className="drag-small-tag">
                  Support formats: JPG, PNG, JPEG
                </p>
              </>
            ) : (
              <>
                <img src={vectorUploadIcon} alt="" />
                <p className="drag-tag">
                  Drag and Drop file here Or <span>Choose file</span>
                </p>
                <p className="drag-small-tag">
                  Support formats: JPG, PNG, JPEG
                </p>
              </>
            )}
          </div>
          <div className="submit-cta">
            <Button type="submit">
              {loading ? <CustomLoader /> : "Submit"}
            </Button>
          </div>
          <div className="image-previews">
            {filePreviews.map((file, index) => (
              <div className="cross-icon-image-preview" key={index}>
                <Button
                  style={{ right: "-10px" }}
                  onClick={() => handleDelete(index)}
                >
                  <CancelIcon />
                </Button>
                <img
                  key={index}
                  src={file}
                  alt={`Preview ${index}`}
                  className="file-preview"
                />
              </div>
            ))}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddStylingElements;
