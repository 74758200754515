import React, { useEffect, useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import DataTable from "../../Components/DataTable/DataTable";
import { Photoshoot_request_header_arr } from "../../Components/DataTable/DummyHeaderArray";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import { getPhotoShootRequestDataActions } from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import MetaTitle from "../../Components/Helmet/MetaTitle";
import { useAlert } from "react-alert";
import DisplayError from "../../Components/DisplayError/DisplayError";
import { Pagination } from "../../Components/Pagination/Pagination";
import api from "../../Middleware/AxiosConfig";
import { BASE_URL } from "../../Middleware/BaseURL";

const ShootRequirement = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [SId, setSId] = useState("");

  // photoshoot_table_data_state
  const [photoshoot_data, setPhotoshoot_data] = useState([]);

  const handleCancel = (id) => {
    setSId(id);
    handleModal(4);
    // alert.show(id);
  };

  // get photoshoot request data state
  const { Photoshoot_request_data, loading, error } = useSelector(
    (state) => state.getPhotoshootReqData
  );

  const addPhotoshootRequest = () => {
    handleModal(0);
  };

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          SId={SId}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (isIndex === null) {
      dispatch(getPhotoShootRequestDataActions());
    }
  }, [dispatch, isIndex]);

  // let photoshoot_data_arr =
  //   Photoshoot_request_data && Photoshoot_request_data?.data?.length > 0
  //     ? Photoshoot_request_data?.data
  //     : null;

  console.log("PhotoShoot Request Data: ", Photoshoot_request_data);

  useEffect(() => {
    if (error) {
      alert.error(error);
    }
  }, [alert, error]);

  useEffect(() => {
    Photoshoot_request_data &&
      Array.isArray(Photoshoot_request_data?.data) &&
      setPhotoshoot_data(
        Photoshoot_request_data && Photoshoot_request_data?.data
      );
  }, [Photoshoot_request_data]);

  const [page, setPage] = useState(1);
  const [pagination_loading, setPagination_loading] = useState(false);

  const fetchNextShoot = async (page) => {
    setPagination_loading(true);
    try {
      const response = await api.post(`${BASE_URL}/photoshoot_request_read`, {
        page,
      });

      if (response && response?.data?.status === 1) {
        setPhotoshoot_data(response && response?.data?.data);
        setPagination_loading(false);
      }
    } catch (error) {
      alert.error(error.message);
      setPagination_loading(false);
    }
  };

  const handlePage = (i) => {
    setPage(i);
    fetchNextShoot(i);
  };

  return (
    <>
      <MetaTitle title={loading ? "Loading..." : "Shoot Requirement"} />
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : error ? (
            <DisplayError error={error} />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderProps
                title="Photoshoot Request"
                btn={true}
                handleAdd={addPhotoshootRequest}
              />
              {/* header component ends here */}

              {/* table component starts here */}
              <div className="table-wrapper">
                <div className="table-container">
                  <DataTable
                    header={Photoshoot_request_header_arr}
                    photoshoot_data_arr={photoshoot_data && photoshoot_data}
                    onCancel={handleCancel}
                  />
                </div>
              </div>
              {/* table component ends here */}

              {/* Pagination component starts here */}
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenA-${page}`}
                  page={page}
                  isLoading={pagination_loading && pagination_loading}
                  totalPage={
                    Photoshoot_request_data &&
                    Photoshoot_request_data?.pagination?.last_page
                  }
                  onPageChange={handlePage}
                />
              </div>
              {/* Pagination component ends here */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default ShootRequirement;
