import React, { useCallback, useEffect, useState } from "react";
import "./AddPhotoShootRequest.css";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AddPhotoShootRequestActions } from "../../../Actions/StyleActions";
import CustomLoader from "../../Loading/CustomLoader";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

const AddPhotoShootRequest = (props) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();

  // add photoshoot request state
  const { add_Photoshoot, loading } = useSelector(
    (state) => state.add_photoshoot_requ
  );
  const { close } = props;

  const [name, setName] = useState("");
  const [remarks, setRemarks] = useState("");
  const [AddHit, setAddHit] = useState(false);

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const handleAdd = (e) => {
    e.preventDefault();
    setAddHit(true);
    dispatch(AddPhotoShootRequestActions(name, remarks));
  };

  useEffect(() => {
    if (add_Photoshoot && add_Photoshoot?.status === 1 && AddHit) {
      navigate(
        `/add-photoshoot-request-details/${
          add_Photoshoot && add_Photoshoot?.data?.id
        }`
      );
    }
  }, [add_Photoshoot, navigate, AddHit]);

  console.log(
    "Add PhotoShoot Data: ",
    add_Photoshoot && add_Photoshoot?.data?.data?.id
  );

  useEffect(() => {
    if (add_Photoshoot && add_Photoshoot?.status === 1 && AddHit) {
      alert.success(add_Photoshoot && add_Photoshoot?.message);
      closeModal();
    } else if (add_Photoshoot && add_Photoshoot?.status === 0 && AddHit) {
      alert.error(add_Photoshoot && add_Photoshoot?.message);
    }
  }, [add_Photoshoot, alert, AddHit, closeModal]);

  console.log(add_Photoshoot);
  return (
    <>
      <HeadingTitle title={`Add Photoshoot \n Request`} closeModal={close} />
      <form className="request-top" onSubmit={(e) => handleAdd(e)}>
        <div className="add-photoshoot-request">
          <label className="label" htmlFor="name">
            Photoshoot Name
          </label>{" "}
          <br />
          <input
            className="input"
            type="text"
            name="name"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />{" "}
          <br />
          <label className="label" htmlFor="remarks">
            Remarks
          </label>{" "}
          <br />
          <textarea
            className="input-textarea"
            name="remarks"
            id="remarks"
            cols="30"
            rows="2"
            onChange={(e) => setRemarks(e.target.value)}
          ></textarea>{" "}
          <br />
          <div className="save-cta">
            <Button type="submit">{loading ? <CustomLoader /> : "Save"}</Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddPhotoShootRequest;
