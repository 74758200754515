import {
  AddPhotoShootRequestReducers,
  LoginUserReducers,
  PhotoshootRequest_detailsDelete_reducers,
  PhotoshootRequest_detailsEdit_reducers,
  approve_styleSet_reducers,
  cancel_shootRequirement_reducers,
  content_format_details_update_reducers,
  getAllPhotoshoot_data_Reducers,
  getAllReshoot_data_Reducers,
  getArticle_wise_barChart_reducers,
  getContentFormatDataByIdReducers,
  getContentFormatDataReducers,
  getDashboardDoughnutCategoryWiseReducers,
  getDashboardDoughnutContentWiseReducers,
  getDashboardMetricsReducers,
  getListofCategoryReducers,
  getListofPhotoshootNameReducers,
  getListofStyleReducers,
  getPhotoShootRequestDataByIdReducers,
  getPhotoShootRequestDataReducers,
  getPhotoShootRequestDetailsReducers,
  getReshoot_details_reducers,
  getStyleSetsTable_data_Reducers,
  getStylingElementDataReducers,
  getStylingElementOptionDataReducers,
  reject_styleSet_reducers,
  reshoot_requirement_cancel_reducers,
} from "./Reducers/StyleReducers";

const { combineReducers, createStore, applyMiddleware } = require("redux");
const { composeWithDevTools } = require("redux-devtools-extension");
const { default: thunk } = require("redux-thunk");

const reducers = combineReducers({
  login: LoginUserReducers,
  dashboard: getDashboardMetricsReducers,
  contentWise: getDashboardDoughnutContentWiseReducers,
  categoryWise: getDashboardDoughnutCategoryWiseReducers,
  showContentFormat: getContentFormatDataReducers,
  contentFormatId: getContentFormatDataByIdReducers,
  add_photoshoot_requ: AddPhotoShootRequestReducers,
  getPhotoshootReqData: getPhotoShootRequestDataReducers,
  getPhotoshootReqDataById: getPhotoShootRequestDataByIdReducers,
  listofstyles: getListofStyleReducers,
  photoshoot_details: getPhotoShootRequestDetailsReducers,
  styling_elements: getStylingElementDataReducers,
  styling_option: getStylingElementOptionDataReducers,
  styleSetsData: getStyleSetsTable_data_Reducers,
  listCategories: getListofCategoryReducers,
  photoshoot_nameList: getListofPhotoshootNameReducers,
  photoshoot_data: getAllPhotoshoot_data_Reducers,
  reshoot_data: getAllReshoot_data_Reducers,
  reshoot_details: getReshoot_details_reducers,
  articleWise_data: getArticle_wise_barChart_reducers,
  cancelShootReq: cancel_shootRequirement_reducers,
  approveStyleSet: approve_styleSet_reducers,
  rejectStyleSet: reject_styleSet_reducers,
  photoshoot_edit_details: PhotoshootRequest_detailsEdit_reducers,
  photoshoot_delete_details: PhotoshootRequest_detailsDelete_reducers,
  reshoot_req_cancel: reshoot_requirement_cancel_reducers,
  contentformat_updateDetails: content_format_details_update_reducers
});

const middleware = [thunk];

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
