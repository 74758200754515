import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ hoverOffset, padding, data_arr }) => {
  console.log("Props: ", data_arr);
  // const data_list = [
  //   {
  //     name: "Photos",
  //     count: 120,
  //   },
  //   {
  //     name: "Videos",
  //     count: 60,
  //   },
  //   {
  //     name: "Reels",
  //     count: 20,
  //   },
  // ];

  defaults.font.family = "Poppins_SemiBold";

  const getRandomColor = () => {
    const letters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const dataProperties = Object.keys(data_arr[0]).filter(
    (key) => key !== "name"
  );

  const datasets = dataProperties.map((key, index) => {
    const label = key;
    const backgroundColors = data_arr && data_arr.map(() => getRandomColor()); // Generate unique colors for each data point
    return {
      label: label,
      data: data_arr.map((data) => parseInt(data[key])),
      backgroundColor: backgroundColors,
      hoverOffset: hoverOffset,
      borderRadius: 7,
    };
  });

  const labels = data_arr && data_arr.map((data) => data.name);

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    layout: {
      padding: padding,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          padding: 10,
          font: {
            family: "Poppins_SemiBold", // Add your font here to change the font of your legend label
          },
          usePointStyle: true, // Use point style markers
        },
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;

      // calulating average
      let sum = 0;
      data?.datasets?.forEach((i) => {
        i?.data?.forEach((j) => {
          sum += j;
        });
      });

      const xcoor = chart.getDatasetMeta(0).data[0].x;
      const ycoor = chart.getDatasetMeta(0).data[0].y;
      ctx.save();
      ctx.font = "12px Poppins_Regular";
      ctx.fillStyle = "#9291A5";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`Total`, xcoor, ycoor + 20);

      ctx.font = "24px Poppins_Bold";
      ctx.fillStyle = "#1E1B39";
      ctx.fillText(`${sum}`, xcoor, ycoor);
    },
  };

  return (
    <Doughnut
      plugins={[textCenter]}
      className="doughnut-chart"
      data={data}
      options={options}
    />
  );
};

export default DoughnutChart;
