import React, { useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import "./CancelModal.css";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  PhotoshootRequest_detailsDelete_Actions,
  cancel_ShootRequirement_Actions,
  reshoot_requirement_cancel_Actions,
} from "../../../Actions/StyleActions";
import CustomLoader from "../../Loading/CustomLoader";
import { useEffect } from "react";
import { useAlert } from "react-alert";

const CancelModal = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { cancel_sR, loading, error } = useSelector(
    (state) => state.cancelShootReq
  );

  const { reshoot_cancel, loading: reject_loading } = useSelector(
    (state) => state.reshoot_req_cancel
  );

  const { PRD_Delete, loading: deletePRD_loading } = useSelector(
    (state) => state.photoshoot_delete_details
  );

  const { close, isReshoot, SId, id, PRD_Id } = props;

  const [cancel_reshoot_hit, setCancel_Reshoot_hit] = useState(false);
  const [cancel_shoot_hit, setCancel_shoot_hit] = useState(false);
  const [
    cancel_photoshoot_request_details_hit,
    setCancel_photoshoot_request_details_hit,
  ] = useState(false);

  const handleCancel = () => {
    if (id !== "" && id !== undefined && id !== null) {
      setCancel_Reshoot_hit(true);
      dispatch(reshoot_requirement_cancel_Actions(id, 2));
    } else if (SId !== "" && SId !== undefined && SId !== null) {
      setCancel_shoot_hit(true);
      dispatch(cancel_ShootRequirement_Actions(SId, 2));
    } else if (PRD_Id !== "" && PRD_Id !== undefined && PRD_Id !== null) {
      setCancel_photoshoot_request_details_hit(true);
      dispatch(PhotoshootRequest_detailsDelete_Actions(PRD_Id));
    }
  };

  useEffect(() => {
    if (cancel_sR && cancel_sR?.status === 1 && cancel_shoot_hit) {
      alert.success(cancel_sR && cancel_sR?.message);
      close();
      setCancel_shoot_hit(false);
    } else if (cancel_sR && cancel_sR?.status === 0 && cancel_shoot_hit) {
      alert.error(cancel_sR && cancel_sR?.message);
      setCancel_shoot_hit(false);
    }
  }, [cancel_sR, alert, close, cancel_shoot_hit]);

  useEffect(() => {
    if (reshoot_cancel && reshoot_cancel?.status === 1 && cancel_reshoot_hit) {
      alert.success(reshoot_cancel && reshoot_cancel?.message);
      setCancel_Reshoot_hit(false);
      close();
    } else if (
      reshoot_cancel &&
      reshoot_cancel?.status === 0 &&
      cancel_reshoot_hit
    ) {
      alert.error(reshoot_cancel && reshoot_cancel?.message);
      setCancel_Reshoot_hit(false);
    }
  }, [reshoot_cancel, alert, cancel_reshoot_hit, close]);

  useEffect(() => {
    if (
      PRD_Delete &&
      PRD_Delete?.status === 1 &&
      cancel_photoshoot_request_details_hit
    ) {
      alert.success(PRD_Delete && PRD_Delete?.message);
      setCancel_Reshoot_hit(false);
      close();
    } else if (
      PRD_Delete &&
      PRD_Delete?.status === 0 &&
      cancel_photoshoot_request_details_hit
    ) {
      alert.error(PRD_Delete && PRD_Delete?.message);
      setCancel_Reshoot_hit(false);
    }
  }, [PRD_Delete, alert, cancel_photoshoot_request_details_hit, close]);

  useEffect(() => {
    if (error) {
      alert.error(error);
    }
  }, [alert, error]);

  console.log("Cancelled Reducers: ", cancel_sR);

  return (
    <>
      <HeadingTitle
        title={
          isReshoot ? (
            <>
              Cancel <br /> Reshoot Requirement
            </>
          ) : (
            <>
              Cancel <br /> Photoshoot Requirement
            </>
          )
        }
        desc={`${
          isReshoot
            ? "Are you sure you want to cancel this Reshoot requirement?"
            : "Are you sure you want to cancel this photoshoot requirement?"
        }`}
        closeModal={close}
        descTop={true}
      />
      <div className="cancel-cta-container">
        <Button onClick={handleCancel}>
          {loading || reject_loading || deletePRD_loading ? (
            <CustomLoader />
          ) : (
            "Yes"
          )}
        </Button>
        <Button onClick={close}>No</Button>
      </div>
    </>
  );
};

export default CancelModal;
