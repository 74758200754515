import React, { useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getReshoot_details_Actions } from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import Vector from "../../Assets/StylingAssets/Frame.png";
import DisplayError from "../../Components/DisplayError/DisplayError";
import "./RejectedImageUploadNew.css";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import DefaultVideoIcon from "../../Assets/StylingAssets/clapperboard-high-efficiency-video-coding-video-player-video-icon-9c8cece0e3ba15290b12a2f15626acdd.png";

const RejectedImageUploadNew = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { reshoot_requirement_details, loading, error } = useSelector(
    (state) => state.reshoot_details
  );

  const [isIndex, setIsIndex] = useState(null);

  const navigate = useNavigate();

  const handleModal = (index) => {
    setIsIndex(index);
  };

  const [imageList, setImageList] = useState([]);

  const handleLoadImage = (i) => {
    setImageList(i);
    handleModal(2);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          photoshoot_request_detail_id={
            reshoot_requirement_details &&
            reshoot_requirement_details?.data?.photoshoot_request_detail_id
          }
          reshoot_request_id={id}
          reshoot_again={true}
          conten_format_type={
            reshoot_requirement_details &&
            reshoot_requirement_details?.data?.content_format_name
          }
          imageList={imageList}
        />
      );
    }
    return null;
  };

  const handleRedirect = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isIndex === null) {
      dispatch(getReshoot_details_Actions(id));
    }
  }, [dispatch, id, isIndex]);

  console.log("DATA: ", reshoot_requirement_details);

  let reshoot_requested_arr_list = [];

  reshoot_requirement_details &&
    Array.isArray(
      reshoot_requirement_details?.data?.reshoot_requested_images
    ) &&
    reshoot_requirement_details?.data?.reshoot_requested_images?.forEach(
      (i) => {
        if (!reshoot_requested_arr_list.includes(i.id)) {
          reshoot_requested_arr_list.push(i);
        }
      }
    );

  let studio_photoshoot_medias_arr_list = [];

  reshoot_requirement_details &&
    Array.isArray(
      reshoot_requirement_details?.data?.studio_photoshoot_medias
    ) &&
    reshoot_requirement_details?.data?.studio_photoshoot_medias?.forEach(
      (i) => {
        if (!studio_photoshoot_medias_arr_list.includes(i.id)) {
          studio_photoshoot_medias_arr_list.push(i);
        }
      }
    );

  console.log("DATASSS: ", reshoot_requirement_details);

  // let reshoot

  return (
    <>
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : error ? (
            <DisplayError error={error} />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <div>
                <HeaderProps
                  title="Reject Images/Videos"
                  hide={true}
                  redirect={true}
                  handleRedirect={handleRedirect}
                />
              </div>
              {/* header component ends here */}

              {/* division component starts here */}
              <div className="aside-col-rejected-images-details-row">
                <div>
                  <div className="top-rejected-images">
                    {reshoot_requested_arr_list &&
                    Array.isArray(reshoot_requested_arr_list) &&
                    reshoot_requested_arr_list?.length > 0
                      ? reshoot_requested_arr_list?.map((i) => {
                          const fileExtension = i.media_url
                            .split(".")
                            .pop()
                            .toLowerCase();
                          // if (
                          //   fileExtension === "mp4" ||
                          //   fileExtension === "webm" ||
                          //   fileExtension === "ogg"
                          // )
                          return (
                            <div
                              onClick={() =>
                                handleLoadImage(reshoot_requested_arr_list)
                              }
                              key={i.id}
                              className="reject-images-col img-fluid"
                            >
                              <img
                                style={{
                                  height:
                                    fileExtension === "mp4" ||
                                    fileExtension === "webm" ||
                                    fileExtension === "ogg"
                                      ? "auto"
                                      : "",
                                }}
                                // className="img-fluid-elements"
                                src={
                                  fileExtension === "mp4" ||
                                  fileExtension === "webm" ||
                                  fileExtension === "ogg"
                                    ? DefaultVideoIcon
                                    : i.media_url
                                }
                                alt=""
                              />
                              <div className="text-container-overlap options-overlap">
                                {/* <p>{i.name}</p> */}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <h4 className="new-image-tag-line">New Image</h4>
                  <div className="top-rejected-images">
                    <div
                      onClick={() => handleModal(5)}
                      className="add-pStyling styling-option-pstyling reshoot-add-container"
                    >
                      <img className="vector-add" src={Vector} alt="" />
                    </div>
                    {studio_photoshoot_medias_arr_list &&
                    studio_photoshoot_medias_arr_list?.length > 0
                      ? studio_photoshoot_medias_arr_list?.map((i) => (
                          <div
                            key={i.id}
                            className="reject-images-col img-fluid option-img-fluid"
                          >
                            <img
                              // className="img-fluid-elements"
                              src={i.media_url}
                              alt=""
                            />
                            <div className="text-container-overlap options-overlap">
                              {/* <p>{i.name}</p> */}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div>
                  <label className="label" htmlFor="">
                    Style Name and Color
                  </label>{" "}
                  <br />
                  <input
                    className="input-readonly"
                    type="text"
                    defaultValue={
                      reshoot_requirement_details
                        ? reshoot_requirement_details?.data?.style_color
                        : ""
                    }
                  />{" "}
                  <br />
                  <div>
                    <label className="label" htmlFor="">
                      Content Format Type
                    </label>{" "}
                    <br />
                    <input
                      className="input-readonly"
                      type="text"
                      defaultValue={
                        reshoot_requirement_details
                          ? reshoot_requirement_details?.data
                              ?.content_format_name
                          : ""
                      }
                    />{" "}
                    <br />
                    <label className="label" htmlFor="">
                      Remarks
                    </label>{" "}
                    <br />
                    <textarea
                      className="input-readonly-textarea"
                      cols="30"
                      rows="5"
                      defaultValue={
                        reshoot_requirement_details
                          ? reshoot_requirement_details?.data?.remarks
                          : ""
                      }
                    ></textarea>{" "}
                  </div>
                </div>
              </div>
              {/* division component ends here */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default RejectedImageUploadNew;
