import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import "./AddPhotoShootRequestDetails.css";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "../../Components/Dropdown/Dropdown";
import vectorUploadIcon from "../../Assets/StylingAssets/Vector.png";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import { photoshoot_request_details_data_arr } from "../../Components/DataTable/DummyHeaderArray";
import DataTable from "../../Components/DataTable/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  PhotoshootRequest_detailsEdit_Actions,
  getContentFormatDataActions,
  getListofStylesActions,
  getPhotoShootRequestDataByIdActions,
  getPhotoShootRequestDetailsDataActions,
} from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import api from "../../Middleware/AxiosConfig";
import { BASE_URL } from "../../Middleware/BaseURL";
import CustomLoader from "../../Components/Loading/CustomLoader";
import { useAlert } from "react-alert";
import LoadingContent from "../../Components/Loading/Loading";
import CancelIcon from "@mui/icons-material/Cancel";
import { Pagination } from "../../Components/Pagination/Pagination";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";

const AddPhotoShootRequestDetails = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  //   photoshoot request by id state
  const { Photoshoot_request_data_by_id, loading } = useSelector(
    (state) => state.getPhotoshootReqDataById
  );

  //   list of styles state
  const { list_of_styles, loading: style_list_loading } = useSelector(
    (state) => state.listofstyles
  );

  //   content format type read state
  const { content_format_data, loading: content_format_loading } = useSelector(
    (state) => state.showContentFormat
  );

  //   photoshoot request details state
  const {
    Photoshoot_request_details_data,
    loading: photoshoot_details_loading,
    error,
  } = useSelector((state) => state.photoshoot_details);

  const { id } = useParams();

  const [photoshoot_details_arr, setPhotoshoot_details_arr] = useState([]);

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(-1);
  };

  console.log("Id: ", id);

  useEffect(() => {
    if (id && id !== null) {
      dispatch(getPhotoShootRequestDataByIdActions(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getListofStylesActions());
    dispatch(getContentFormatDataActions());
  }, [dispatch]);

  console.log("Photoshoot By Id: ", Photoshoot_request_data_by_id);
  console.log("List Style: ", list_of_styles);

  let content_format_dropdown =
    content_format_data && content_format_data?.data?.length > 0
      ? content_format_data?.data?.map(
          ({
            content_format_images,
            created,
            created_by,
            description,
            modified,
            modified_by,
            ...rest
          }) => rest
        )
      : [];

  console.log("Content Format Read Data: ", content_format_dropdown);

  const [isIndex, setIsIndex] = useState(null);
  const [imageList, setImageList] = useState([]);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const handleImgSlider = (imageList) => {
    console.log("Image List: ", imageList);
    setImageList(imageList);
    handleModal(2);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const [uri, setUri] = useState("");

  const handleVideoUri = (video) => {
    setUri(video);
  };

  const [PRD_Id, setPRD_id] = useState("");

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          imageList={imageList}
          video_url={uri}
          PRD_Id={PRD_Id}
        />
      );
    }
    return null;
  };

  const [style_color, setstyle_color] = useState("");
  const [content_format_id, setcontent_format_id] = useState("");
  const [content_format_name, setcontent_format_name] = useState("");
  const [files, setFiles] = useState([]);
  const [loading_icon, setLoading] = useState(false);

  const [page, setPage] = useState(1);

  const [filePreviews, setFilePreviews] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter(
        (file) =>
          file.type.startsWith("image/") || file.type.startsWith("video/")
      );

      if (filteredFiles.length !== acceptedFiles.length) {
        alert.error(
          "Invalid file format. Only image and video files are allowed."
        );
        return;
      }

      setFiles([...files, ...filteredFiles]);
      setFilePreviews([
        ...filePreviews,
        ...filteredFiles.map((file) => URL.createObjectURL(file)),
      ]);
    },
    [files, filePreviews, alert]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*, video/*",
  });

  const handleStyle = (val) => {
    setstyle_color(val?.value);
  };

  const handleFormatName = (val) => {
    setcontent_format_id(val?.id);
    setcontent_format_name(val?.value);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("content_format_id", content_format_id);
    formData.set("style_color", style_color);
    formData.set("photoshoot_request_id", id);

    // Append each image to the formData
    files.forEach((image, index) => {
      formData.append(`files[${index}]`, image);
    });

    setLoading(true);
    api
      .post(`${BASE_URL}/photoshoot_request_detail_create`, formData)
      .then((response) => {
        if (response && response?.data?.status === 1) {
          alert.success(response && response?.data?.message);
          setLoading(false);
          dispatch(getPhotoShootRequestDetailsDataActions(id));
          setFiles([]);
          setFilePreviews([]);
          setcontent_format_id("");
          setcontent_format_name("");
          setstyle_color("");
        } else if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        alert.error(error.message);
        setLoading(false);
      });
  };

  console.log("Content Format Id: ", content_format_id);

  console.log(
    "Photoshoot Details: ",
    Photoshoot_request_details_data?.pagination
  );

  const handleDelete = (index) => {
    let filter_preview =
      filePreviews && filePreviews.filter((i, ix) => ix !== index);
    let filter_files = files && files.filter((i, iy) => iy !== index);
    setFiles(filter_files);
    setFilePreviews(filter_preview);
  };

  useEffect(() => {
    if (id !== null && id !== undefined && id !== "") {
      dispatch(getPhotoShootRequestDetailsDataActions(id));
    }
  }, [dispatch, id]);

  const [pagination_loading, setPagination_loading] = useState(false);

  const fetchNextDetails = async (page) => {
    setPagination_loading(true);
    try {
      let response = await api.post(
        `${BASE_URL}/photoshoot_request_detail_read`,
        { photoshoot_request_id: id, page }
      );

      if (response && response?.data?.status === 1) {
        setPhotoshoot_details_arr(response && response?.data?.data);
        setPagination_loading(false);
      }
    } catch (error) {
      setPagination_loading(false);
      alert.error(error.message);
    }
  };

  const handlePage = (i) => {
    setPage(i);
    fetchNextDetails(i);
  };

  useEffect(() => {
    Photoshoot_request_details_data &&
      setPhotoshoot_details_arr(
        Photoshoot_request_details_data && Photoshoot_request_details_data?.data
      );
  }, [Photoshoot_request_details_data]);

  console.log(filePreviews);

  // edit photoshoot_request_data
  const { PRD_Edit, loading: edit_request_loading } = useSelector(
    (state) => state.photoshoot_edit_details
  );
  const [edit_hit, setEdit_hit] = useState(false);
  const [update_action, setUpdate_action] = useState(false);

  // update_id_state
  const [Update_id, setUpdate_Id] = useState("");

  const handleEdit = async (id) => {
    setUpdate_Id(id);
    setUpdate_action(true);
    setEdit_hit(true);
    await dispatch(PhotoshootRequest_detailsEdit_Actions(id));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  console.log("PHOTOSHOOT_EDIT_DATA: ", PRD_Edit);

  const fetchBinaryData = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new File([blob], "filename");
    } catch (error) {
      console.error("Error fetching binary data:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (PRD_Edit && PRD_Edit?.status === 1 && edit_hit) {
        alert.success(PRD_Edit && PRD_Edit?.message);
        setEdit_hit(false);
        setstyle_color(PRD_Edit && PRD_Edit?.data?.style_color);
        setcontent_format_id(PRD_Edit && PRD_Edit?.data?.content_format_id);
        setcontent_format_name(PRD_Edit && PRD_Edit?.data?.content_format_name);

        const promises =
          PRD_Edit?.data?.photoshoot_request_details_reference_medias.map(
            async (item) => {
              const binaryData = await fetchBinaryData(item.media_url);
              return { binaryData, preview: item.media_url };
            }
          );

        const results = await Promise.all(promises);

        const newFiles = results.map((result) => result.binaryData);
        const newFilePreviews = results.map((result) => result.preview);

        setFiles(newFiles);
        setFilePreviews(newFilePreviews);
      } else if (PRD_Edit && PRD_Edit?.status === 0 && edit_hit) {
        alert.error(PRD_Edit && PRD_Edit?.message);
        setEdit_hit(false);
      }
    };

    fetchData();
  }, [PRD_Edit, edit_hit, alert]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("content_format_id", content_format_id);
    formData.set("style_color", style_color);
    formData.set("id", Update_id);

    // Append each image to the formData
    files.forEach((image, index) => {
      formData.append(`files[${index}]`, image);
    });

    setLoading(true);
    api
      .post(`${BASE_URL}/photoshoot_request_detail_update`, formData)
      .then((response) => {
        if (response && response?.data?.status === 1) {
          alert.success(response && response?.data?.message);
          setLoading(false);
          dispatch(getPhotoShootRequestDetailsDataActions(id));
          setFiles([]);
          setFilePreviews([]);
          setcontent_format_id("");
          setcontent_format_name("");
          setstyle_color("");
          setUpdate_action(false);
        } else if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        alert.error(error.message);
        setLoading(false);
      });
  };

  console.log("FILES:", filePreviews, files);

  const handleDeleteIndex = (id) => {
    setPRD_id(id);
    handleModal(4);
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
    }
  }, [error, alert]);

  return (
    <>
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <div>
                <HeaderProps
                  title="Add Photoshoot Request Details"
                  excel={true}
                  // handleAdd={handleImportExcelAdd}
                  redirect={true}
                  handleRedirect={handleRedirect}
                  middleLevel={true}
                  id={id}
                  downloadExcelFile={true}
                  // handleChange={handleOnChange}
                />
              </div>

              {/* form to create photoshoot request details */}
              <div className="photoshoot-request-details-create">
                <div>
                  <form>
                    <div>
                      <label className="label" htmlFor="PhotoshootName">
                        Photoshoot Name
                      </label>{" "}
                      <br />
                      <input
                        className="input-readonly"
                        type="text"
                        name="PhotoshootName"
                        id="PhotoshootName"
                        defaultValue={
                          Photoshoot_request_data_by_id &&
                          Photoshoot_request_data_by_id?.data
                            ?.photoshoot_request_name
                        }
                        readOnly
                      />
                    </div>
                    <div>
                      <label htmlFor="" className="label">
                        Select Style
                      </label>
                      <Dropdown
                        takeonTop={true}
                        placeholder="Select Style"
                        dropdown={
                          list_of_styles && list_of_styles?.data?.length > 0
                            ? list_of_styles?.data
                            : []
                        }
                        loading={style_list_loading}
                        value={style_color}
                        onData={handleStyle}
                      />
                    </div>
                    <div className="content-format-input-label">
                      <label htmlFor="" className="label">
                        Content Format Type
                      </label>
                      <Dropdown
                        takeonTop={true}
                        placeholder="Select Content Format Type"
                        dropdown={
                          content_format_dropdown &&
                          content_format_dropdown?.length > 0
                            ? content_format_dropdown
                            : []
                        }
                        loading={content_format_loading}
                        value={content_format_name}
                        onData={handleFormatName}
                      />
                    </div>
                  </form>
                  <div
                    className="browse-files add-request-details"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <>
                        <img src={vectorUploadIcon} alt="" />
                        <h4>Upload Image for content format</h4>
                        <p className="drag-tag">
                          Drag and Drop file here Or <span>Choose file</span>
                        </p>
                        <p className="drag-small-tag">
                          Support formats: JPG, PNG, Video | Minimum size: 20 MB
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={vectorUploadIcon} alt="" />
                        <h4>Upload Image for content format</h4>
                        <p className="drag-tag">
                          Drag and Drop file here Or <span>Choose file</span>
                        </p>
                        <p className="drag-small-tag">
                          Support formats: Image/Video
                        </p>
                      </>
                    )}
                  </div>
                  <div className="submit-cta-request-details">
                    {update_action ? (
                      <Button type="submit" onClick={handleUpdate}>
                        {loading_icon ? <CustomLoader /> : "Update"}
                      </Button>
                    ) : (
                      <Button type="submit" onClick={handleAdd}>
                        {loading_icon ? <CustomLoader /> : "Submit"}
                      </Button>
                    )}
                  </div>
                  <div className="image-previews shoot-grid-previews">
                    {filePreviews.map((file, index) => (
                      <div className="cross-icon-image-preview" key={index}>
                        <Button onClick={() => handleDelete(index)}>
                          <CancelIcon />
                        </Button>
                        <img
                          key={index}
                          src={file}
                          alt={`Preview ${index}`}
                          className="file-preview file-shhot"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {photoshoot_details_loading ? (
                    <LoadingContent />
                  ) : (
                    <DataTable
                      loadImage={handleImgSlider}
                      header={photoshoot_request_details_data_arr}
                      Photoshoot_request_details_data={
                        photoshoot_details_arr &&
                        photoshoot_details_arr?.length > 0
                          ? photoshoot_details_arr
                          : []
                      }
                      editIndex={handleEdit}
                      loadVideo={handleVideoUri}
                      handleindex={handleModal}
                      editLoading={edit_request_loading}
                      handleDeleteIndex={handleDeleteIndex}
                    />
                  )}
                </div>
              </div>
              {/* header component ends here */}

              {/* Pagination component starts here */}
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenA-${page}`}
                  page={page}
                  isLoading={pagination_loading && pagination_loading}
                  totalPage={
                    Photoshoot_request_details_data &&
                    Photoshoot_request_details_data?.pagination?.last_page
                  }
                  onPageChange={handlePage}
                />
              </div>
              {/* Pagination component ends here */}
            </div>
          )}
          {/* content format data */}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default AddPhotoShootRequestDetails;
