import React, { useEffect, useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import Header from "../../Components/Navbar/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import Vector from "../../Assets/StylingAssets/Frame.png";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import { DEFAULT_IMAGE } from "../../Middleware/DefaultImage";
import "./StylingOption.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loading/Loader";
import { getStylingElementsOptionDataActions } from "../../Actions/StyleActions";

const StylingElementOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, name } = useParams();

  //   styling_element read option
  const { styling_elements_option_data, loading } = useSelector(
    (state) => state.styling_option
  );

  const decodeName = decodeURIComponent(name);

  console.log("Name: ", decodeName);
  console.log("Id: ", id);

  const handleRedirect = () => {
    navigate(-1);
  };

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          isFill={true}
          decodeName={decodeName}
          styling_item_type_id={id}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (id && id !== null && isIndex === null) {
      dispatch(getStylingElementsOptionDataActions(id));
    }
  }, [id, dispatch, isIndex]);

  console.log("Styling Element Option: ", styling_elements_option_data);

  return (
    <>
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <div>
                <HeaderProps
                  title="Styling Elements Options"
                  redirect={true}
                  hide={true}
                  handleRedirect={handleRedirect}
                  middleLevel={true}
                />
              </div>
              {/* header component ends here */}

              {/* images map code starts here */}
              <div className="image-code-container-grid-row options-grid">
                <div onClick={() => handleModal(3)} className="add-pStyling styling-option-pstyling">
                  <img src={Vector} alt="" />
                </div>
                {styling_elements_option_data &&
                styling_elements_option_data?.data?.styling_items?.length > 0
                  ? styling_elements_option_data?.data?.styling_items?.map(
                      (i) => (
                        <div
                          className="image-code-container-grid-col"
                          key={i.id}
                        >
                          <div>
                            <div className="img-fluid option-img-fluid">
                              <img
                                className="img-fluid-elements"
                                src={i.media_url}
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                                alt=""
                                loading="lazy"
                              />
                              <div className="text-container-overlap options-overlap">
                                <p>{i.name}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  : null}
              </div>
              {/* images map code ends here */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default StylingElementOptions;
