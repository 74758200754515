import React, { useCallback, useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import vectorUploadIcon from "../../../Assets/StylingAssets/Vector.png";
import "./UploadPhotosVideos.css";
import { useAlert } from "react-alert";
import api from "../../../Middleware/AxiosConfig";
import { BASE_URL } from "../../../Middleware/BaseURL";
import CustomLoader from "../../Loading/CustomLoader";
import VideoDefaultIcon from "../../../Assets/StylingAssets/clapperboard-high-efficiency-video-coding-video-player-video-icon-9c8cece0e3ba15290b12a2f15626acdd.png";

const UploadPhotosVideos = (props) => {
  const alert = useAlert();
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [description, setdescription] = useState("");

  const [remarks, setRemarks] = useState("");

  const {
    close,
    photoshoot_request_detail_id,
    conten_format_type,
    requested_media_ids,
    id,
    reshoot_again,
    reshoot_request_id,
    new_reshoot,
  } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter(
        (file) =>
          file.type.startsWith("image/") || file.type.startsWith("video/")
      );

      if (filteredFiles.length !== acceptedFiles.length) {
        alert.error(
          "Invalid file format. Only image and video files are allowed."
        );
        return;
      }
      setFiles([...files, ...filteredFiles]);

      // const updatedFiles = [...files, ...acceptedFiles];
      // setFiles(updatedFiles);

      // const previews = acceptedFiles.map((file) => {
      //   return {
      //     file,
      //     preview: URL.createObjectURL(file),
      //   };
      // });

      // const updatedPreviews = [...filePreviews, ...previews];
      const previews = filteredFiles.map((file) => {
        if (file.type.startsWith("image/")) {
          return URL.createObjectURL(file);
        } else if (file.type.startsWith("video/")) {
          // For video files, you might want to use a default thumbnail or show the video icon
          return VideoDefaultIcon;
        }
        return null; // Handle other file types if needed
      });
      setFilePreviews([...filePreviews, ...previews]);
    },
    [filePreviews, alert, files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDelete = (index) => {
    const updatedPreviews = filePreviews.filter((_, idx) => idx !== index);
    const updatedFiles = files.filter((_, idx) => idx !== index);

    setFiles(updatedFiles);
    setFilePreviews(updatedPreviews);
  };

  const [loading, setLoading] = useState(false);

  const [reshoot_loading, setReshoot_loading] = useState(false);

  const handleShoot = (e) => {
    e.preventDefault();
    if (requested_media_ids && requested_media_ids?.length > 0) {
      // alert.show("YES");
      const formData = new FormData();

      formData.set("photoshoot_request_detail_id", id);
      formData.set("remarks", remarks);
      requested_media_ids.forEach((i, index) => {
        formData.append(`requested_media_ids[${index}]`, i);
      });
      // Append each image to the formData
      files.forEach((image, index) => {
        formData.append(`files[${index}]`, image);
      });
      setReshoot_loading(true);
      api
        .post(`${BASE_URL}/reshoot_request_create`, formData)
        .then((response) => {
          if (response && response?.data?.status === 1) {
            alert.success(response && response?.data?.message);
            close();
            setReshoot_loading(false);
          } else if (response && response?.data?.status === 0) {
            alert.error(response && response?.data?.message);
            setReshoot_loading(false);
          }
        })
        .catch((error) => {
          // Handle network errors and errors thrown in the 'then' block
          // console.error("Error:", error);
          alert.error(error.message);
          setReshoot_loading(false);
        });
    } else {
      const formData = new FormData();

      if (reshoot_again) {
        formData.set("reshoot_request_id", reshoot_request_id);
      }
      formData.set(
        "photoshoot_request_detail_id",
        photoshoot_request_detail_id
      );
      formData.set("description", description);
      // Append each image to the formData
      files.forEach((image, index) => {
        formData.append(`files[${index}]`, image);
      });
      setLoading(true);
      api
        .post(`${BASE_URL}/studio_photoshoot_create`, formData)
        .then((response) => {
          if (response && response?.data?.status === 1) {
            alert.success(response && response?.data?.message);
            close();
            setLoading(false);
          } else if (response && response?.data?.status === 0) {
            alert.error(response && response?.data?.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          // Handle network errors and errors thrown in the 'then' block
          // console.error("Error:", error);
          alert.error(error.message);
          setLoading(false);
        });
    }
  };

  console.log("DATA: ", photoshoot_request_detail_id, conten_format_type);

  console.log("IDS: ", requested_media_ids, id);

  return (
    <>
      <HeadingTitle
        title={
          props.reshoot ? (
            <>
              Upload <br /> Reshoot Photos/Videos
            </>
          ) : (
            <>
              Upload <br /> Photos/Videos
            </>
          )
        }
        closeModal={close}
      />

      <div className="upload-videos-photos-container">
        <form onSubmit={(e) => handleShoot(e)}>
          {!props.reshoot && (
            <div>
              <label className="label" htmlFor="name">
                Content Format Type
              </label>{" "}
              <br />
              <input
                className="input"
                type="text"
                name="Content_Format_Type"
                id="Content_Format_Type"
                value={conten_format_type}
                readOnly
              />
            </div>
          )}
          {new_reshoot === false &&
          new_reshoot === undefined &&
          new_reshoot === null &&
          new_reshoot === "" ? (
            <div>
              <label className="label description-label" htmlFor="description">
                Remarks <div className="red-mark"></div>
              </label>{" "}
              <br />
              <textarea
                className="input-textarea"
                name="Remarks"
                id="Remarks"
                cols="30"
                rows="3"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              ></textarea>
            </div>
          ) : requested_media_ids?.length > 0 ? (
            <div>
              <label className="label description-label" htmlFor="description">
                Remarks <div className="red-mark"></div>
              </label>{" "}
              <br />
              <textarea
                className="input-textarea"
                name="Remarks"
                id="Remarks"
                cols="30"
                rows="3"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              ></textarea>
            </div>
          ) : (
            <div>
              <label className="label description-label" htmlFor="description">
                Description <div className="red-mark"></div>
              </label>{" "}
              <br />
              <textarea
                className="input-textarea"
                name="description"
                id="description"
                cols="30"
                rows="3"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></textarea>
            </div>
          )}
          <div className="browse-files" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                {" "}
                <img src={vectorUploadIcon} alt="" />
                <h4 className="headline-upload">Upload Image / Videos</h4>
                <p className="drag-tag" style={{ marginTop: "-2px" }}>
                  Drag and Drop file here Or <span>Choose file</span>
                </p>
                <p className="drag-small-tag">
                  Support formats: JPG, PNG, Video
                </p>
              </>
            ) : (
              <>
                {" "}
                <img src={vectorUploadIcon} alt="" />
                <h4 className="headline-upload">Upload Image / Videos</h4>
                <p className="drag-tag" style={{ marginTop: "-2px" }}>
                  Drag and Drop file here Or <span>Choose file</span>
                </p>
                <p className="drag-small-tag">
                  Support formats: JPG, PNG, Video
                </p>
              </>
            )}
          </div>
          <div className="submit-cta">
            <Button type="submit">
              {loading || reshoot_loading ? <CustomLoader /> : "Submit"}
            </Button>
          </div>
        </form>

        <div className="image-previews">
          {filePreviews.map((file, index) => (
            <div className="cross-icon-image-preview" key={index}>
              <Button
                className="content-format-cta"
                style={{
                  right: "-5px",
                  width: "20px",
                  height: "20px",
                  minWidth: "20px",
                }}
                onClick={() => handleDelete(index)}
              >
                <CancelIcon />
              </Button>
              <img
                key={index}
                src={file}
                alt={`Preview ${index}`}
                className="file-preview"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UploadPhotosVideos;
