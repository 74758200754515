import React, { useEffect, useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getStylingElementsDataActions,
  getStylingElementsOptionDataActions,
} from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import "./StylingOption.css";
import CanvasRow from "../Canvas/CanvasRow";
import NoDataFound from "../../Components/Loading/NoDataFound";
import LoadingContent from "../../Components/Loading/Loading";
import Images from "../../Components/Images/Images";

const CreateStylingOption = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  //   styling item read state
  const { styling_elements_data, loading } = useSelector(
    (state) => state.styling_elements
  );

  //   styling_element read option
  const { styling_elements_option_data, loading: styling_loading } =
    useSelector((state) => state.styling_option);

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getStylingElementsDataActions());
  }, [dispatch]);

  const handleEdit = (id) => {
    dispatch(getStylingElementsOptionDataActions(id));
  };

  console.log("Styling Option Element Data: ", styling_elements_option_data);

  const [imageUrl, setImageUrl] = useState("");

  const handleImage = (url) => {
    console.log("NEWS_URL: ", url);
    setImageUrl(url);
  };

  console.log("Photoshoot Request Details Id: ", imageUrl);

  return (
    <>
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <div>
                <HeaderProps
                  title="Create Styling Options"
                  hide={true}
                  redirect={true}
                  handleRedirect={handleRedirect}
                />
              </div>
              {/* header component ends here */}
              {/* aside col starts here */}
              <div className="aside-col-json-grid-row">
                <div>
                  <div className="styling-options-row option-hght">
                    {styling_elements_data &&
                    styling_elements_data?.data?.length > 0
                      ? styling_elements_data?.data?.map((i, index) => {
                          return (
                            <Images
                              i={i}
                              index={index}
                              handleData={handleEdit}
                            />
                          );
                        })
                      : null}
                  </div>

                  {/* products listing */}
                  <h3 className="product-headline">Products</h3>
                  {/* products listing */}
                  {styling_loading ? (
                    <LoadingContent />
                  ) : (
                    <div
                      className={
                        styling_elements_option_data &&
                        styling_elements_option_data?.data?.styling_items
                          ?.length > 0
                          ? "styling-options-row"
                          : ""
                      }
                    >
                      {styling_elements_option_data &&
                      styling_elements_option_data?.data?.styling_items
                        ?.length > 0
                        ? styling_elements_option_data?.data?.styling_items?.map(
                            (i, index) => {
                              return <Images handleImage={handleImage} i={i} index={index} data={true} />;
                            }
                          )
                        : !styling_loading && (
                            <>
                              <NoDataFound title={"No Products Found..."} />
                            </>
                          )}
                    </div>
                  )}
                </div>
                <div>
                  <CanvasRow
                    photoshoot_request_detail_id={id}
                    imageUrl={imageUrl}
                  />
                </div>
              </div>
              {/* aside col ends here */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateStylingOption;
