import React, { useCallback, useEffect, useState } from "react";
import { HeadingTitle } from "../../ModalContainer/ModalContainer";
import { Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import "./AddContentFormat.css";
import vectorUploadIcon from "../../../Assets/StylingAssets/Vector.png";
import { BASE_URL } from "../../../Middleware/BaseURL";
import api from "../../../Middleware/AxiosConfig";
import { useAlert } from "react-alert";
import CustomLoader from "../../../Components/Loading/CustomLoader";
import CancelIcon from "@mui/icons-material/Cancel";
import VideoDefaultIcon from "../../../Assets/StylingAssets/clapperboard-high-efficiency-video-coding-video-player-video-icon-9c8cece0e3ba15290b12a2f15626acdd.png";
import { useDispatch, useSelector } from "react-redux";
import { content_format_details_update_Actions } from "../../../Actions/StyleActions";

const AddContentFormat = (props) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { close, id, name: new_Name, description: new_description } = props;

  const { content_format_update_details, loading: editLoading } = useSelector(
    (state) => state.contentformat_updateDetails
  );

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filePreviews, setFilePreviews] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter(
        (file) =>
          file.type.startsWith("image/") || file.type.startsWith("video/")
      );

      if (filteredFiles.length !== acceptedFiles.length) {
        alert.error(
          "Invalid file format. Only image and video files are allowed."
        );
        return;
      }
      setFiles([...files, ...filteredFiles]);

      const previews = filteredFiles.map((file) => {
        if (file.type.startsWith("image/")) {
          return URL.createObjectURL(file);
        } else if (file.type.startsWith("video/")) {
          // For video files, you might want to use a default thumbnail or show the video icon
          return VideoDefaultIcon;
        }
        return null; // Handle other file types if needed
      });

      setFilePreviews([...filePreviews, ...previews]);
    },
    [files, filePreviews, alert]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [addActive, setAddActive] = useState(false);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (id !== null && id !== undefined && id !== "") {
      setAddActive(true);
      const formData = new FormData();

      formData.set("content_format_id", id);
      // Append each image to the formData
      files.forEach((image, index) => {
        formData.append(`files[${index}]`, image);
      });

      setLoading(true);
      api
        .post(`${BASE_URL}/content_format_image_create`, formData)
        .then((response) => {
          if (response && response?.data?.status === 1) {
            dispatch(
              content_format_details_update_Actions(
                id,
                new_Name,
                new_description
              )
            );
            alert.success(response && response?.data?.message);
            closeModal();
            setLoading(false);
          } else if (response && response?.data?.status === 0) {
            alert.error(response && response?.data?.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          // Handle network errors and errors thrown in the 'then' block
          // console.error("Error:", error);
          alert.error(error.message);
          setLoading(false);
        });
    } else {
      const formData = new FormData();
      formData.set("name", name);
      formData.set("description", description);

      // Append each image to the formData
      files.forEach((image, index) => {
        formData.append(`files[${index}]`, image);
      });

      setLoading(true);
      api
        .post(`${BASE_URL}/content_format_create`, formData)
        .then((response) => {
          if (response && response?.data?.status === 1) {
            alert.success(response && response?.data?.message);
            closeModal();
            setLoading(false);
          } else if (response && response?.data?.status === 0) {
            alert.error(response && response?.data?.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          // Handle network errors and errors thrown in the 'then' block
          alert.error(error.message);
          setLoading(false);
        });
    }
  };

  console.log("File Previews: ", id);

  const handleDelete = (index) => {
    let filter_preview =
      filePreviews && filePreviews.filter((i, ix) => ix !== index);
    let filter_files = files && files.filter((i, iy) => iy !== index);
    setFiles(filter_files);
    setFilePreviews(filter_preview);
  };

  console.log("Name: ", new_Name);
  console.log("Description: ", new_description);

  useEffect(() => {
    if (
      content_format_update_details &&
      content_format_update_details?.status === 1 &&
      addActive
    ) {
      alert.success(
        content_format_update_details && content_format_update_details?.message
      );
      setAddActive(false);
    } else if (
      content_format_update_details &&
      content_format_update_details?.status === 0 &&
      addActive
    ) {
      alert.error(
        content_format_update_details && content_format_update_details?.message
      );
      setAddActive(false);
    }
  }, [content_format_update_details, alert, addActive]);

  return (
    <>
      <HeadingTitle
        title={`${
          id !== null && id !== "" && id !== undefined
            ? "Edit Content Format"
            : "Add Content Format"
        }`}
        // desc={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.....`}
        closeModal={close}
      />
      <form onSubmit={(e) => handleAdd(e)}>
        <div className="add-photoshoot-request">
          <div
            style={{
              display:
                id !== null && id !== "" && id !== undefined ? "none" : "block",
            }}
          >
            <label className="label" htmlFor="name">
              Name
            </label>{" "}
            <br />
            <input
              className="input"
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div
            style={{
              display:
                id !== null && id !== "" && id !== undefined ? "none" : "block",
            }}
          >
            <label className="label" htmlFor="description">
              Description
            </label>{" "}
            <br />
            <textarea
              className="input-textarea"
              name="description"
              id="description"
              cols="30"
              rows="1"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div
            style={{
              marginTop:
                id !== null && id !== "" && id !== undefined ? "20px" : "",
            }}
            className="browse-files"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                <img src={vectorUploadIcon} alt="" />
                <p className="drag-tag">
                  Drag and Drop file here Or <span>Choose file</span>
                </p>
                <p className="drag-small-tag">
                  Support formats: JPG, PNG, Video
                </p>
              </>
            ) : (
              <>
                <img src={vectorUploadIcon} alt="" />
                <p className="drag-tag">
                  Drag and Drop file here Or <span>Choose file</span>
                </p>
                <p className="drag-small-tag">
                  Support formats: JPG, PNG, Video
                </p>
              </>
            )}
          </div>
          <div className="submit-cta">
            <Button type="submit">
              {loading || editLoading ? <CustomLoader /> : "Submit"}
            </Button>
          </div>
          <div className="image-previews">
            {filePreviews.map((file, index) => (
              <div className="cross-icon-image-preview" key={index}>
                <Button
                  className="content-format-cta"
                  style={{
                    right: "-5px",
                    width: "20px",
                    height: "20px",
                    minWidth: "20px",
                  }}
                  onClick={() => handleDelete(index)}
                >
                  <CancelIcon />
                </Button>
                <img
                  key={index}
                  src={file}
                  alt={`Preview ${index}`}
                  className="file-preview"
                />
              </div>
            ))}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddContentFormat;
