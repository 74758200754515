import React, { useEffect, useState } from "react";
import "./SytlingSetView.css";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../Loading/CustomLoader";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  approve_styleSet_Actions,
  reject_styleSet_Actions,
} from "../../../Actions/StyleActions";
import { useAlert } from "react-alert";

const SytlingSetView = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { approve_set, loading: approve_loading } = useSelector(
    (state) => state.approveStyleSet
  );
  const { reject_set, loading: reject_loading } = useSelector(
    (state) => state.rejectStyleSet
  );
  const { close, img_uri } = props;

  const [approve_hit, setApprove_hit] = useState(false);
  const [reject_hit, setReject_hit] = useState(false);

  const handleReject = () => {
    setReject_hit(true);
    dispatch(reject_styleSet_Actions(img_uri[currentIndex].styling_set_id, 2));
  };

  const handleApprove = () => {
    setApprove_hit(true);
    dispatch(approve_styleSet_Actions(img_uri[currentIndex].styling_set_id, 1));
  };

  console.log("APPROVE DATA: ", approve_set);
  console.log("REJECT DATA: ", reject_set);

  useEffect(() => {
    if (reject_set && reject_set?.status === 1 && reject_hit) {
      alert.success(reject_set && reject_set?.message);
      setReject_hit(false);
    } else if (reject_set && reject_set?.status === 0 && reject_hit) {
      alert.error(reject_set && reject_set?.message);
      setReject_hit(false);
    }
  }, [reject_set, alert, reject_hit]);

  useEffect(() => {
    if (approve_set && approve_set?.status === 1 && approve_hit) {
      alert.success(approve_set && approve_set?.message);
      setApprove_hit(false);
    } else if (approve_set && approve_set?.status === 0 && approve_hit) {
      alert.error(approve_set && approve_set?.message);
      setApprove_hit(false);
    }
  }, [approve_set, alert, approve_hit]);

  console.log("IMAGES URI: ", img_uri);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleIncrement = () => {
    // Increment the index and handle the case where it exceeds the array length
    setCurrentIndex((prevIndex) => (prevIndex + 1) % img_uri?.length);
  };

  const handleDecrement = () => {
    // Increment the index and handle the case where it exceeds the array length
    setCurrentIndex((prevIndex) => (prevIndex - 1 + img_uri.length) % img_uri.length);
  };

  console.log(img_uri[currentIndex].styling_set_id);

  return (
    <>
      <div
        onClick={close}
        className="cross-icon-modal"
        style={{ backgroundColor: "#FF0000" }}
      >
        <CloseIcon className="cross-icon-col" />
        {/* <img src={CrossIcon} alt="" /> */}
      </div>
      <div className="img-container-view">
        <Button onClick={handleDecrement}>
          <ChevronLeftIcon />
        </Button>
        <img src={img_uri[currentIndex].media_url} alt="" />
        <Button onClick={handleIncrement}>
          <ChevronRightIcon />
        </Button>
        <div style={{ marginTop: "20px" }}>
        {/* Dots representing each image */}
        {img_uri.map((item, index) => (
          <span
            key={item.id}
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              margin: "0 5px",
              borderRadius: "50%",
              background: index === currentIndex ? "#f76631" : "white",
            }}
          />
        ))}
      </div>
      </div>

      <div className="approve-reject-cta">
        <Button onClick={() => handleReject()}>
          {reject_loading ? <CustomLoader /> : "reject"}
        </Button>
        <Button onClick={() => handleApprove()}>
          {approve_loading ? <CustomLoader /> : "approve"}
        </Button>
      </div>
    </>
  );
};

export default SytlingSetView;
