import React from "react";
import DefaultVideoIcon from "../../Assets/StylingAssets/clapperboard-high-efficiency-video-coding-video-player-video-icon-9c8cece0e3ba15290b12a2f15626acdd.png";
import NoDataFound from "../../Components/Loading/NoDataFound";
import { Button } from "@mui/material";

const VideosPhotos = ({
  arr_list_images_videos,
  handleCheckBox,
  handleIndex,
  index,
  handleImgUri_new,
  handleVideoUri,
}) => {
  const handleVideo = (video) => {
    handleVideoUri(video);
  };

  const handleImgUri = (img) => {
    handleImgUri_new(img);
  };
  return (
    <div
      className={`${
        arr_list_images_videos?.length > 0 ? "shoot-images-grid-row" : ""
      }`}
    >
      {arr_list_images_videos && arr_list_images_videos?.length > 0 ? (
        arr_list_images_videos.map((media, ix) => {
          const fileExtension = media.media_url.split(".").pop().toLowerCase();
          if (
            fileExtension === "mp4" ||
            fileExtension === "webm" ||
            fileExtension === "ogg"
          ) {
            return (
              <div key={ix} className="image-container">
                {media.is_reshoot_requested === "1" ? null : (
                  <>
                    <input
                      onChange={() => handleCheckBox()}
                      type="checkbox"
                      checked={index && index.includes(media.id) ? true : false}
                      id="checkbox"
                    />
                    <label
                      onClick={() => handleIndex(media.id)}
                      htmlFor="checkbox"
                    ></label>
                  </>
                )}
                <img
                  onClick={() => handleVideo(media.media_url)}
                  className="default-img-video"
                  src={DefaultVideoIcon}
                  alt={`${index}`}
                />
                <div
                  className={`${
                    media.is_reshoot_requested === "1"
                      ? "reshoot-requested"
                      : "reshoot-requested-btn"
                  }`}
                >
                  {media.is_reshoot_requested === "1" ? (
                    <p>Reshoot requested</p>
                  ) : (
                    <Button onClick={() => handleIndex(media.id, true)}>
                      Reshoot
                    </Button>
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  height:
                    fileExtension === "png" || fileExtension === "PNG"
                      ? "auto"
                      : "100%",
                }}
                key={ix}
                className="image-container"
              >
                {media.is_reshoot_requested === "1" ? null : (
                  <>
                    <input
                      onChange={() => handleCheckBox()}
                      type="checkbox"
                      checked={index && index.includes(media.id) ? true : false}
                      id="checkbox"
                    />
                    <label
                      onClick={() => handleIndex(media.id)}
                      htmlFor="checkbox"
                    ></label>
                  </>
                )}
                <img
                  onClick={() => handleImgUri(media.media_url)}
                  className="img-container-photos"
                  style={{
                    padding:
                      fileExtension === "png" || fileExtension === "PNG"
                        ? "0"
                        : "0px",
                    height:
                      fileExtension === "png" || fileExtension === "PNG"
                        ? "auto"
                        : "100%",
                  }}
                  src={media.media_url}
                  alt={`${ix}`}
                />
                <div
                  className={`${
                    media.is_reshoot_requested === "1"
                      ? "reshoot-requested"
                      : "reshoot-requested-btn"
                  }`}
                >
                  {media.is_reshoot_requested === "1" ? (
                    <p>Reshoot requested</p>
                  ) : (
                    <Button onClick={() => handleIndex(media.id, true)}>
                      Reshoot
                    </Button>
                  )}
                </div>
              </div>
            );
          }
        })
      ) : (
        <NoDataFound title={"No Images/Videos Found..."} />
      )}
    </div>
  );
};

export default VideosPhotos;
