import React from "react";
import LoaderVideo from "../../Assets/Loader/loader1.gif";

const Loader = () => {
  return (
    <div className="video-loader-icon">
      {/* <video autoPlay muted loop>
        <source src={LoaderVideo} type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video> */}
      <img src={LoaderVideo} alt="" />
    </div>
  );
};

export default Loader;
