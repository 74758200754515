import React from "react";

const Images = ({ i, index, handleData, data, handleImage }) => {
  const handleImg = () => {
    handleData(i.id);
  };

  const newHandle = (i) => {
    handleImage(i);
  };
  return (
    <>
      {!data ? (
        <div
          onClick={() => handleImg()}
          className="styling-option-row-col"
          key={index}
        >
          <div className="img-url-styling-option">
            <img src={i.media_url} alt="" />
            <div className="styling-option-create-text">
              <p>{i.name}</p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ position: "relative" }}
          className="styling-option-row-col gradient-col"
          key={index}
        >
          <img
            className="styling-option-mobile"
            onClick={() => newHandle(i.media_url)}
            draggable="true"
            onDragStart={(e) => {
              e.dataTransfer.setData("text/plain", e.target.src);
            }}
            src={i.media_url}
            alt=""
          />
          <div className="absolute-element"></div>
          <div className="styling-option-create-text">
            <p>{i.name}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Images;
