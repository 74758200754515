import React, { useEffect, useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  getContentFormatDataActions,
  getContentFormatDataByIdActions,
} from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import "./ContentFormat.css";
import DetailsLoader from "../../Components/Loading/DetailsLoader";
import { DEFAULT_IMAGE } from "../../Middleware/DefaultImage";
import MetaTitle from "../../Components/Helmet/MetaTitle";
import { useAlert } from "react-alert";
import DisplayError from "../../Components/DisplayError/DisplayError";
import { Button } from "@mui/material";
import VIDEO_DEFAULT_ICON from "../../Assets/StylingAssets/clapperboard-high-efficiency-video-coding-video-player-video-icon-9c8cece0e3ba15290b12a2f15626acdd.png";

const ContentFormat = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  // all data for content_format_data state
  const { content_format_data, loading, error } = useSelector(
    (state) => state.showContentFormat
  );

  const { content_format_data_by_id, loading: content_loading } = useSelector(
    (state) => state.contentFormatId
  );

  const [id, setId] = useState();

  const addPhotoshootRequest = () => {
    handleModal(1);
  };

  const [isIndex, setIsIndex] = useState(null);
  const [video_url, setVideo_url] = useState("");
  const handleModal = (index, video) => {
    setVideo_url(video);
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          id={id}
          video_url={video_url}
          name={name}
          description={description}
        />
      );
    }
    return null;
  };

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isIndex === null) {
      setIsActive(true);
      setId();
      dispatch(getContentFormatDataActions());
    }
  }, [dispatch, isIndex]);

  console.log("Content Format Data: ", content_format_data);

  const handleDetails = (id) => {
    dispatch(getContentFormatDataByIdActions(id));
  };

  let content_format_details = content_format_data_by_id
    ? content_format_data_by_id?.data
    : null;

  console.log("Content Format Details: ", content_format_details);

  useEffect(() => {
    if (error) {
      alert.error(error);
    }
  }, [error, alert]);

  useEffect(() => {
    if (content_format_data_by_id && content_format_data_by_id?.status === 0) {
      alert.error(
        content_format_data_by_id && content_format_data_by_id?.data?.message
      );
    } else if (
      content_format_data_by_id &&
      content_format_data_by_id?.status === 1
    ) {
      setName(
        content_format_data_by_id && content_format_data_by_id?.data?.name
      );
      setDescription(
        content_format_data_by_id &&
          content_format_data_by_id?.data?.description
      );
    }
  }, [content_format_data_by_id, alert]);

  const handleEditReshoot = (id) => {
    setId(id);
    handleModal(1);
  };

  console.log("DTA: ", content_format_data_by_id);

  return (
    <>
      <MetaTitle
        title={loading ? "Loading..." : "Our Style - Content Format"}
      />
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : error ? (
            <DisplayError error={error} />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderProps
                title="Content Format"
                btn={true}
                handleAdd={addPhotoshootRequest}
              />
              {/* header component ends here */}

              {/* content format data */}
              <div
                className={
                  // content_format_details &&
                  // content_format_details.hasOwnProperty("id") &&
                  // content_format_details.id !== null &&
                  // content_format_details.id !== "" &&
                  // isActive ?
                  "content-format-grid-row"
                  // : "content-format-grid-row-active"
                }
              >
                <div>
                  <div
                    className={
                      // content_format_details &&
                      // content_format_details.hasOwnProperty("id") &&
                      // content_format_details.id !== null &&
                      // content_format_details.id !== "" &&
                      // isActive ?
                      "image-container-grid-row"
                      // : "image-container-grid-row-active"
                    }
                  >
                    {content_format_data &&
                    content_format_data?.data?.length > 0
                      ? content_format_data?.data?.map((i) => {
                          const image = i?.content_format_images.find((item) =>
                            /\.(jpg|jpeg|png)$/i.test(item.media_url)
                          );

                          console.log("IMAGE: ", image);
                          return (
                            <div
                              onClick={() => handleDetails(i.id)}
                              key={i.id}
                              className="image-container-col"
                            >
                              <img
                                className="imaged-format"
                                src={
                                  image !== null &&
                                  image !== undefined &&
                                  image !== ""
                                    ? image?.media_url
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                                onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                              />
                              <p className="name-tag">{i.name}</p>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div
                // style={{
                //   display:
                //     content_format_details &&
                //     content_format_details.hasOwnProperty("id") &&
                //     content_format_details.id !== null &&
                //     content_format_details.id !== "" &&
                //     isActive
                //       ? "block"
                //       : "none",
                // }}
                >
                  {content_loading ? (
                    <DetailsLoader />
                  ) : (
                    <form>
                      <label className="label" htmlFor="">
                        Name
                      </label>{" "}
                      <br />
                      <input
                        className="input-readonly"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />{" "}
                      <br />
                      <label className="label" htmlFor="">
                        Description
                      </label>{" "}
                      <br />
                      <textarea
                        className="input-readonly-textarea"
                        cols="30"
                        rows="5"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>{" "}
                      <br />
                      <div className="image-display-grid-container-row">
                        {content_format_details &&
                          content_format_details?.content_format_images?.map(
                            (i) => {
                              const fileExtension = i.media_url
                                .split(".")
                                .pop();

                              // List of video file extensions (you can extend this list as needed)
                              const videoExtensions = [
                                "mp4",
                                "webm",
                                "avi",
                                "mov",
                                "wmv",
                                "flv",
                                "mkv",
                                "m4v",
                                "3gp",
                                "ogg",
                                "ogv",
                                "mpeg",
                                "mpg",
                                "m2v",
                                "mxf",
                                "asf",
                                "rm",
                                "rmvb",
                                "ts",
                                "vob",
                              ];

                              // Checking if the file extension is in the list of video extensions
                              const isVideo = videoExtensions.includes(
                                fileExtension.toLowerCase()
                              );

                              console.log("Video: ", isVideo);
                              return (
                                <div key={i.id}>
                                  {isVideo ? (
                                    <img
                                      onClick={() =>
                                        handleModal(6, i.media_url)
                                      }
                                      src={VIDEO_DEFAULT_ICON}
                                      onError={(e) =>
                                        (e.target.src = DEFAULT_IMAGE)
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={i.media_url}
                                      onError={(e) =>
                                        (e.target.src = DEFAULT_IMAGE)
                                      }
                                      alt=""
                                    />
                                  )}
                                </div>
                              );
                            }
                          )}
                      </div>
                    </form>
                  )}
                  <div className="re-upload-container">
                    <Button
                      disabled={
                        content_format_details &&
                        content_format_details.hasOwnProperty("id") &&
                        content_format_details.id !== null &&
                        content_format_details.id !== "" &&
                        isActive
                          ? false
                          : true
                      }
                      type="submit"
                      onClick={() =>
                        handleEditReshoot(
                          content_format_details && content_format_details?.id
                        )
                      }
                    >
                      Choose Images
                    </Button>
                  </div>
                </div>
              </div>
              {/* content format data */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default ContentFormat;
