import React from "react";
import LoaderVideo from "../../Assets/Loader/Loading-Icon.mp4";

const DetailsLoader = () => {
  return (
    <div className="video-loader-icon-details">
      <video autoPlay muted loop>
        <source src={LoaderVideo} type="video/mp4" />
        Sorry, your browser doesn't support videos.
      </video>
    </div>
  );
};

export default DetailsLoader;
