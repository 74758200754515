import React, { useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import DataTable from "../../Components/DataTable/DataTable";
import { re_shoot_requirement_header_arr } from "../../Components/DataTable/DummyHeaderArray";
import MetaTitle from "../../Components/Helmet/MetaTitle";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { status_arr } from "../PhotoShoot-Request-Details/PhotoShootRequestDetails";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllReshoot_data_Actions,
  getContentFormatDataActions,
  getListofCategoryActions,
  getListofStylesActions,
} from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import DisplayError from "../../Components/DisplayError/DisplayError";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import { Pagination } from "../../Components/Pagination/Pagination";
import api from "../../Middleware/AxiosConfig";
import { BASE_URL } from "../../Middleware/BaseURL";

const ReShootRequirements = () => {
  const dispatch = useDispatch();

  //   list of category state dropdown
  const { list_of_Category, loading: category_list_loading } = useSelector(
    (state) => state.listCategories
  );

  //   content format type read state
  const { content_format_data, loading: content_format_loading } = useSelector(
    (state) => state.showContentFormat
  );

  //   list of styles state dropdown
  const { list_of_styles, loading: style_list_loading } = useSelector(
    (state) => state.listofstyles
  );

  // reshoot Table Data state
  const { reshoot_alldata, loading, error } = useSelector(
    (state) => state.reshoot_data
  );

  const [reshoot_list, setReshoot_list] = useState([]);

  useEffect(() => {
    reshoot_alldata &&
      setReshoot_list(reshoot_alldata && reshoot_alldata?.data);
  }, [reshoot_alldata]);

  // filters state
  const [style_color, setStyle_color] = useState("");

  const [category_display, setCategory_display] = useState("");
  const [category_id, setCategory_id] = useState("");

  const [content_format_id, setcontent_format_id] = useState("");
  const [content_format_display, setcontent_format_display] = useState("");

  const [status, setStatus] = useState("");
  const [status_id, setStatus_id] = useState("");

  const [isFilter_Active, setIsFilter_Active] = useState(false);

  const [totalPage_new, setTotal_page_new] = useState("");

  const fetchFilters = async (filters) => {
    try {
      // Remove keys with empty or null values
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(
          ([_, value]) => value !== null && value !== ""
        )
      );
      let response = await api.post(
        `${BASE_URL}/reshoot_request_read`,
        filteredFilters
      );

      if (response && response?.data?.status === 1) {
        setReshoot_list(response && response?.data?.data);
      }
      setTotal_page_new(response && response?.data?.pagination?.last_page);

      console.log("RESPONSE: ", response.data);
    } catch (error) {
      alert.error(error.message);
    }
  };

  const handleStyle_Color = (val) => {
    setIsFilter_Active(true);
    setStyle_color(val?.value);
    const filters = {
      style_color: val?.value,
      category_id: category_id,
      content_format_id: content_format_id,
      status: status_id,
    };
    fetchFilters(filters);
  };

  const handleCategory = (val) => {
    setIsFilter_Active(true);
    setCategory_display(val?.value);
    setCategory_id(val?.id);
    const filters = {
      style_color: style_color,
      category_id: val?.id,
      content_format_id: content_format_id,
      status: status_id,
    };
    fetchFilters(filters);
  };

  const handleContent_Format = (val) => {
    setIsFilter_Active(true);
    setcontent_format_id(val?.id);
    setcontent_format_display(val?.value);
    const filters = {
      style_color: style_color,
      category_id: category_id,
      content_format_id: val?.id,
      status: status_id,
    };
    fetchFilters(filters);
  };

  const handleStatus = (val) => {
    setIsFilter_Active(true);
    setStatus(val?.value);
    setStatus_id(val?.id);
    const filters = {
      style_color: style_color,
      category_id: category_id,
      content_format_id: content_format_id,
      status: val?.id,
    };
    fetchFilters(filters);
  };

  // filters state

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNewReshootList = async (
    page,
    style_color,
    category_id,
    content_format_id,
    status_id
  ) => {
    setIsLoading(true);
    const filters = {
      page: page,
      style_color: style_color,
      category_id: category_id,
      content_format_id: content_format_id,
      status: status_id,
    };

    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([_, value]) => value !== null && value !== ""
      )
    );
    try {
      let response = await api.post(
        `${BASE_URL}/reshoot_request_read`,
        filteredFilters
      );

      if (response && response?.data?.status === 1) {
        setReshoot_list(response && response?.data?.data);
      }
      setTotal_page_new(response && response?.data?.pagination?.last_page);
      setIsLoading(false);
    } catch (error) {
      alert.error(error.message);
      setIsLoading(false);
    }
  };

  const handlePage = (i) => {
    setPage(i);
    fetchNewReshootList(
      i,
      style_color,
      category_id,
      content_format_id,
      status_id
    );
  };

  const [imageList, setImageList] = useState([]);
  const [isIndex, setIsIndex] = useState(null);

  const handleModal = (index) => {
    setIsIndex(index);
  };

  const handleImgSlider = (imageList) => {
    console.log("Image List: ", imageList);
    setImageList(imageList);
    handleModal(2);
  };

  const [id, setId] = useState("");

  const handleCancel = (id) => {
    setId(id);
    handleModal(4);
  };

  const [uri, setUri] = useState("");

  const handleVideoUri = (video) => {
    setUri(video);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          imageList={imageList}
          isReshoot={true}
          video_url={uri}
          id={id}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (!isFilter_Active) {
      dispatch(getListofCategoryActions());
      dispatch(getContentFormatDataActions());
      dispatch(getListofStylesActions());
      dispatch(getAllReshoot_data_Actions());
    }
  }, [dispatch, isFilter_Active]);

  let content_format_dropdown =
    content_format_data && content_format_data?.data?.length > 0
      ? content_format_data?.data?.map(
          ({
            content_format_images,
            created,
            created_by,
            description,
            modified,
            modified_by,
            ...rest
          }) => rest
        )
      : [];

  console.log("DATA: ", reshoot_alldata);

  return (
    <>
      <MetaTitle title={"ReShoot Requirement"} />
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : error ? (
            <DisplayError error={error} />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderProps title="Reshoot Requirement" hide={true} />
              {/* header component ends here */}

              {/* dropdown container */}
              <div
                className="dropdown-filter-container"
                style={{ marginBottom: "1.5rem" }}
              >
                <Dropdown
                  placeholder="Style Color"
                  bgColor={true}
                  dropdown={
                    list_of_styles && list_of_styles?.data?.length > 0
                      ? list_of_styles?.data
                      : []
                  }
                  loading={style_list_loading}
                  value={style_color}
                  onData={handleStyle_Color}
                />
                <Dropdown
                  placeholder="Category"
                  bgColor={true}
                  dropdown={
                    list_of_Category && list_of_Category?.data?.length > 0
                      ? list_of_Category?.data
                      : []
                  }
                  loading={category_list_loading}
                  value={category_display}
                  onData={handleCategory}
                />
                <Dropdown
                  placeholder="Content format type"
                  bgColor={true}
                  dropdown={
                    content_format_dropdown &&
                    content_format_dropdown?.length > 0
                      ? content_format_dropdown
                      : []
                  }
                  loading={content_format_loading}
                  value={content_format_display}
                  onData={handleContent_Format}
                />
                <Dropdown
                  placeholder="Status"
                  bgColor={true}
                  dropdown={
                    status_arr && status_arr?.length > 0 ? status_arr : []
                  }
                  // loading={category_list_loading}
                  value={status}
                  onData={handleStatus}
                />
              </div>
              {/* dropdown container */}

              {/* table component starts here */}
              <div className="table-wrapper-pagination">
                <div className="table-container-pagination">
                  <DataTable
                    onCancel={handleCancel}
                    header={re_shoot_requirement_header_arr}
                    loadImage={handleImgSlider}
                    reshoot_alldata={
                      reshoot_alldata && Array.isArray(reshoot_list)
                        ? reshoot_list
                        : []
                    }
                    loadVideo={handleVideoUri}
                    handleindex={handleModal}
                  />
                </div>
              </div>
              {/* table component ends here */}

              {/* Pagination component starts here */}
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenA-${page}`}
                  page={page}
                  isLoading={isLoading && isLoading}
                  totalPage={
                    totalPage_new !== ""
                      ? totalPage_new
                      : reshoot_alldata &&
                        reshoot_alldata?.pagination?.last_page
                  }
                  onPageChange={handlePage}
                />
              </div>
              {/* Pagination component ends here */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default ReShootRequirements;
