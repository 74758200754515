import React, { useCallback, useEffect, useState } from "react";
import Header from "../../Components/Navbar/Navbar";
import SideBar from "../../Components/Sidebar/Sidebar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import "./Dashboard.css";
import DoughnutChart from "../../Components/Bars/DoughnutChart/Doughnutchart";
import DateFilter from "../../Components/DateFilter/DateFilter";
import { dateArr } from "../../Components/DateFilter/DropdownStylesDateArr";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardAllDataActions,
  getArticle_wise_barChart_Actions,
  getDashboardDoughnutCategoryWiseActions,
  getDashboardDoughnutContentWiseActions,
} from "../../Actions/StyleActions";
import { useAlert } from "react-alert";
import Loader from "../../Components/Loading/Loader";
import DisplayError from "../../Components/DisplayError/DisplayError";
import MetaTitle from "../../Components/Helmet/MetaTitle";
import LoadingContent from "../../Components/Loading/Loading";
import BarChart from "../../Components/Bars/Bar/BarChart";

const Dashboard = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  // metrics state
  const { dashboard_metrics, loading, error } = useSelector(
    (state) => state.dashboard
  );

  // content wise doughnut chart state
  const { content_wise_data, loading: doughnut_content_loading } = useSelector(
    (state) => state.contentWise
  );

  // category wise doughnut chart state
  const { category_wise_data, loading: doughnut_category_loading } =
    useSelector((state) => state.categoryWise);

  // article wise bar chart state
  const { article_wise_data } = useSelector((state) => state.articleWise_data);

  const [metric_filter_display, setmetric_filter_display] = useState(
    dateArr && dateArr[0]?.name
  );
  const [metricFilter, setMetricFilter] = useState(dateArr && dateArr[0]?.id);

  const [isFilter, setIsFilter] = useState(false);

  const handleMainFilter = (children) => {
    setIsFilter(true);
    setMetricFilter(children?.value);
    setmetric_filter_display(children?.label);

    dispatch(DashboardAllDataActions(children?.value));
  };

  const [hoverOffset, setHoverOffset] = useState(20);
  const [padding, setPadding] = useState(20);

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setHoverOffset(5);
      setPadding(5);
    } else {
      setHoverOffset(20);
      setPadding(20);
    }
  }, []);

  const [contentWiseDate, setContentWiseDate] = useState(
    dateArr && dateArr[0]?.name
  );
  const [content_wise_date_id, setcontent_wise_date_id] = useState(
    dateArr && dateArr[0]?.id
  );

  const [categoryWiseDate, setCategoryWiseDate] = useState(
    dateArr && dateArr[0]?.name
  );
  const [category_wise_date_id, setcategory_wise_date_id] = useState(
    dateArr && dateArr[0]?.id
  );

  const [article_loading, setArticle_loading] = useState(false);
  const [articleWiseDate, setArticleWiseDate] = useState(
    dateArr && dateArr[0]?.name
  );
  const [articleWiseDate_id, setArticleWiseDate_id] = useState(
    dateArr && dateArr[0]?.id
  );

  const [content_loading, setContent_loading] = useState(false);

  const handleContentDateChange = (children) => {
    setContent_loading(true);
    setIsFilter(true);
    console.log("Content", children);
    setContentWiseDate(children?.label);
    setcontent_wise_date_id(children?.value);
    dispatch(getDashboardDoughnutContentWiseActions(children?.value));
    setTimeout(() => {
      setContent_loading(false);
    }, 2000);
  };

  const [styling_loading, setStyling_loading] = useState(false);
  const handleCategoryDateChange = (children) => {
    setStyling_loading(true);
    setIsFilter(true);
    setCategoryWiseDate(children?.label);
    setcategory_wise_date_id(children?.value);
    dispatch(getDashboardDoughnutCategoryWiseActions(children?.value));
    setStyling_loading(false);
  };

  const handleArticleWise_data = async (children) => {
    setArticle_loading(true);
    setIsFilter(true);
    setArticleWiseDate(children?.label);
    setArticleWiseDate_id(children?.value);
    await dispatch(getArticle_wise_barChart_Actions(children?.value));
    setArticle_loading(false);
  };

  const fetchDashboardApis = useCallback(() => {
    dispatch(DashboardAllDataActions(metricFilter));
    dispatch(getDashboardDoughnutCategoryWiseActions(content_wise_date_id));
    dispatch(getDashboardDoughnutContentWiseActions(category_wise_date_id));
    dispatch(getArticle_wise_barChart_Actions(articleWiseDate_id));
  }, [
    dispatch,
    metricFilter,
    content_wise_date_id,
    category_wise_date_id,
    articleWiseDate_id,
  ]);

  useEffect(() => {
    if (!isFilter) {
      fetchDashboardApis();
    }
  }, [fetchDashboardApis, isFilter]);

  useEffect(() => {
    if (error && error !== "" && error !== undefined && error !== null) {
      alert.error(error);
    }
  }, [error, alert]);

  let dashboard_metrics_data = dashboard_metrics && dashboard_metrics?.data;
  let productivity_metrics_data =
    dashboard_metrics_data && dashboard_metrics_data?.productivity;
  let studio_metrics_data =
    dashboard_metrics_data && dashboard_metrics_data?.studio;

  let content_wise_data_arr =
    content_wise_data && content_wise_data?.data?.content_type_with_count;
  let styling_wise_data_arr =
    category_wise_data &&
    category_wise_data?.data?.styling_item_types_with_count;

  console.log("Category Data: ", styling_wise_data_arr);
  console.log("Content Data: ", content_wise_data_arr);

  const studio_metrics = [
    {
      id: 1,
      bgColor: "rgba(255, 178, 178, 1)",
      title: "Total Pending Photoshoot",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_pending_photoshoot !== "" &&
        studio_metrics_data?.total_pending_photoshoot !== null &&
        studio_metrics_data?.total_pending_photoshoot !== undefined
          ? studio_metrics_data?.total_pending_photoshoot
          : 0,
    },
    {
      id: 2,
      bgColor: "rgba(219, 196, 240, 1)",
      title: "Total Complete Photoshoot",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_complete_photoshoot !== "" &&
        studio_metrics_data?.total_complete_photoshoot !== null &&
        studio_metrics_data?.total_complete_photoshoot !== undefined
          ? studio_metrics_data?.total_complete_photoshoot
          : 0,
    },
    {
      id: 3,
      bgColor: "rgba(212, 226, 212, 1)",
      title: "Total Cancel Photoshoot",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_cancel_photoshoot !== "" &&
        studio_metrics_data?.total_cancel_photoshoot !== null &&
        studio_metrics_data?.total_cancel_photoshoot !== undefined
          ? studio_metrics_data?.total_cancel_photoshoot
          : 0,
    },
    {
      id: 4,
      bgColor: "rgba(255, 198, 172, 1)",
      title: "Total Reshoot",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_reshoot_photoshoot !== "" &&
        studio_metrics_data?.total_reshoot_photoshoot !== null &&
        studio_metrics_data?.total_reshoot_photoshoot !== undefined
          ? studio_metrics_data?.total_reshoot_photoshoot
          : 0,
    },
    {
      id: 5,
      bgColor: "rgba(253, 206, 223, 1)",
      title: "Total Reshoot Complete",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_reshoot_complete !== "" &&
        studio_metrics_data?.total_reshoot_complete !== null &&
        studio_metrics_data?.total_reshoot_complete !== undefined
          ? studio_metrics_data?.total_reshoot_complete
          : 0,
    },
    {
      id: 6,
      bgColor: "rgba(172, 177, 214, 1)",
      title: "Total Reshoot Pending",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_reshoot_pending !== "" &&
        studio_metrics_data?.total_reshoot_pending !== null &&
        studio_metrics_data?.total_reshoot_pending !== undefined
          ? studio_metrics_data?.total_reshoot_pending
          : 0,
    },
    {
      id: 7,
      bgColor: "rgba(255, 242, 204, 1)",
      title: "Total Styling Option pending",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_styling_option_pending !== "" &&
        studio_metrics_data?.total_styling_option_pending !== null &&
        studio_metrics_data?.total_styling_option_pending !== undefined
          ? studio_metrics_data?.total_styling_option_pending
          : 0,
    },
    {
      id: 8,
      bgColor: "rgba(247, 200, 224, 1)",
      title: "Total Styling Approved",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_styling_approved !== "" &&
        studio_metrics_data?.total_styling_approved !== null &&
        studio_metrics_data?.total_styling_approved !== undefined
          ? studio_metrics_data?.total_styling_approved
          : 0,
    },
    {
      id: 9,
      bgColor: "rgba(237, 219, 199, 1)",
      title: "Total Styling Element Available",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_styling_items_available !== "" &&
        studio_metrics_data?.total_styling_items_available !== null &&
        studio_metrics_data?.total_styling_items_available !== undefined
          ? studio_metrics_data?.total_styling_items_available
          : 0,
    },
    {
      id: 10,
      bgColor: "rgba(210, 233, 233, 1)",
      title: "Total Styling Element Option",
      count:
        studio_metrics_data &&
        studio_metrics_data?.total_styling_items_types !== "" &&
        studio_metrics_data?.total_styling_items_types !== null &&
        studio_metrics_data?.total_styling_items_types !== undefined
          ? studio_metrics_data?.total_styling_items_types
          : 0,
    },
  ];

  const productivity_metrics = [
    {
      id: 1,
      bgColor: "rgba(228, 208, 208, 1)",
      count:
        productivity_metrics_data &&
        productivity_metrics_data?.total_articles_done !== "" &&
        productivity_metrics_data?.total_articles_done !== null &&
        productivity_metrics_data?.total_articles_done !== undefined
          ? productivity_metrics_data?.total_articles_done
          : 0,
      title: "Total Articles Done",
    },
    {
      id: 2,
      bgColor: "rgba(254, 222, 255, 1)",
      count:
        productivity_metrics_data &&
        productivity_metrics_data?.total_articales_content_count !== "" &&
        productivity_metrics_data?.total_articales_content_count !== null &&
        productivity_metrics_data?.total_articales_content_count !== undefined
          ? productivity_metrics_data?.total_articales_content_count
          : 0,
      title: "Total Articles Content Count",
    },
  ];

  const stats_list = [
    {
      name: "No Data",
      count: 0,
    },
  ];

  const order_chart = [
    {
      label: "No Data",
      total_article_count: 0,
      total_article_content_count: 0,
      reshoot_article: 0,
      reshoot_article_content_count: 0,
    },
  ];

  console.log("Article Wise Bar Chart: ", article_wise_data?.data);

  return (
    <>
      <MetaTitle
        title={
          loading || doughnut_category_loading || doughnut_content_loading
            ? "Loading..."
            : "Dashboard"
        }
      />
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {!isFilter &&
          (loading || doughnut_category_loading || doughnut_content_loading) ? (
            <Loader />
          ) : error ? (
            <DisplayError error={error} />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderProps
                title="Studio Dashboard"
                onDate={handleMainFilter}
                metricFilter={metric_filter_display}
              />
              {/* header component ends here */}
              <div className="studio-metrics">
                {studio_metrics &&
                  studio_metrics.map((i) => (
                    <div style={{ backgroundColor: i.bgColor }} key={i.id}>
                      <h4>{i.count}</h4>
                      <p>{i.title}</p>
                    </div>
                  ))}
              </div>

              <h2 className="productivity-metrics-title">
                Productivity Metrics
              </h2>
              <div className="studio-metrics">
                {productivity_metrics &&
                  productivity_metrics.map((i) => (
                    <div style={{ backgroundColor: i.bgColor }} key={i.id}>
                      <h4>{i.count}</h4>
                      <p>{i.title}</p>
                    </div>
                  ))}
              </div>

              {/* doughnut chart component starts here */}
              <div className="grid-doughnut-chart-row">
                <div>
                  <div className="doughnut-header">
                    <div>
                      <p>Statistics</p>
                      <h3>Content Format Wise</h3>
                    </div>
                    <div>
                      <DateFilter
                        onDateChange={handleContentDateChange}
                        metricFilter={contentWiseDate}
                      />
                    </div>
                  </div>
                  <div className="doughnut-wrapper">
                    {content_loading ? (
                      <LoadingContent />
                    ) : (
                      <DoughnutChart
                        hoverOffset={hoverOffset}
                        padding={padding}
                        data_arr={
                          content_wise_data_arr &&
                          content_wise_data_arr?.length > 0
                            ? content_wise_data_arr
                            : stats_list && stats_list.length > 0
                            ? stats_list
                            : null
                        }
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div className="doughnut-header">
                    <div>
                      <p>Statistics</p>
                      <h3>Category Wise</h3>
                    </div>
                    <div>
                      <DateFilter
                        onDateChange={handleCategoryDateChange}
                        metricFilter={categoryWiseDate}
                      />
                    </div>
                  </div>
                  <div className="doughnut-wrapper">
                    {styling_loading ? (
                      <LoadingContent />
                    ) : (
                      <DoughnutChart
                        hoverOffset={hoverOffset}
                        padding={padding}
                        data_arr={
                          styling_wise_data_arr &&
                          styling_wise_data_arr?.length > 0
                            ? styling_wise_data_arr
                            : stats_list && stats_list.length > 0
                            ? stats_list
                            : null
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* doughnut chart component ends here */}

              {/* Bar chart */}
              <div className="bar-chart-statistics">
                <div className="doughnut-header bar-header">
                  <div>
                    <p>Statistics</p>
                    <h3>Articles Wise</h3>
                  </div>
                  <div>
                    <DateFilter
                      article={true}
                      onDateChange={handleArticleWise_data}
                      metricFilter={articleWiseDate}
                    />
                  </div>
                </div>
                {article_loading ? (
                  <LoadingContent />
                ) : (
                  <BarChart
                    order_chart={
                      article_wise_data &&
                      Array.isArray(article_wise_data?.data) &&
                      article_wise_data?.data?.length > 0
                        ? article_wise_data?.data
                        : order_chart
                    }
                  />
                )}
              </div>
              {/* Bar Chart */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
