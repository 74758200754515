import React from "react";
import "./Loader.css";

const CustomLoader = ({ small }) => {
  return (
    <div
      style={{ width: small ? "14px" : "", height: small ? "14px" : "" }}
      className="loader"
    ></div>
  );
};

export default CustomLoader;
