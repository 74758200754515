import React from "react";
import Error from "../../Assets/Loader/Error.gif";
import "./DisplayError.css";

const DisplayError = (props) => {
  const { error } = props;
  return (
    <div className="error-display">
      <img src={Error} alt="" />
      <p>{error}</p>
    </div>
  );
};

export default DisplayError;
