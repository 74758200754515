export const RadiuscustomStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#fffff",
    fontFamily: "Poppins_Regular",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_Regular",
    backgroundColor: "rgba(233, 232, 255, 1)",
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "5px",
    padding: "0px 10px 0px 10px",
    minHeight: "40px",
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#1C1C1C" }),
};

export const dateArr = [
  { id: "today", name: "Today" },
  { id: "yesterday", name: "Yesterday" },
  { id: "current_month", name: "Current Month" },
  { id: "last_month", name: "Last Month" },
];

export const dateArr_article = [
  { id: "today", name: "Today" },
  { id: "yesterday", name: "Yesterday" },
  { id: "last_seven_days", name: "Last 7 Days" },
  { id: "current_month", name: "Current Month" },
  { id: "last_three_months", name: "Last 3 Month" },
  { id: "last_six_months", name: "Last 6 Month" },
];
