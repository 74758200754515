import React from "react";
import No_Data_Found from "../../Assets/Loader/no_data_found.gif";

const NoDataFound = ({ title }) => {
  return (
    <div className="no-data-found">
      <img src={No_Data_Found} alt="" />
      <p className="no-found">{title}</p>
    </div>
  );
};

export default NoDataFound;
