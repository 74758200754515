import React from "react";
import Select from "react-select";
import {
  RadiuscustomStyles,
  dateArr,
  dateArr_article,
} from "./DropdownStylesDateArr";

const DateFilter = (props) => {
  const handleDateChange = (selectedOption) => {
    props.onDateChange(selectedOption);
  };

  console.log(props.metricFilter);

  return (
    <div>
      {props.article ? (
        <Select
          placeholder={`${
            props.metricFilter !== undefined
              ? props.metricFilter
              : dateArr_article[0].name
          }`}
          options={
            dateArr_article &&
            dateArr_article.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          }
          styles={RadiuscustomStyles}
          onChange={handleDateChange}
        />
      ) : (
        <Select
          placeholder={`${
            props.metricFilter !== undefined
              ? props.metricFilter
              : dateArr[0].name
          }`}
          options={
            dateArr &&
            dateArr.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          }
          styles={RadiuscustomStyles}
          onChange={handleDateChange}
        />
      )}
    </div>
  );
};

export default DateFilter;
