import React, { useRef, useState } from "react";
import "./HeaderProps.css";
import DateFilter from "../DateFilter/DateFilter";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExcelIcon from "../../Assets/StylingAssets/Xls 1.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useAlert } from "react-alert";
import api from "../../Middleware/AxiosConfig";
import { BASE_URL } from "../../Middleware/BaseURL";
import CustomLoader from "../Loading/CustomLoader";
import DownloadIcon from "@mui/icons-material/Download";
import CSVFile from "../../Assets/Files/photoshoot_request_detail_import.csv";

const HeaderProps = (props) => {
  const { id, downloadExcelFile } = props;
  console.log(id);
  const alert = useAlert();
  const fileInputRef = useRef(null);
  const handleDateChange = (children) => {
    props.onDate(children);
  };

  const [is_Uploading, setIs_uploading] = useState(false);

  const handleFile = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileNameArray = selectedFile.name.split(".");
      const fileExtension =
        fileNameArray[fileNameArray.length - 1].toLowerCase();

      if (fileExtension === "csv") {
        setIs_uploading(true);
        // File is a CSV, set it in state or perform further actions
        // console.log("Selected file:", selectedFile);
        const formData = new FormData();
        formData.set("photoshoot_request_id", id);
        formData.set("file", selectedFile);
        api
          .post(`${BASE_URL}/photoshoot_request_detail_import`, formData)
          .then((response) => {
            if (response && response?.data?.status === 1) {
              alert.success(response && response?.data?.message);
              setIs_uploading(false);
            } else if (response && response?.data?.status === 0) {
              alert.error(response && response?.data?.message);
              setIs_uploading(false);
            }
          })
          .catch((err) => {
            alert.error(err.message);
            setIs_uploading(false);
          });
      } else {
        // File is not a CSV, you can show an error message or handle it accordingly
        alert.error("Please select a CSV file");
      }
    }
  };

  const handleAdd = () => {
    props.handleAdd();
  };

  const handleFileAdd = () => {
    fileInputRef.current.click();
  };

  const handleRedirect = () => {
    props.handleRedirect();
  };

  const handleNormal = () => {};

  const handleDownload = () => {
    // Replace 'your_file_url' with the actual URL of the file you want to download
    const fileUrl = CSVFile;

    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the file URL
    link.href = fileUrl;

    // Set the download attribute with the desired file name
    link.download = "Test_File";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };
  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".csv"
        onChange={handleFile}
      />
      <div
        className={`headline-props ${
          props.middleLevel && "headline-middle-device"
        }`}
      >
        <div
          onClick={props.redirect ? handleRedirect : handleNormal}
          className="back-navigate"
        >
          {props.redirect && <KeyboardBackspaceIcon />}
          <h1>{props.title}</h1>
        </div>
        <div>
          {!props.hide && (
            <>
              {props.btn ? (
                <Button onClick={handleAdd} className="btn-add">
                  {props.btnText ? (
                    "Reshoot"
                  ) : (
                    <>
                      <AddIcon />
                      &nbsp;Add
                    </>
                  )}
                </Button>
              ) : props.excel ? (
                <div className="excel-file-container">
                  <Button onClick={handleFileAdd} className="btn-add">
                    {is_Uploading ? (
                      <CustomLoader />
                    ) : (
                      <>
                        <img src={ExcelIcon} alt="" />
                        &nbsp;&nbsp;Import excel file
                      </>
                    )}
                  </Button>
                  {downloadExcelFile && (
                    <Tooltip title="Download Excel File">
                      <Button onClick={handleDownload}>
                        <DownloadIcon color="rgba(82, 78, 183, 1)" />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              ) : (
                <DateFilter
                  onDateChange={handleDateChange}
                  metricFilter={props.metricFilter}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderProps;
