import React, { useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllPhotoshoot_data_Actions,
  getReshoot_details_Actions,
} from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import DisplayError from "../../Components/DisplayError/DisplayError";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import VideosPhotos from "../../Components/VideoPhotos/VideosPhotos";
import { useAlert } from "react-alert";

const UploadPhotoVideo = () => {
  const alert = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);
  const reshoot = query.get("reshoot");

  const { photoshoot_alldata, error, loading } = useSelector(
    (state) => state.photoshoot_data
  );

  // detail reshoot
  const {
    reshoot_requirement_details,
    loading: loading_reshoot,
    error: reshoot_error,
  } = useSelector((state) => state.reshoot_details);

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(-1);
  };

  let arr_list_images_videos = [];

  photoshoot_alldata && Array.isArray(photoshoot_alldata?.data) && !reshoot
    ? photoshoot_alldata?.data.forEach((i) => {
        // i?.studio_photoshoot_medias?.forEach((j) => {
        if (!arr_list_images_videos.includes(i.id)) {
          arr_list_images_videos.push(i);
        } else {
          return null;
        }
        // });
      })
    : reshoot_requirement_details &&
      Array.isArray(
        reshoot_requirement_details?.data?.studio_photoshoot_medias
      ) &&
      reshoot_requirement_details?.data?.studio_photoshoot_medias?.forEach(
        (k) => {
          console.log("XYZ:", k);
          if (!arr_list_images_videos.includes(k.id)) {
            arr_list_images_videos.push(k);
          } else {
            return null;
          }
        }
      );

  const [isChecked, setIsChecked] = useState(false);
  const [index, setIsindex] = useState([]);

  const handleCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const handleIndex = (id, i) => {
    if (i !== undefined && i !== null) {
      handleModal(5);
    }
    if (index.includes(id)) {
      setIsindex((prevNum) => prevNum.filter((num) => num !== id));
    } else {
      setIsindex((prev) => [...prev, id]);
    }
  };

  const [isIndex, setIsIndex] = useState(null);
  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const [video_url, setvideo_url] = useState("");
  const [imageList, setimageList] = useState([]);

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          reshoot={true}
          requested_media_ids={index}
          id={id}
          new_reshoot={reshoot}
          photoshoot_request_detail_id={
            reshoot_requirement_details &&
            reshoot_requirement_details?.data?.photoshoot_request_detail_id
          }
          video_url={video_url}
          imageList={imageList}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    if (
      id !== null &&
      id !== "" &&
      id !== undefined &&
      !reshoot &&
      isIndex === null
    ) {
      dispatch(getAllPhotoshoot_data_Actions(id));
    }
  }, [dispatch, id, reshoot, isIndex]);

  useEffect(() => {
    if (
      id !== null &&
      id !== "" &&
      id !== undefined &&
      reshoot &&
      isIndex === null
    ) {
      dispatch(getReshoot_details_Actions(id));
    }
  }, [dispatch, id, reshoot, isIndex]);

  useEffect(() => {
    if (isIndex === null) {
      setIsindex([]);
    }
  }, [isIndex]);

  const handleReshootRequest = () => {
    if (index && index?.length > 0) {
      handleModal(5);
    } else {
      alert.error("Please Choose Images/Videos For Reshoot Request");
    }
  };

  const handleVideoUri = (uri) => {
    setvideo_url(uri);
    handleModal(6);
  };

  const handleImgUri = (uri) => {
    setimageList([{ media_url: uri }]);
    handleModal(2);
  };

  console.log(
    "DATA: ",
    reshoot_requirement_details &&
      reshoot_requirement_details?.data?.photoshoot_request_detail_id
  );
  return (
    <>
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading || loading_reshoot ? (
            <Loader />
          ) : error || reshoot_error ? (
            <DisplayError
              error={error ? error : reshoot_error ? reshoot_error : null}
            />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <div>
                <HeaderProps
                  title="Upload Photo and Video"
                  btn={true}
                  btnText={true}
                  redirect={true}
                  handleRedirect={handleRedirect}
                  handleAdd={handleReshootRequest}
                />
              </div>
              {/* header component ends here */}

              {/* map component starts here */}
              <VideosPhotos
                handleCheckBox={handleCheckBox}
                handleIndex={handleIndex}
                arr_list_images_videos={arr_list_images_videos}
                index={index}
                handleVideoUri={handleVideoUri}
                handleImgUri_new={handleImgUri}
              />
              {/* map component ends here */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default UploadPhotoVideo;
