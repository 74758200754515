import React, { useCallback, useEffect, useRef } from "react";
import { fabric } from "fabric";
import { Button } from "@mui/material";
import saveIcon from "../../Assets/StylingAssets/direct-inbox.png";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../Middleware/AxiosConfig";
import { BASE_URL } from "../../Middleware/BaseURL";
import { useAlert } from "react-alert";
import { useState } from "react";
import CustomLoader from "../../Components/Loading/CustomLoader";

function CanvasRow({ imageUrl, photoshoot_request_detail_id }) {
  const alert = useAlert();
  const canvasRef = useRef(null);
  const dropZoneRef = useRef(null);
  let canvas;
  let isImageLoading = false;

  const [loadingJson, setLoadingJson] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    canvas = new fabric.Canvas(canvasRef.current, {
      width: 340,
      height: 600,
    });

    // Enable drop event to allow image uploading
    canvas.on("mouse:down", (options) => {
      if (options.target) {
        // If an object is clicked, select it
        canvas.setActiveObject(options.target);
      } else {
        // If the canvas is clicked, deselect any selected object
        canvas.discardActiveObject();
      }
    });

    // Function to delete the selected object
    const deleteSelectedObject = () => {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
      }
    };

    // const json = JSON.stringify(canvas.toJSON());
    // // Create a Blob containing the JSON data
    // const blob = new Blob([json], { type: "application/json" });
    // // Create a download link for the JSON file
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "canvas.json";
    // a.click();

    // Function to export canvas state as JSON
    // const exportCanvasToJson = () => {
    //   const image = new Image();
    //   image.src = canvas.toDataURL(); // Convert the canvas to a data URL

    //   // Create an anchor element to trigger the download
    //   const downloadLink = document.createElement("a");
    //   downloadLink.href = image.src;
    //   downloadLink.download = "canvas_image.png"; // Set the filename for the downloaded image

    //   // Simulate a click on the anchor element to trigger the download
    //   downloadLink.click();

    //   console.log(image.src);

    //   const json = JSON.stringify(canvas.toJSON());

    //   // Create a Blob containing the JSON data
    //   const blob = new Blob([json], { type: "application/json" });

    //   // Create a FormData object and append the Blob file
    //   const formData = new FormData();
    //   formData.set(
    //     "photoshoot_request_detail_id",
    //     photoshoot_request_detail_id
    //   );
    //   formData.append("file", blob, "canvas.json");

    //   api
    //     .post(`${BASE_URL}/styling_set_create`, formData)
    //     .then((response) => {
    //       console.log("Response: ", response);
    //     })
    //     .catch((err) => {
    //       console.log(err.message);
    //     });
    // };

    const exportCanvasToJson = () => {
      setLoadingJson(true);
      const canvas_image = document.getElementById("customization-canvas"); // Replace 'yourCanvasId' with the ID of your canvas element
      const json = JSON.stringify(canvas.toJSON());

      // Create a Blob containing the JSON data
      const blob_json = new Blob([json], { type: "application/json" });
      const image = new Image();
      image.onload = function () {
        canvas_image.width = image.width;
        canvas_image.height = image.height;
        canvas_image.getContext("2d").drawImage(image, 0, 0);

        const pngDataUrl = canvas_image.toDataURL("image/png");

        const blob = dataURItoBlob(pngDataUrl);

        // const downloadBlob = (blob, fileName) => {
        //   const url = URL.createObjectURL(blob);
        //   const a = document.createElement("a");
        //   a.href = url;
        //   a.download = fileName;
        //   a.textContent = "Download " + fileName;
        //   a.style.display = "none";
        //   document.body.appendChild(a);
        //   a.click();
        //   document.body.removeChild(a);
        // };

        // downloadBlob(blob, "canvas_image.png");

        const formData = new FormData();
        formData.set(
          "photoshoot_request_detail_id",
          photoshoot_request_detail_id
        );
        formData.append("image", blob, "canvas_image.png");
        formData.append("file", blob_json, "canvas.json");

        // Replace 'api' with your API call method (e.g., fetch, axios)
        api
          .post(`${BASE_URL}/styling_set_create`, formData)
          .then((response) => {
            if (response && response?.data?.status === 1) {
              setLoadingJson(false);
              alert.success(response && response?.data?.message);
            } else if (response && response?.data?.status === 0) {
              setLoadingJson(false);
              alert.error(response && response?.data?.message);
            }
          })
          .catch((err) => {
            setLoadingJson(false);
            alert.error(err.message);
          });
      };

      image.src = canvas.toDataURL(); // Convert the canvas to a data URL
    };

    function dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    }

    // Example: Add a button to delete the selected image
    const deleteButton = document.getElementById("delete-button");
    deleteButton.addEventListener("click", deleteSelectedObject);

    // Example: Add a button to export canvas as JSON
    const exportButton = document.getElementById("export-button");
    exportButton.addEventListener("click", exportCanvasToJson);

    // Prevent the default behavior of drag-and-drop to allow dropping images
    dropZoneRef.current.addEventListener("dragover", (e) => {
      e.preventDefault();
    });

    // Handle the dropped image and load it onto the canvas
    dropZoneRef.current.addEventListener("drop", (e) => {
      e.preventDefault();
      const imageUrl = e.dataTransfer.getData("text/plain");
      if (!isImageLoading) {
        // eslint-disable-next-line
        isImageLoading = true; // Set the flag to indicate image loading

        // Clear the canvas to remove existing objects
        // Load the new image
        loadExternalImage(imageUrl);
      }
    });

    return () => {
      canvas.dispose();
    };
  }, []);

  // Function to load an external image onto the canvas
  // Function to load an external image onto the canvas
  // const loadExternalImage = useCallback((imageUrl) => {
  //   console.log(imageUrl);
  //   fabric.Image.fromURL(imageUrl, (img) => {
  //     const initialScaleFactor = 0.2; // Set the initial scale factor
  //     const fullWidth = 300; // Full canvas width

  //     img.set({
  //       left: canvas.width / 2 - (fullWidth * initialScaleFactor) / 2, // Center the image horizontally
  //       top: canvas.height / 2 - (img.height * initialScaleFactor) / 2, // Center the image vertically
  //       scaleX: initialScaleFactor, // Scale horizontally
  //       scaleY: initialScaleFactor, // Scale vertically
  //       selectable: true, // Make the image selectable
  //       evented: true, // Allow events on the image
  //     });
  //     canvas.add(img);
  //     // eslint-disable-next-line
  //     isImageLoading = false;
  //   });
  // }, []);

  const loadExternalImage = useCallback(
    (imageUrl) => {
      
      const imgElement = new Image();

      imgElement.crossOrigin = "Anonymous";
      const customProxyUrl = "http://localhost:8080/proxy?url=";

      imgElement.onload = () => {
        const initialScaleFactor = 0.2; // Set the initial scale factor
        const fullWidth = 300;
        const fabricImage = new fabric.Image(imgElement, {
          left: canvas.width / 2 - (fullWidth * initialScaleFactor) / 2, // Center the image horizontally
          top: canvas.height / 2 - (imgElement.height * initialScaleFactor) / 2, // Center the image vertically
          scaleX: initialScaleFactor, // Scale horizontally
          scaleY: initialScaleFactor, // Scale vertically
          selectable: true, // Make the image selectable
          evented: true,
          // additional properties or manipulation here
        });

        canvas.add(fabricImage);
        // eslint-disable-next-line
        isImageLoading = false; // Update the .current property of useRef
      };

      imgElement.onerror = (error) => {
        alert.error(error.type);
        // console.log(error.type)
        // Handle the error (e.g., show a message to the user)
      };

      // imgElement.src = proxiedImageUrl;
      imgElement.src = customProxyUrl + encodeURIComponent(imageUrl);
    },
    [canvas, alert]
  );

  useEffect(() => {
    if (imageUrl) {
      loadExternalImage(imageUrl);
    }
  }, [imageUrl, loadExternalImage]);

  // const handleSaveJson = () => {
  // const json = JSON.stringify(canvas.toJSON());
  // // Create a Blob containing the JSON data
  // const blob = new Blob([json], { type: "application/json" });
  // // Create a download link for the JSON file
  // const url = URL.createObjectURL(blob);
  // const a = document.createElement("a");
  // a.href = url;
  // a.download = "canvas.json";
  // a.click();
  // URL.revokeObjectURL(url);
  // };

  console.log("Pho: ", imageUrl);

  return (
    <>
      <div ref={dropZoneRef} id="drop-zone">
        <p>
          Drag and drop images <br /> here
        </p>
      </div>
      <div className="border-drag-drop-request">
        <canvas ref={canvasRef} id="customization-canvas"></canvas>
        <div className="save-cta">
          <Button id="export-button">
            {loadingJson ? (
              <CustomLoader />
            ) : (
              <>
                <img src={saveIcon} alt="" />
                &nbsp; Save
              </>
            )}
          </Button>
          <Button id="delete-button">
            <DeleteIcon />
            &nbsp; Delete
          </Button>
        </div>
      </div>
    </>
  );
}

export default CanvasRow;
