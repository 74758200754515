import React, { useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/styles.css";
import "./ImageSlider.css";
import DetailsLoader from "../../Loading/DetailsLoader";
import { DEFAULT_IMAGE } from "../../../Middleware/DefaultImage";
import CloseIcon from "@mui/icons-material/Close";

const ImageSlider = (props) => {
  const { imageList, close } = props;
  console.log("Images For Slider: ", imageList);

  const [isImage_loaded, setIsimage_loaded] = useState(true);

  return (
    <>
      <div onClick={close} className="cross-icon-modal">
        <CloseIcon className="cross-icon-col" />
      </div>
      <AwesomeSlider animation="cubeAnimation">
        {imageList && imageList.length > 0
          ? imageList.map((i, index) => (
              <div className="slider-image-container" key={index}>
                <img
                  onError={(e) => (e.target.src = DEFAULT_IMAGE)}
                  onLoad={() => setIsimage_loaded(false)}
                  src={i.media_url}
                  alt=""
                />
                <div className="is-image-loaded">
                  {isImage_loaded ? <DetailsLoader /> : null}
                </div>
              </div>
            ))
          : null}
      </AwesomeSlider>
    </>
  );
};

export default ImageSlider;
