import React, { useEffect, useState } from "react";
import Select from "react-select-virtualized";

export const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#fffff",
    fontFamily: "Poppins_Regular",
    whiteSpace: "nowrap", // Allow text to wrap within the option
    overflow: "hidden", // Allow long words to break into multiple lines
    textOverflow: "ellipsis",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "#fffff",
    border: "1px solid #E6E6E6",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "5px",
    padding: "0px 0px",
    minHeight: "48px",
    marginTop: "5px",
    marginBottom: "25px",
    "&:hover": {
      border: "1px solid #E6E6E6 !important",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontFamily: "Poppins_Regular",
    fontSize: "14px", // Replace 'Your desired font' with the font you want to use
    maxWidth: "300px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    // Add other menu styles if needed
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#1C1C1C" }),
};

export const customStylesFilter = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#000" : "#1C1C1C",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#fffff",
    fontFamily: "Poppins_Regular",
    whiteSpace: "nowrap", // Allow text to wrap within the option
    overflow: "hidden", // Allow long words to break into multiple lines
    textOverflow: "ellipsis",
    fontSize: "14px",
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: "#000",
    fontFamily: "Poppins_SemiBold",
    fontSize: "14px",
  }),

  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Poppins_SemiBold",
    backgroundColor: "rgba(233, 232, 255, 1)",
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    borderRadius: "5px",
    padding: "0px 0px",
    minHeight: "48px",
    marginTop: "5px",
    marginBottom: "25px",
    "&:hover": {
      border: "none",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    fontFamily: "Poppins_Regular",
    fontSize: "14px", // Replace 'Your desired font' with the font you want to use
    maxWidth: "300px",
    overflowX: "auto",
    whiteSpace: "nowrap",
    // Add other menu styles if needed
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#1C1C1C" }),
};

const Dropdown = (props) => {
  const { dropdown, value, onData, placeholder, loading, bgColor, takeonTop } =
    props;

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (val) => {
    onData && onData(val);
  };

  const clearFilter = (e) => {
    e.preventDefault();
    handleChange(null); // Clear the selected value
    setTimeout(() => {
      setSelectedValue(placeholder);
    }, 500);
  };

  let options = [];

  if (dropdown && dropdown?.length > 0) {
    options = dropdown.map((option) => ({
      value: option.sku || option.name,
      label: option.sku || option.name,
      id: option.id,
    }));
  }

  console.log("Dropdown: ", dropdown, value);

  useEffect(() => {
    if (value !== null || value !== "") {
      setSelectedValue(
        value?.length > 16 ? value.substring(0, 16) + "..." : value
      );
    } else {
      setSelectedValue(placeholder);
    }
  }, [value, placeholder]);

  // Wrap the Select component with virtualized Select
  return (
    <div
      className={`${
        bgColor ? "filter-dropdown-cross new-filters" : "filter-dropdown-cross"
      }`}
    >
      <Select
        placeholder={selectedValue ? selectedValue : placeholder}
        options={options}
        value={selectedValue}
        backspaceRemoves={false}
        isLoading={loading}
        styles={bgColor ? customStylesFilter : customStyles}
        onChange={(selectedOption) => handleChange(selectedOption)}
      />
      {selectedValue && selectedValue !== placeholder && (
        <button
          onClick={clearFilter}
          className="filter-icon"
          style={{ top: takeonTop ? "55%" : "" }}
        >
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default Dropdown;
