export const Photoshoot_request_header_arr = [
  "Sr.No.",
  <>
    Photoshoot <br /> Name
  </>,
  <>
    Total <br />
    Articles
  </>,
  <>
    Requests <br />
    Created By
  </>,
  <>
    Total Articles <br />
    Complete
  </>,
  <>
    Total Articles <br />
    Pending
  </>,
  "Created At",
  "Status",
  "Action",
];

export const photoshoot_request_details_data_arr = [
  "Sr.No.",
  "Style Color",
  "Category",
  <>
    Content <br />
    Format type
  </>,
  <>
    Reference <br />
    Image/Video
  </>,
  "Action",
];

export const add_photoshoot_request_details_arr = [
  "Sr.No.",
  "Pictures",
  <>
    Style Color <br />
    Name
  </>,
  "Category",
  <>
    Content <br />
    Format type
  </>,
  <>
    Reference <br />
    Image/Video
  </>,
  <>
    Styling <br />
    Options
  </>,
  "Action",
];

export const Style_set_header_arr = [
  "Sr.No.",
  <>
    Photoshoot <br />
    Name
  </>,
  <>Pictures</>,
  <>
    Style Color <br />
    Name
  </>,
  <>Category</>,
  <>
    Reference <br />
    Image/Video
  </>,
  <>Styling Option</>,
];

export const re_shoot_requirement_header_arr = [
  "Sr.No.",
  "Pictures",
  <>
    Style Color <br />
    Name
  </>,
  <>Category</>,
  <>
    Content <br />
    Format Type
  </>,
  <>
    Reference <br />
    Image/Video
  </>,
  <>
    Styling <br />
    Options
  </>,
  "Created At",
  "Status",
  "Action",
];
