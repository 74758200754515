import React from "react";
import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";

const VideoModalContainer = (props) => {
  const { close, video_url } = props;

  console.log("Video_Url: ", video_url);
  return (
    <>
      <div onClick={close} className="cross-icon-modal">
        <CloseIcon className="cross-icon-col" />
      </div>
      <ReactPlayer
        url={video_url} // Replace with your video URL
        width="100%"
        height="240px"
        playing={true}
        controls // Show video controls (play, pause, etc.)
      />
    </>
  );
};

export default VideoModalContainer;
