import api from "../Middleware/AxiosConfig";
import {
  ADD_PHOTOSHOOT_FAILURE,
  ADD_PHOTOSHOOT_REQUEST,
  ADD_PHOTOSHOOT_SUCCESS,
  APPROVE_STYLE_SET_FAILURE,
  APPROVE_STYLE_SET_REQUEST,
  APPROVE_STYLE_SET_SUCCESS,
  CANCEL_SHOOT_REQUIREMENT_FAILURE,
  CANCEL_SHOOT_REQUIREMENT_REQUEST,
  CANCEL_SHOOT_REQUIREMENT_SUCCESS,
  CONTENT_FORMAT_DETAILS_UPDATE_FAILURE,
  CONTENT_FORMAT_DETAILS_UPDATE_REQUEST,
  CONTENT_FORMAT_DETAILS_UPDATE_SUCCESS,
  DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_FAILURE,
  DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_REQUEST,
  DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_SUCCESS,
  DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_FAILURE,
  DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_REQUEST,
  DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_SUCCESS,
  DASHBOARD_METRICS_FAILURE,
  DASHBOARD_METRICS_REQUEST,
  DASHBOARD_METRICS_SUCCESS,
  GET_ALL_CATEGORY_LIST_FAILURE,
  GET_ALL_CATEGORY_LIST_REQUEST,
  GET_ALL_CATEGORY_LIST_SUCCESS,
  GET_ALL_PHOTOSHOOT_DATA_FAILURE,
  GET_ALL_PHOTOSHOOT_DATA_REQUEST,
  GET_ALL_PHOTOSHOOT_DATA_SUCCESS,
  GET_ALL_PHOTOSHOOT_NAME_LIST_FAILURE,
  GET_ALL_PHOTOSHOOT_NAME_LIST_REQUEST,
  GET_ALL_PHOTOSHOOT_NAME_LIST_SUCCESS,
  GET_ARTICLE_WISE_BAR_CHART_FAILURE,
  GET_ARTICLE_WISE_BAR_CHART_REQUEST,
  GET_ARTICLE_WISE_BAR_CHART_SUCCESS,
  GET_CONTENT_FORMAT_DATA_BY_ID_FAILURE,
  GET_CONTENT_FORMAT_DATA_BY_ID_REQUEST,
  GET_CONTENT_FORMAT_DATA_BY_ID_SUCCESS,
  GET_CONTENT_FORMAT_DATA_FAILURE,
  GET_CONTENT_FORMAT_DATA_REQUEST,
  GET_CONTENT_FORMAT_DATA_SUCCESS,
  GET_LIST_OF_STYLE_FAILURE,
  GET_LIST_OF_STYLE_REQUEST,
  GET_LIST_OF_STYLE_SUCCESS,
  GET_PHOTOSHOOT_DATA_BY_ID_FAILURE,
  GET_PHOTOSHOOT_DATA_BY_ID_REQUEST,
  GET_PHOTOSHOOT_DATA_BY_ID_SUCCESS,
  GET_PHOTOSHOOT_DATA_FAILURE,
  GET_PHOTOSHOOT_DATA_REQUEST,
  GET_PHOTOSHOOT_DATA_SUCCESS,
  GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_FAILURE,
  GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_REQUEST,
  GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_SUCCESS,
  GET_RESHOOT_DATA_FAILURE,
  GET_RESHOOT_DATA_REQUEST,
  GET_RESHOOT_DATA_SUCCESS,
  GET_RESHOOT_DETAILS_FAILURE,
  GET_RESHOOT_DETAILS_REQUEST,
  GET_RESHOOT_DETAILS_SUCCESS,
  GET_STYLE_SETS_TABLE_DATA_FAILURE,
  GET_STYLE_SETS_TABLE_DATA_REQUEST,
  GET_STYLE_SETS_TABLE_DATA_SUCCESS,
  GET_STYLING_ELEMENTS_DATA_FAILURE,
  GET_STYLING_ELEMENTS_DATA_REQUEST,
  GET_STYLING_ELEMENTS_DATA_SUCCESS,
  GET_STYLING_ELEMENT_OPTION_FAILURE,
  GET_STYLING_ELEMENT_OPTION_REQUEST,
  GET_STYLING_ELEMENT_OPTION_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  PHOTOSHOOT_REQUEST_DETAILS_DELETE_FAILURE,
  PHOTOSHOOT_REQUEST_DETAILS_DELETE_REQUEST,
  PHOTOSHOOT_REQUEST_DETAILS_DELETE_SUCCESS,
  PHOTOSHOOT_REQUEST_DETAILS_EDIT_FAILURE,
  PHOTOSHOOT_REQUEST_DETAILS_EDIT_REQUEST,
  PHOTOSHOOT_REQUEST_DETAILS_EDIT_SUCCESS,
  REJECT_STYLE_SET_FAILURE,
  REJECT_STYLE_SET_REQUEST,
  REJECT_STYLE_SET_SUCCESS,
  RESHOOT_REQUIREMENT_CANCEL_FAILURE,
  RESHOOT_REQUIREMENT_CANCEL_REQUEST,
  RESHOOT_REQUIREMENT_CANCEL_SUCCESS,
} from "../Constants/StyleConstants";
import axios from "axios";
import { BASE_URL } from "../Middleware/BaseURL";

export const LoginUserActions = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const { data } = await axios.post(`${BASE_URL}/login`, {
      username,
      password,
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.message,
    });
  }
};

export const DashboardAllDataActions = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_METRICS_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/dashboard_main_metrics`, {
      filter,
    });
    dispatch({
      type: DASHBOARD_METRICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DASHBOARD_METRICS_FAILURE,
      payload: error.message,
    });
  }
};

export const getArticle_wise_barChart_Actions =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ARTICLE_WISE_BAR_CHART_REQUEST,
      });

      const { data } = await api.post(
        `${BASE_URL}/dashboard_article_wise_bar_chart`,
        {
          filter,
        }
      );
      dispatch({
        type: GET_ARTICLE_WISE_BAR_CHART_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ARTICLE_WISE_BAR_CHART_FAILURE,
        payload: error.message,
      });
    }
  };

export const getDashboardDoughnutContentWiseActions =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_REQUEST,
      });

      const { data } = await api.post(
        `${BASE_URL}/dashboard_studio_contents_pie_chart`,
        {
          filter,
        }
      );
      dispatch({
        type: DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_DOUGHNUT_CHART_CONTENT_WISE_FAILURE,
        payload: error.message,
      });
    }
  };

export const getDashboardDoughnutCategoryWiseActions =
  (filter) => async (dispatch) => {
    try {
      dispatch({
        type: DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_REQUEST,
      });

      const { data } = await api.post(
        `${BASE_URL}/dashboard_styling_item_types_pie_chart`,
        {
          filter,
        }
      );
      dispatch({
        type: DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DASHBOARD_DOUGHNUT_CHART_CATEGORY_WISE_FAILURE,
        payload: error.message,
      });
    }
  };

export const getContentFormatDataActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONTENT_FORMAT_DATA_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/content_format_read`);
    dispatch({
      type: GET_CONTENT_FORMAT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONTENT_FORMAT_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getContentFormatDataByIdActions = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONTENT_FORMAT_DATA_BY_ID_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/content_format_edit`, { id });
    dispatch({
      type: GET_CONTENT_FORMAT_DATA_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONTENT_FORMAT_DATA_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const AddPhotoShootRequestActions =
  (name, remarks) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_PHOTOSHOOT_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/photoshoot_request_create`, {
        name,
        remarks,
      });
      dispatch({
        type: ADD_PHOTOSHOOT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_PHOTOSHOOT_FAILURE,
        payload: error.message,
      });
    }
  };

export const getPhotoShootRequestDataActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PHOTOSHOOT_DATA_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/photoshoot_request_read`);
    dispatch({
      type: GET_PHOTOSHOOT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PHOTOSHOOT_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getPhotoShootRequestDataByIdActions = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PHOTOSHOOT_DATA_BY_ID_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/photoshoot_request_edit`, {
      id,
    });
    dispatch({
      type: GET_PHOTOSHOOT_DATA_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PHOTOSHOOT_DATA_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const getListofStylesActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIST_OF_STYLE_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/style_color`);
    dispatch({
      type: GET_LIST_OF_STYLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_LIST_OF_STYLE_FAILURE,
      payload: error.message,
    });
  }
};

export const getListofCategoryActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CATEGORY_LIST_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/category_select`);
    dispatch({
      type: GET_ALL_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CATEGORY_LIST_FAILURE,
      payload: error.message,
    });
  }
};

export const getListofPhotoshootNameActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PHOTOSHOOT_NAME_LIST_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/photoshoot_request_select`);
    dispatch({
      type: GET_ALL_PHOTOSHOOT_NAME_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PHOTOSHOOT_NAME_LIST_FAILURE,
      payload: error.message,
    });
  }
};

export const getPhotoShootRequestDetailsDataActions =
  (photoshoot_request_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_REQUEST,
      });

      const { data } = await api.post(
        `${BASE_URL}/photoshoot_request_detail_read`,
        { photoshoot_request_id }
      );
      dispatch({
        type: GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PHOTOSHOOT_REQUEST_DETAILS_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getStylingElementsDataActions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_STYLING_ELEMENTS_DATA_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/styling_item_type_read`);
    dispatch({
      type: GET_STYLING_ELEMENTS_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_STYLING_ELEMENTS_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getStylingElementsOptionDataActions = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STYLING_ELEMENT_OPTION_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/styling_item_type_edit`, {
      id,
    });
    dispatch({
      type: GET_STYLING_ELEMENT_OPTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_STYLING_ELEMENT_OPTION_FAILURE,
      payload: error.message,
    });
  }
};

export const getStyleSetsTable_data_Actions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_STYLE_SETS_TABLE_DATA_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/styling_set_read`);
    dispatch({
      type: GET_STYLE_SETS_TABLE_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_STYLE_SETS_TABLE_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getStyleSetsTable_data_with_id_Actions =
  (photoshoot_request_detail_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_STYLE_SETS_TABLE_DATA_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/styling_set_read`, {
        photoshoot_request_detail_id,
      });
      dispatch({
        type: GET_STYLE_SETS_TABLE_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_STYLE_SETS_TABLE_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllPhotoshoot_data_Actions =
  (photoshoot_request_detail_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_PHOTOSHOOT_DATA_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/studio_photoshoot_read`, {
        photoshoot_request_detail_id,
      });
      dispatch({
        type: GET_ALL_PHOTOSHOOT_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PHOTOSHOOT_DATA_FAILURE,
        payload: error.message,
      });
    }
  };

export const getAllReshoot_data_Actions = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_RESHOOT_DATA_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/reshoot_request_read`);
    dispatch({
      type: GET_RESHOOT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_RESHOOT_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const getReshoot_details_Actions =
  (reshoot_request_id) => async (dispatch) => {
    try {
      dispatch({
        type: GET_RESHOOT_DETAILS_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/reshoot_request_detail`, {
        reshoot_request_id,
      });
      dispatch({
        type: GET_RESHOOT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_RESHOOT_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

export const cancel_ShootRequirement_Actions =
  (id, status) => async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_SHOOT_REQUIREMENT_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/photoshoot_request_update`, {
        id,
        status,
      });
      dispatch({
        type: CANCEL_SHOOT_REQUIREMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CANCEL_SHOOT_REQUIREMENT_FAILURE,
        payload: error.message,
      });
    }
  };

export const approve_styleSet_Actions = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_STYLE_SET_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/styling_set_approval`, {
      id,
      status,
    });
    dispatch({
      type: APPROVE_STYLE_SET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVE_STYLE_SET_FAILURE,
      payload: error.message,
    });
  }
};

export const reject_styleSet_Actions = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_STYLE_SET_REQUEST,
    });

    const { data } = await api.post(`${BASE_URL}/styling_set_approval`, {
      id,
      status,
    });
    dispatch({
      type: REJECT_STYLE_SET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REJECT_STYLE_SET_FAILURE,
      payload: error.message,
    });
  }
};

export const PhotoshootRequest_detailsEdit_Actions =
  (id) => async (dispatch) => {
    try {
      dispatch({
        type: PHOTOSHOOT_REQUEST_DETAILS_EDIT_REQUEST,
      });

      const { data } = await api.post(
        `${BASE_URL}/photoshoot_request_detail_edit`,
        {
          id,
        }
      );
      dispatch({
        type: PHOTOSHOOT_REQUEST_DETAILS_EDIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PHOTOSHOOT_REQUEST_DETAILS_EDIT_FAILURE,
        payload: error.message,
      });
    }
  };

export const PhotoshootRequest_detailsDelete_Actions =
  (id) => async (dispatch) => {
    try {
      dispatch({
        type: PHOTOSHOOT_REQUEST_DETAILS_DELETE_REQUEST,
      });

      const { data } = await api.post(
        `${BASE_URL}/photoshoot_request_detail_delete`,
        {
          id,
        }
      );
      dispatch({
        type: PHOTOSHOOT_REQUEST_DETAILS_DELETE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PHOTOSHOOT_REQUEST_DETAILS_DELETE_FAILURE,
        payload: error.message,
      });
    }
  };

export const reshoot_requirement_cancel_Actions =
  (id, status) => async (dispatch) => {
    try {
      dispatch({
        type: RESHOOT_REQUIREMENT_CANCEL_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/reshoot_request_approval`, {
        id,
        status,
      });
      dispatch({
        type: RESHOOT_REQUIREMENT_CANCEL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RESHOOT_REQUIREMENT_CANCEL_FAILURE,
        payload: error.message,
      });
    }
  };

export const content_format_details_update_Actions =
  (id, name, description) => async (dispatch) => {
    try {
      dispatch({
        type: CONTENT_FORMAT_DETAILS_UPDATE_REQUEST,
      });

      const { data } = await api.post(`${BASE_URL}/content_format_update`, {
        id,
        name,
        description,
      });
      dispatch({
        type: CONTENT_FORMAT_DETAILS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTENT_FORMAT_DETAILS_UPDATE_FAILURE,
        payload: error.message,
      });
    }
  };
