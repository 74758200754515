import React, { useState } from "react";
import SideBar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Navbar/Navbar";
import HeaderProps from "../../Components/HeaderProps/HeaderProps";
import { Style_set_header_arr } from "../../Components/DataTable/DummyHeaderArray";
import DataTable from "../../Components/DataTable/DataTable";
import MetaTitle from "../../Components/Helmet/MetaTitle";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getListofCategoryActions,
  getListofPhotoshootNameActions,
  getListofStylesActions,
  getStyleSetsTable_data_Actions,
  getStyleSetsTable_data_with_id_Actions,
} from "../../Actions/StyleActions";
import Loader from "../../Components/Loading/Loader";
import DisplayError from "../../Components/DisplayError/DisplayError";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { Pagination } from "../../Components/Pagination/Pagination";
import ModalContainer from "../../Components/ModalContainer/ModalContainer";
import { useAlert } from "react-alert";
import api from "../../Middleware/AxiosConfig";
import { BASE_URL } from "../../Middleware/BaseURL";

const StyleSets = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { style_sets_table_data, loading, error } = useSelector(
    (state) => state.styleSetsData
  );

  //   list of styles state dropdown
  const { list_of_styles, loading: style_list_loading } = useSelector(
    (state) => state.listofstyles
  );

  //   list of category state dropdown
  const { list_of_Category, loading: category_list_loading } = useSelector(
    (state) => state.listCategories
  );

  //   list of Photoshoot name state dropdown
  const { photoshoot_name_list, loading: photoshoot_name_list_loading } =
    useSelector((state) => state.photoshoot_nameList);

  const [style_sets_arr, setStyle_setArr] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [isIndex, setIsIndex] = useState(null);

  const [img_uri, setImg_uri] = useState("");

  const loadImg = (i) => {
    setImg_uri(i);
  };

  const handleImgSlider = (imageList) => {
    setImageList(imageList);
    handleModal(2);
  };

  const handleModal = (index) => {
    setIsIndex(index);
  };

  const closeModal = () => {
    setIsIndex(null);
  };

  const [uri, setUri] = useState("");

  const handleVideoUri = (video) => {
    setUri(video);
  };

  const [id_product, setId_product] = useState("");

  const handleProductId = (id) => {
    setId_product(id);
  };

  console.log("STYLE_SET_ID: ", id_product);

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={true}
          index={isIndex}
          closeModal={closeModal}
          onData={closeModal}
          imageList={imageList}
          video_url={uri}
          img_uri={img_uri}
          id={id_product}
        />
      );
    }
    return null;
  };

  const [page, setPage] = useState(1);
  const [pagination_loading, setPagination_loading] = useState(false);
  const [totalPage_new, setTotal_page_new] = useState("");

  const fetchNextStyleSets = async (
    page,
    photoshoot_request_detail_id,
    photoshoot_request_id,
    style_color,
    category_id
  ) => {
    setPagination_loading(true);
    try {
      const filters = {
        photoshoot_request_detail_id: photoshoot_request_detail_id,
        photoshoot_request_id: photoshoot_request_id,
        style_color: style_color,
        category_id: category_id,
        page: page,
      };
      // Remove keys with empty or null values
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(
          ([_, value]) => value !== null && value !== ""
        )
      );
      let response;
      if (id !== "" && id !== null && id !== undefined) {
        response = await api.post(
          `${BASE_URL}/styling_set_read`,
          filteredFilters
        );
      } else {
        response = await api.post(
          `${BASE_URL}/styling_set_read`,
          filteredFilters
        );
      }

      if (response && response?.data?.status === 1) {
        setStyle_setArr(response && response?.data?.data);
        setPagination_loading(false);
        setTotal_page_new(response && response?.data?.pagination?.last_page);
      }
    } catch (error) {
      setPagination_loading(false);
      alert.error(error.message);
    }
  };

  const handlePage = (i) => {
    setPage(i);
    fetchNextStyleSets(i, id, photoshoot_request_id, style_color, category_id);
  };

  // filter state code starts here
  const [isFilter_Active, setIsFilter_Active] = useState(false);

  const [photoshoot_name, setPhotoshoot_name] = useState("");
  const [photoshoot_request_id, setPhotoshoot_request_id] = useState("");

  const [style_color, setStyle_color] = useState("");

  const [category, setCategory] = useState("");
  const [category_id, setCategory_id] = useState("");

  const handlePhotoshootName = (val) => {
    setIsFilter_Active(false);
    setPhotoshoot_name(val?.value);
    setPhotoshoot_request_id(val?.id);
    fetchNextStyleSets(page, id, val?.id, style_color, category_id);
  };

  const handleStyle = (val) => {
    setIsFilter_Active(false);
    setStyle_color(val?.value);
    fetchNextStyleSets(
      page,
      id,
      photoshoot_request_id,
      val?.value,
      category_id
    );
  };

  const handleCategory = (val) => {
    setIsFilter_Active(false);
    setCategory(val?.value);
    setCategory_id(val?.id);
    fetchNextStyleSets(page, id, photoshoot_request_id, style_color, val?.id);
  };
  // filter state code ends here

  useEffect(() => {
    dispatch(getListofStylesActions());
    dispatch(getListofCategoryActions());
    dispatch(getListofPhotoshootNameActions());
  }, [dispatch]);

  useEffect(() => {
    if (id !== null && id !== undefined && id !== "" && !isFilter_Active) {
      dispatch(getStyleSetsTable_data_with_id_Actions(id));
    } else if (!isFilter_Active) {
      dispatch(getStyleSetsTable_data_Actions());
    }
  }, [dispatch, id, isFilter_Active]);

  console.log(
    "Style Sets Data: ",
    list_of_Category?.data,
    list_of_styles?.data
  );

  const handleRedirect = () => {
    navigate(-1);
  };

  useEffect(() => {
    style_sets_table_data &&
      setStyle_setArr(style_sets_table_data && style_sets_table_data?.data);
  }, [style_sets_table_data]);

  return (
    <>
      <MetaTitle title={"Style Sets"} />
      <div className="grid-sidebar-row">
        <div>
          <SideBar />
        </div>
        <div>
          <Header />
          {loading ? (
            <Loader />
          ) : error ? (
            <DisplayError error={error} />
          ) : (
            <div className="container-wrapper">
              {/* header component starts here */}
              <HeaderProps
                title="Style Sets"
                hide={true}
                redirect={
                  id !== null && id !== undefined && id !== "" ? true : false
                }
                handleRedirect={handleRedirect}
              />
              {/* header component ends here */}

              {/* dropdown container */}
              <div className="dropdown-filter-container">
                <Dropdown
                  placeholder="PhotoShoot Name"
                  bgColor={true}
                  dropdown={
                    photoshoot_name_list &&
                    photoshoot_name_list?.data?.length > 0
                      ? photoshoot_name_list?.data
                      : []
                  }
                  loading={photoshoot_name_list_loading}
                  value={photoshoot_name}
                  onData={handlePhotoshootName}
                />
                <Dropdown
                  placeholder="Style Color"
                  bgColor={true}
                  dropdown={
                    list_of_styles && list_of_styles?.data?.length > 0
                      ? list_of_styles?.data
                      : []
                  }
                  loading={style_list_loading}
                  value={style_color}
                  onData={handleStyle}
                />
                <Dropdown
                  placeholder="Category"
                  bgColor={true}
                  dropdown={
                    list_of_Category && list_of_Category?.data?.length > 0
                      ? list_of_Category?.data
                      : []
                  }
                  loading={category_list_loading}
                  value={category}
                  onData={handleCategory}
                />
              </div>
              {/* dropdown container */}

              {/* table component starts here */}
              <div className="table-wrapper">
                <div className="table-container">
                  <DataTable
                    loadImage={handleImgSlider}
                    header={Style_set_header_arr}
                    style_set_data={
                      style_sets_arr && style_sets_arr?.length > 0
                        ? style_sets_arr
                        : []
                    }
                    handleImg={handleModal}
                    loadVideo={handleVideoUri}
                    handleindex={handleModal}
                    loadImg={loadImg}
                    sendId={handleProductId}
                  />
                </div>
              </div>
              {/* table component ends here */}

              {/* Pagination component starts here */}
              <div className="rendering-pagination">
                <Pagination
                  uniqueKey={`ScreenA-${page}`}
                  page={page}
                  isLoading={pagination_loading && pagination_loading}
                  totalPage={
                    totalPage_new !== ""
                      ? totalPage_new
                      : style_sets_table_data &&
                        style_sets_table_data?.pagination?.last_page
                  }
                  onPageChange={handlePage}
                />
              </div>
              {/* Pagination component ends here */}
            </div>
          )}
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default StyleSets;
